import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';

// Custom Backend Config for i18n
const customBackend = {
  type: 'backend',
  read: async function (language, namespace, callback) {
    try {
      if (language === 'en' || language === 'dev') callback(null, {});
      else {
        const response = await axios.get(
          APICONSTANTS.getTranslations.replace(':lang', language)
        );
        callback(null, response.data);
      }
    } catch (error) {
      callback(null, {});
    }
  }
};

const lang = localStorage.getItem('lang');

i18n
  .use(initReactI18next)
  .use(customBackend)
  .init({
    lng: lang || 'en',
    fallbackLng: 'en'
  });

export default i18n;
