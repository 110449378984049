import React from 'react';

import axios from 'src/axios';
import { GLOBAL_CONSTANTS } from 'src/components/Constants';
import { LoadingContext } from 'src/store/ContextStore';
import { APICONSTANTS, CONSTANTS } from './Constants';

// for storing user details
export const FormContext = React.createContext(null);

const EVENTS = Object.freeze({
  SET_SCHEMA_WITH_RESPONSE: 'SET_SCHEMA_WITH_RESPONSE',
  SET_ERROR_PAGE: 'SET_ERROR_PAGE',
  SET_DEACTIVATED_LINK: 'SET_DEACTIVATED_LINK',
  SUBMIT_FORM: 'SUBMIT_FORM'
});

function init(token) {
  return {
    token,
    // form: null
    responses: {},
    isInvalidLink: false
    // status: null
  };
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case EVENTS.SET_SCHEMA_WITH_RESPONSE: {
      if (payload.status === CONSTANTS.user_status.DEACTIVATED)
        return {
          isDeactivatedLink: true
        };
      else
        return {
          ...state,
          ...payload
        };
    }
    case EVENTS.SET_ERROR_PAGE:
      return {
        isInvalidLink: true
      };
    case EVENTS.SET_DEACTIVATED_LINK:
      return {
        isDeactivatedLink: true
      };
    case EVENTS.SUBMIT_FORM:
      return {
        status: CONSTANTS.user_status.SUBMITTED
      };
  }
};

const fetchResponse = ({ fromURL, token }) => {
  // if fromURL is present use that
  if (fromURL) {
    return axios.get(fromURL);
  }

  // from the url fetch token
  return axios.get(APICONSTANTS.schemaAndResponses, {
    headers: { [CONSTANTS.authTokenKey]: token }
  });
};

const FormContextProvider = ({ fromURL, children }) => {
  const { setLoading } = React.useContext(LoadingContext);

  // from the url fetch token
  const params = new URLSearchParams(window.location.search);
  const token = params.get(GLOBAL_CONSTANTS.tokenKey);

  const [state, dispatch] = React.useReducer(reducer, token, init);

  // on landing fetch schema and response
  React.useLayoutEffect(() => {
    setLoading(true);
    // verify if link is valid
    fetchResponse({ fromURL, token })
      .then((res) => {
        dispatch({ type: EVENTS.SET_SCHEMA_WITH_RESPONSE, payload: res.data });
      })
      .catch(() => {
        dispatch({ type: EVENTS.SET_ERROR_PAGE });
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = React.useMemo(
    () => ({
      ...state,
      dispatchSubmitForm: () => dispatch({ type: EVENTS.SUBMIT_FORM })
    }),
    [state]
  );

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
};

export const useFormContext = () => React.useContext(FormContext);

export default FormContextProvider;
