import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import DateInput from '../../../UI/input/DateInput';

import axios from 'src/axios';

import { Box, TextField } from '@mui/material';

import { SnackContext, AppContext } from 'src/store/ContextStore';

import TextInput from 'src/components/UI/input/TextInput';

export default function ConsentForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        consentType: '',
        consentDate: new Date(),
        reponse: '',
        showInFamily: false
      };

  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([]);

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const { setSnack } = React.useContext(SnackContext);

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.consent, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Consent Type"
              type="autocomplete"
              name="consentType"
              value={formValues.consentType || ''}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  consentType: value
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter a consent type"
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Consent Date</FormLabel>
          </Grid>
          <DateInput
            value={formValues.consentDate}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                consentDate: e
              }))
            }
            variant="outline"
            minDate={isEditing ? new Date(1600, 0, 0) : new Date()}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Response</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="response"
              value={formValues.response}
              multiline={true}
            />
          </Box>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new consent details`}
        />
      </Grid>
    </form>
  );
}
