export const INITIAL_FORM_STRUCTURE = {
  formId: '',
  title: '',
  description: '',
  startingGroupId: '',
  schema: {}
};

export const INITIAL_GROUP_STRUCTURE = {
  title: '',
  description: '',
  renderType: '',
  condition: [],
  groupsConnectedTo: [],
  formElements: [],
  previousConnections: [],
  formType: ''
};
