import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function DeleteModal({ content }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          {content ??
            "This action will permanently delete this family member's details, incase of a proband, all family details will be deleted."}
        </Typography>
      </Grid>
    </Grid>
  );
}
