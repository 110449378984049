import React from 'react';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import AutocompleteInput from 'src/components/UI/input/AutocompleteInput';
import { Button, Grid } from '@mui/material';
import { SnackContext } from 'src/store/ContextStore';
import { getColor } from 'src/util/util';
import axios from 'src/axios';

function Tags({ user }) {
  const { setSnack } = React.useContext(SnackContext);

  const [tags, setTags] = React.useState(
    (user?.tags && user.tags.map((tag) => tag.tag)) || []
  );

  const [options, setOptions] = React.useState(CONSTANTS.tags);

  React.useEffect(() => {
    axios.get(APICONSTANTS.setTags).then(({ data: { clientTags } }) => {
      if (clientTags.length > 0) {
        setOptions(clientTags);
      }
    });
  }, []);

  const handleTags = (value) => {
    const tags = [];
    value.map((tag) => {
      if (typeof tag === 'string' && !tags.includes(tag)) tags.push(tag);
      else {
        const label = tag?.inputValue || tag?.label;

        label && !tags.includes(label) && tags.push(label);
      }
    });

    setTags(tags);
  };

  const onSave = () => {
    const clientTags = [];
    const pedigreeTags = [];
    tags.forEach((tag) => {
      let tagColor =
        options.find((t) => t.label === tag)?.tagColor ?? getColor();
      clientTags.push({ label: tag, tagColor });
      pedigreeTags.push({ tag, tagColor });
    });

    axios
      .post(APICONSTANTS.setTags, {
        userId: user._id,
        clientTags,
        pedigreeTags
      })
      .then((/*res*/) => {
        setSnack({
          status: true,
          msg: 'Tags Updated',
          severity: 'success'
        });
      })
      .catch((/*err*/) => {
        setSnack({
          status: true,
          msg: 'An error occured',
          severity: 'error'
        });
      });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteInput
          multiple
          options={options}
          getOptionLabel={(option) => option}
          value={tags}
          onChange={(v) => handleTags(v)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="secondary" onClick={onSave}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
}

export default Tags;
