import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';

import { CONSTANTS } from 'src/components/Constants';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const useStyles = makeStyles({
  emailSentChip: {
    color: '#fff',
    backgroundColor: '#379fb4'
  },
  inProgressChip: {
    color: '#000',
    backgroundColor: '#f8dc2e'
  },
  submittedChip: {
    color: '#fff',
    backgroundColor: '#569e52'
  },
  linkCreatedChip: {
    color: '#000',
    backgroundColor: '#dfeff5'
  },
  importedChip: {
    color: '#fff',
    backgroundColor: '#2BA9BF'
  }
});

export default function UsersTableStatusCol({ status }) {
  const classes = useStyles();

  const t = useAutoTranslation();

  switch (status) {
    case CONSTANTS.user_status.DEACTIVATED:
      return <Chip label={t('Link Deactivated')} />;
    case CONSTANTS.user_status.CREATED:
      return (
        <Chip label={t('Link Created')} className={classes.linkCreatedChip} />
      );
    case CONSTANTS.user_status.PENDING:
      return <Chip label={'E-Mail Sent'} className={classes.emailSentChip} />;
    case CONSTANTS.user_status.IN_PROGRESS:
      return (
        <Chip label={t('In Progress')} className={classes.inProgressChip} />
      );
    case CONSTANTS.user_status.SUBMITTED:
      return <Chip label={t('Submitted')} className={classes.submittedChip} />;
    case CONSTANTS.user_status.IMPORTED:
      return <Chip label={t('Imported')} className={classes.importedChip} />;
    default:
      return '';
  }
}
