import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from 'src/components/UI/appbar/AppBar';
import CustomDrawer from '../appbar/CustomDrawer';

const offset = 65;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      overflowY: 'scroll'
    }
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    overflowY: 'hidden',
    flex: 1,
    height: '100vh',
    [theme.breakpoints.down('lg')]: {
      overflow: 'hidden',
      flexDirection: 'column-reverse',
      height: '100vh'
    }
  },
  content: {
    position: 'relative',
    flex: 1,
    padding: theme.spacing(1),
    paddingTop: theme.spacing(4),
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginTop: offset
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
    // marginLeft: 0,
  }
}));

export default function MainContainer(props) {
  const classes = useStyles();
  // use to open and close drawer
  // const [open, setOpen] = React.useState(props.defaultOpen ? true : false);

  return (
    <div className={classes.root}>
      <AppBar menuItems={props.menuItems} />
      <div className={classes.main}>
        <CustomDrawer navItems={props.navItems} />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
            [props.className]: props.className
          })}
        >
          {props.children}
        </main>
      </div>
    </div>
  );
}
