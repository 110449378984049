import React from 'react';
import MUIFormLabel from '@mui/material/FormLabel';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function FormLabel(props) {
  const { theme } = React.useContext(ThemeContext);
  const t = useAutoTranslation();
  return (
    <MUIFormLabel
      component="label"
      className={
        props.className
          ? props.className
          : `form-label ${props.required ? ' required' : ''}`
      }
      style={{ color: theme.appearance.text }}
    >
      {typeof props.children === 'string' ? t(props.children) : props.children}
      {/* {props.children} */}
    </MUIFormLabel>
  );
}
