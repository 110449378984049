// ContextAPI
import { FormData, PopupContext } from '../../../../../CreateQuestionnaire';

// Libraries
import React, { Fragment, useContext } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

// Components
import TextField from '@mui/material/TextField';

// styles

import styles from './TextInput.module.css';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { FORM_TYPES } from '../../../../../constants/formTypes';
import GraphStructureService from '../../../../../services/graph.structurer.service';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const initialValidationSchema = yup.object({
  label: yup
    .string('Enter the form label')
    .required('This field must be specified')
});

function ParaInput({ edit }) {
  // ContextAPI
  const [formData, setFormData] = useContext(FormData);
  const [popupContext, setPopupContext] = useContext(PopupContext);
  const gs = new GraphStructureService();

  const initialFormValues = () => {
    let value = {};
    value = popupContext.data.formData || {
      label: '',
      isRequired: false,
      isIdentifier: false
    };
    return value;
  };

  // handleSubmit
  const handleFormSubmit = async (values) => {
    values.type = FORM_TYPES.LONG_TEXT;
    const updatedFormData = { ...formData };
    if (!edit) {
      values.id = uuidv4();
      updatedFormData.schema[popupContext.data.id].formElements.push(values);
      setPopupContext({ ...popupContext, show: false });
    } else {
      const formElements = formData.schema[popupContext.data.id].formElements;
      for (let i = 0; i < formElements.length; i++) {
        if (formElements[i].id === popupContext.data.formData.id) {
          values.id = formElements[i].id;
          formElements[i] = values;
          gs.editFormElement(
            values.id,
            popupContext.data.id,
            updatedFormData,
            values,
            true
          );
        }
      }
      updatedFormData.schema[popupContext.data.id].formElements = formElements;
      setFormData(updatedFormData);
      setPopupContext({ ...popupContext, show: false });
    }
  };

  const handleClose = async () => {
    setPopupContext({ ...popupContext, show: false });
  };

  // Formik
  const formik = useFormik({
    initialValues: initialFormValues(),
    validationSchema: initialValidationSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    }
  });

  const t = useAutoTranslation();

  return (
    <Fragment>
      <div className={styles.InitialFormScreen} onSubmit={handleFormSubmit}>
        <h2>{t('Long Text')}</h2>
        <p>{t('Add a long text')}</p>
        <form onSubmit={formik.handleSubmit} className={styles.InitialForm}>
          <TextField
            variant="outlined"
            className={styles.FormField}
            id="label"
            name="label"
            label={t('Label')}
            value={formik.values['label']}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched['label'] && Boolean(formik.errors['label'])}
            helperText={formik.touched['label'] && formik.errors['label']}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="isRequired"
                name="isRequired"
                checked={formik.values['isRequired']}
                onChange={formik.handleChange}
              />
            }
            label={t('Is Required')}
          />
          <FormControlLabel
            control={
              <Checkbox
                id="isIdentifier"
                name="isIdentifier"
                checked={formik.values['isIdentifier']}
                onChange={formik.handleChange}
              />
            }
            label={t('Is Identifier')}
          />
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              {t('CANCEL')}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="secondary"
            >
              {edit ? t('SAVE') : t('CREATE')}
            </Button>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default ParaInput;
