import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CONSTANTS } from 'src/components/Constants';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

const size = 17;

const useStyles = makeStyles({
  emailSentChip: {
    color: '#379fb4',
    width: size,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  inProgressChip: {
    color: '#f8dc2e',
    width: size,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  submittedChip: {
    color: '#43b480',
    width: size,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  importedChip: {
    color: '#fff',
    backgroundColor: '#2BA9BF'
  },
  linkCreatedChip: {
    color: '#96cfda',
    width: size,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  deactivated: {
    color: '#e41a52',
    width: size,
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  default: {
    color: '#efefe7',
    width: size
  }
});

export default function UsersTableProgressCol({ status }) {
  const classes = useStyles();
  switch (status) {
    case CONSTANTS.user_status.DEACTIVATED:
      return <FiberManualRecord className={classes.deactivated} />;
    case CONSTANTS.user_status.CREATED:
      return <FiberManualRecord className={classes.linkCreatedChip} />;
    case CONSTANTS.user_status.PENDING:
      return <FiberManualRecord className={classes.emailSentChip} />;
    case CONSTANTS.user_status.IN_PROGRESS:
      return <FiberManualRecord className={classes.inProgressChip} />;
    case CONSTANTS.user_status.SUBMITTED:
      return <FiberManualRecord className={classes.submittedChip} />;
    case CONSTANTS.user_status.IMPORTED:
      return <FiberManualRecord className={classes.importedChip} />;
    default:
      return <FiberManualRecord className={classes.default} />;
  }
}
