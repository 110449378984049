import { Button, Grid, Typography } from '@mui/material';
import { Alert } from '@mui/material';
import { isArray } from 'lodash';
import React from 'react';
import TextInput from 'src/components/UI/input/TextInput';
import { FORM_TYPES } from '../../../constants/formTypes';

function LoadConfig({ data, setPopupContext }) {
  const [config, setConfig] = React.useState('');
  const [error, setError] = React.useState('');

  const validateConfig = (data) => {
    try {
      const formdata = JSON.parse(data);
      if (isArray(formdata)) {
        let status = false;
        formdata.forEach((element) => {
          if (element.type && element.label && element.id) {
            if (!FORM_TYPES[element.type]) {
              status = false;
            } else {
              if (
                element.type === FORM_TYPES.CHECKBOX_INPUT ||
                element.type === FORM_TYPES.DROPDOWN ||
                element.type === FORM_TYPES.LINEAR_SCALE ||
                element.type === FORM_TYPES.MULTIPLE_CHOICE
              ) {
                if (!(element.options.length > 1)) {
                  status = false;
                }
              } else status = true;
            }
          }
        });
        return status;
      } else return false;
    } catch (error) {
      return false;
    }
  };

  const handleClose = async () => {
    setPopupContext({ ...data, show: false });
  };

  const onSubmit = () => {
    setError(false);
    if (config) {
      const status = validateConfig(config);
      if (status) {
        data.setConfig(config);
        handleClose();
      } else setError(true);
    } else setError(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      spacing={2}
    >
      <Grid item xs={12}>
        <h2>
          <strong>Import Configuration </strong>
        </h2>
        {error && <Alert severity="error">Invalid Form Config</Alert>}
        <Typography gutterBottom>
          Paste your form configuration in the below field
        </Typography>
        <TextInput
          type="textarea"
          onChange={(e) => setConfig(e.target.value)}
          placeholder="{ config }"
        />
      </Grid>
      <Grid item sx={12}>
        <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Import
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

export default LoadConfig;
