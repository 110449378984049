import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import ProgressTrackerTable from './ProgressTrackerTable';
import { AppContext } from 'src/store/ContextStore';
import { APICONSTANTS } from '../Constants';
import axios from 'src/axios';

export default function ProgressTracker({ selectedGroup }) {
  const [users, setUsers] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('all');

  const {
    content: {
      formBuilder: { schemas }
    }
  } = React.useContext(AppContext);

  useEffect(() => {
    axios
      .get(
        `${APICONSTANTS.formProgress}${
          selectedWorkflow.id ? `?workflow=${selectedWorkflow.id}` : ''
        }`
      )
      .then((res) => {
        let result = res.data.map((user, index) => {
          return {
            id: index + 1,
            email: user.email,
            form: user.formData,
            client_group_id: user.client_group_id
          };
        });
        if (selectedGroup && selectedGroup !== 'default')
          result = result?.filter(
            (user) => user.client_group_id === selectedGroup
          );
        setUsers(result);
      });
  }, [selectedWorkflow, selectedGroup]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* <Typography gutterBottom>Track progress of User</Typography> */}
        <div className="margin-bottom-2" />
      </Grid>

      <ProgressTrackerTable
        schemas={schemas}
        users={users}
        selectedWorkflow={selectedWorkflow}
        setSelectedWorkflow={setSelectedWorkflow}
      />
    </Grid>
  );
}
