import React from 'react';
// Components
import Tool from '../Tool/Tool.jsx';
import Canvas from '../Canvas/Canvas.jsx';

// Styles
import styles from './FormBuilderTool.module.css';

// Constants
import { getUserSideFormTypes } from '../../constants/formTypes';
import { getUserSideTemplateTypes } from '../../constants/templateTypes';

function FormBuilderTool() {
  const formTools = getUserSideFormTypes();
  const templates = getUserSideTemplateTypes();

  const onDragStart = (e, tool) => {
    e.dataTransfer.setData('toolName', tool.value);
  };

  return (
    <div className={styles.FomBuilderContainer}>
      <div className={styles.FormCanvas}>
        <Canvas />
      </div>
      <div className={styles.FormToolsContainer} style={{ overflow: 'scroll' }}>
        <p>Tools</p>
        <div className={styles.FormToolsInnerContainer}>
          {formTools.map((tool, index) => (
            <Tool
              key={`Tool_${index}`}
              toolName={tool.value}
              tool={tool}
              type="tool"
            />
          ))}
        </div>

        <p>Templates</p>
        <div className={styles.FormToolsInnerContainer}>
          {templates.map((tool, index) => (
            <Tool
              key={`Template_${index}`}
              toolName={tool.value}
              tool={tool}
              type="template"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default FormBuilderTool;
