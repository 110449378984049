import React from 'react';

import EmailAuthentication from './EmailAuthentication';
import FormContextProvider from './FormContext';
import CustomFormContainer from './CustomFormContainer';

export default function CustomFormWithAuth() {
  const [authenticated, setAuthenticated] = React.useState(false);

  if (!authenticated) return <EmailAuthentication setAuth={setAuthenticated} />;

  return (
    <FormContextProvider>
      <CustomFormContainer />
    </FormContextProvider>
  );
}
