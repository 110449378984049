import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { HelpOutlineRounded } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 13
  }
}));

export default function InfoTooltip({ label, help, fontSize }) {
  const classes = useStyles();

  return (
    <Tooltip title={label} classes={classes} placement="right" arrow>
      {help ? (
        <HelpOutlineRounded fontSize={fontSize || 'small'} />
      ) : (
        <InfoOutlinedIcon fontSize={fontSize || 'small'} />
      )}
    </Tooltip>
  );
}
