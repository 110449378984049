import React, { useCallback, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'src/axios';
import PasswordInput from 'src/components/UI/input/PasswordInput';
import TextInput from '../UI/input/TextInput';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import {
  APICONSTANTS,
  CONSTANTS,
  RECAPTCHA_SITE_KEY
} from 'src/components/Constants';
import logo from 'src/assets/images/trakgeneLogo.png';
import { injectGoogleCaptchaScript, validateEmail } from 'src/util/util';
import ReactOtpInput from 'react-otp-input';
import MFASteps from './MFASteps';
import { Fade } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100vh',
    // backgroundColor: '#2ba9bf11',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  container: {
    padding: theme.spacing(8),
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    backgroundColor: 'white',
    width: '40%',
    [theme.breakpoints.down('lg')]: {
      width: '65%'
    },
    [theme.breakpoints.down('md')]: {
      width: '90%'
    }
  },

  form: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(3),
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  logo: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '75%'
    }
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#2ba9bf'
  },
  btn: {
    marginTop: theme.spacing(2),
    paddingBlock: 10
  }
}));

const Adminlogin = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const nextPath = params.get('next');
  const isResetingPassword =
    window.location.pathname === CONSTANTS.resetPasswordPath;
  const { setUser, user } = React.useContext(UserContext);
  const [code, setCode] = React.useState('');
  const [failedAttemp, setFailedAttemp] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({ status: false });
  const [is2FA, setIs2FA] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const { setTheme } = React.useContext(ThemeContext);

  const recaptcha = useRef('');

  React.useEffect(() => {
    axios
      .get('/recaptcha')
      .then((res) => {
        injectGoogleCaptchaScript(null, res.data.siteKey);
        recaptcha.current = res.data.siteKey;
      })
      .catch((err) => console.error(err));
  }, []);

  React.useEffect(() => {
    if (is2FA) {
      // dummy_state, temporary implementation
      window.history.pushState(null, '');

      const handlePopState = () => {
        window.history.pushState(null, '');
      };

      window.addEventListener('popstate', handlePopState);

      setDisableBackButton(true);

      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    } else {
      if (disableBackButton) {
        window.history.back();
        setDisableBackButton(false);
      }
    }
  }, [is2FA, disableBackButton]);

  const isValid = () => {
    return isResetingPassword ? !!email : !!email && !!password;
  };

  const onTempAccess = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!recaptcha.current) {
      axios
        .post(APICONSTANTS.login, { email, password, grToken: 'token' })
        .then((res) => {
          setLoading(false);
          if (res.data?.status === CONSTANTS.client_status.ACTIVE) {
            setUser(res.data);
            setIs2FA(true);
          } else {
            setAlert({
              status: true,
              severity: 'error',
              msg: 'This account has been deactivated!'
            });
            setPassword('');
          }
        })
        .catch(() => {
          setAlert({
            status: true,
            severity: 'error',
            msg: 'Invalid email or password!'
          });
          setLoading(false);
        });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (alert.status) setAlert((prev) => ({ ...prev, status: false }));
    if (!validateEmail(email)) {
      setAlert({ status: true, severity: 'error', msg: 'Invalid email!' });
      return;
    }
    setLoading(true);

    window.grecaptcha?.ready(function () {
      try {
        window.grecaptcha
          .execute(recaptcha.current, {
            action: isResetingPassword ? 'passwordReset' : 'login'
          })
          .then(function (grToken) {
            if (isResetingPassword) {
              axios
                .post(APICONSTANTS.resetPasswordEmail, { email, grToken })
                .then(() => {
                  setAlert({
                    status: true,
                    severity: 'success',
                    msg: 'Password Reset Email sent!'
                  });
                  setTimeout(() => {
                    setAlert((prev) => ({ ...prev, status: false }));
                    history.push(CONSTANTS.signinPath);
                  }, 3000);
                  setLoading(false);
                })
                .catch(() => {
                  setAlert({
                    status: true,
                    severity: 'error',
                    msg: 'Invalid email or password!'
                  });
                  setLoading(false);
                });
            } else if (password) {
              axios
                .post(APICONSTANTS.login, { email, password, grToken })
                .then((res) => {
                  setLoading(false);
                  if (res.data?.status === CONSTANTS.client_status.ACTIVE) {
                    setUser(res.data);
                    setIs2FA(true);
                  } else {
                    setAlert({
                      status: true,
                      severity: 'error',
                      msg: 'This account has been deactivated!'
                    });
                    setPassword('');
                  }
                })
                .catch((error) => {
                  setPassword('');
                  setEmail('');
                  setLoading(false);
                  if (error.response.status === 401) {
                    setAlert({
                      status: true,
                      severity: 'error',
                      msg: 'Invalid email/password'
                    });
                  }
                  if (error?.message.includes('429')) {
                    setAlert({
                      status: true,
                      severity: 'error',
                      msg: 'Too many failed attempts. Please try again in 5 minutes.'
                    });
                  } else {
                    setFailedAttemp(true);
                  }
                });
            }
          });
      } catch (error) {
        setAlert({
          status: true,
          severity: 'error',
          msg: 'Bot Detected'
        });
        setLoading(false);
      }
    });
  };

  const verify2FACallback = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${APICONSTANTS.login}/add-2fa`, {
          code,
          secret: user?.twoFactorAuthSecret,
          twoFactorAuthEnabled: user?.twoFactorAuthEnabled,
          email: user?.email
        });
        if (response?.data?.isValid) {
          await axios
            .get(APICONSTANTS.getCustomTheme)
            .then((res) => {
              if (res.data) setTheme(res.data);
            })
            .catch((/*err*/) => {
              setTheme(CONSTANTS.customTheme);
            });

          localStorage.setItem('2FAloggedin', 'true');
          history.push(nextPath ?? CONSTANTS.adminPath);
        } else {
          // window.alert('Wrong authenticator code! Try again.');
          setCodeError({ msg: 'Wrong authenticator code! Try again.' });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      code,
      history,
      nextPath,
      user?.email,
      user?.twoFactorAuthEnabled,
      user?.twoFactorAuthSecret
    ]
  );
  const [backupLoading, setBackupLoading] = React.useState(false);

  // Function to handle the backup code request
  const handleBackupCode = async (e) => {
    e.preventDefault();
    setBackupLoading(true);
    try {
      await axios.post(`${APICONSTANTS.login}/generate-onetime-code`, {
        email
      });
      setCodeError({
        msg: 'A one time passcode has been sent to the registered email address.',
        type: 'info'
      });
      setBackupLoading(false);
    } catch (err) {
      setCodeError({ msg: 'Failed to generate one time passcode!' });
      setBackupLoading(false);
    }
  };

  return (
    <div className={classes.paper}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img style={{ width: '100%' }} src={logo} alt="Trakgene Logo" />
        </div>
        {!is2FA ? (
          <form
            className={`${classes.form} user-form`}
            onSubmit={recaptcha.current ? onSubmit : onTempAccess}
          >
            <Grid item xs={12}>
              <Fade in={alert.status}>
                <Alert severity={alert.severity}>{alert.msg}</Alert>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                maxWidth={true}
                label="Email"
                type="email"
                name="email"
                variant="outlined"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={
                  !!(
                    alert.status &&
                    !validateEmail(email) &&
                    !isResetingPassword
                  )
                }
                autoFocus
              />
            </Grid>
            {!isResetingPassword && (
              <Grid item xs={12}>
                <PasswordInput
                  maxWidth={true}
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={alert.status}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                className={classes.btn}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading || !isValid()}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : isResetingPassword ? (
                  'Send'
                ) : (
                  'Login'
                )}
              </Button>
            </Grid>
            {!isResetingPassword && (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Link
                  to={CONSTANTS.resetPasswordPath}
                  onClick={() => {
                    setEmail('');
                    setPassword('');
                    setAlert({
                      status: true,
                      severity: 'info',
                      msg: 'Please enter your email to reset your password.'
                    });
                  }}
                  className={classes.link}
                >
                  Forgot password?
                </Link>
              </Grid>
            )}
          </form>
        ) : (
          <div>
            {user?.qrCodeImgUrl || user?.twoFactorAuthEnabled ? (
              <form
                onSubmit={verify2FACallback}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '2rem'
                }}
              >
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  alignItems="center"
                >
                  {user?.qrCodeImgUrl ? (
                    <>
                      <MFASteps />

                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <img
                          src={user?.qrCodeImgUrl}
                          alt="qr code"
                          style={{ maxWidth: '100%', height: 'auto' }}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    {codeError && (
                      <Alert severity={codeError?.type ?? 'error'}>
                        {codeError?.msg}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <label htmlFor="code-2fa" style={{ fontSize: '1.5rem' }}>
                      Enter your 6 digit Authentication Code
                    </label>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <div style={{ display: 'inline-block' }}>
                      <ReactOtpInput
                        shouldAutoFocus={true}
                        value={code}
                        onChange={setCode}
                        numInputs={6}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{ fontSize: '2rem' }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                        >
                          Verify
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => window.location.reload()}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {user?.qrCodeImgUrl ? null : (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleBackupCode}
                        disabled={
                          backupLoading || !email || !validateEmail(email)
                        }
                        style={{ padding: '10px 20px', width: 'auto' }}
                      >
                        {backupLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          'Lost your device? Click this to receive a one time passcode'
                        )}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </form>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Adminlogin;
