import React, { useContext } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import { useTourContext } from './TourContext';
import { styles, getTourSteps, tourConfig } from './util';
import { UserContext, ThemeContext } from 'src/store/ContextStore';
import CustomTooltip from './CustomTooltip';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function TourWrapper() {
  const { theme } = useContext(ThemeContext);
  const {
    setState,
    state: { run, stepIndex, steps },
    startTour,
    endTour
  } = useTourContext();
  const { user } = useContext(UserContext);
  const { tourSkip = true, userType } = user || {};

  const t = useAutoTranslation();

  const history = useHistory();

  useMount(() => {
    setState({
      steps: getTourSteps(userType)
    });

    if (!tourSkip) {
      // Show virtual tour
      startTour(userType);
    }
  });

  const tourStepHandle = (data) => {
    const { action, index, nextLink, prevLink, end } = data;

    if (action === ACTIONS.NEXT) {
      if (end) return endTour();

      setState({ stepIndex: index + 1 });
      if (nextLink) history.push(nextLink);
    } else {
      setState({ stepIndex: index - 1 });
      if (prevLink) history.push(prevLink);
    }
  };

  const handleCallBack = (data) => {
    const userTypeConfig = tourConfig[userType] || tourConfig.general;

    for (const step of userTypeConfig) {
      const { index: stepIndex, nextLink, prevLink, end } = step;
      const { action, index, type, status } = data;

      if (
        action === ACTIONS.CLOSE ||
        (status === STATUS.SKIPPED && run) ||
        status === STATUS.FINISHED
      ) {
        return endTour();
      } else if (type === EVENTS.STEP_AFTER && index === stepIndex) {
        return tourStepHandle({
          action,
          index,
          prevLink,
          nextLink,
          end
        });
      }
    }
  };

  return (
    <Joyride
      callback={handleCallBack}
      tooltipComponent={CustomTooltip}
      continuous
      run={run}
      stepIndex={stepIndex}
      steps={steps}
      disableScrollParentFix={true}
      disableScrolling={true}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      styles={{
        ...styles,
        options: {
          arrowColor: theme.header, // Beacon color
          backgroundColor: theme.appearance.color, // Bg color
          textColor: theme.appearance.text, // All text, title color
          zIndex: 1000000000
        }
      }}
      locale={{
        back: t('Go Back'),
        last: t('End tour'),
        start: t('Start tour')
      }}
    />
  );
}
