/**
 * Hook used when EMR is opened from TG
 * used to authenticate the code token query param
 * Refer https://fhir.epic.com/Documentation?docId=oauth2&section=standaloneOauth2Launch
 * Calls the Epic Oauth api with code token which then returns the access token
 * This access token is refreshed if expired
 */
import { useCallback, useEffect, useRef } from 'react';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';

import { useIntegrationsContext } from '../../IntegrationsContext';
import { EVENTS } from '../../integrationsReducer';

const REFRESH_FREQUENCY = 2700 * 1000; //45 minutes

// TODO save access token to db and read it from there on landing - probably with emr settings
// TODO add a check for emr launch token
// TDDD this needs to come from db
// const CLIENT_REFRESH_SECRET =
//   'JJh2L8cI0uu4yh62a09GKfYU9kuTCK4cpTFXgPtBz5qKuBmp+fKVEENn+mAX7HNalTD9gwLAawDdoPSUUBhnHg==';
// const CLIENT_ID = '86671a32-8e26-4fd7-8d69-66a5cab32a3e';
// const REDIRECT_URI = 'https://localhost:3000/admin/pedigree';

// hook to check code query param is present and then fetch access token
const useEMRCodeAuth = () => {
  const refreshAccessTokenTimer = useRef();
  const {
    content: { epicEMRState },
    dispatchUpdate
  } = useIntegrationsContext();

  const handleTokenResponse = useCallback((response) => {
    dispatchUpdate({
      type: EVENTS.SET_EPIC_EMR_STATE,
      payload: response
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on landing check `code` query param is present and fetch token
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      // get the auth token
      axios
        .get(APICONSTANTS.emrIntegrationAccessToken.replace(':code', code))
        .then((response) => {
          handleTokenResponse(response.data);
        })
        .catch((err) => {
          console.log(err);
          // handle error flag
        });
    }
    // should only happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRefreshToken = useCallback(() => {
    const params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', epicEMRState.refresh_token);

    axios
      .post(APICONSTANTS.emrIntegrationRefreshToken, {
        refresh_token: epicEMRState.refresh_token
      })
      .then(handleTokenResponse)
      .catch(() => {
        // TODO handle error
      });
  }, [epicEMRState.refresh_token, handleTokenResponse]);

  // if valid refresh token is present and there is no timer set
  // initiate token refresh timer
  useEffect(() => {
    if (epicEMRState.refresh_token && !refreshAccessTokenTimer.current) {
      const queryParams = new URLSearchParams(window.location.search);

      // check if token is about to expire or not - if yes then fetch a new one
      // if the existing token expiry is less than the refresh frequency - fetch a new one
      // only get refresh token if there is no code/laumch in the query params - so that this doesn't conflict with launch token
      if (
        epicEMRState.expires_in - Date.now() - REFRESH_FREQUENCY < 0 &&
        !(queryParams.get('code') || queryParams.get('launch'))
      ) {
        getRefreshToken();
      }
      // initiate token refresh timer
      refreshAccessTokenTimer.current = setInterval(() => {
        getRefreshToken();
      }, REFRESH_FREQUENCY);
    }

    return () => clearInterval(refreshAccessTokenTimer.current);
  }, [epicEMRState.expires_in, epicEMRState.refresh_token, getRefreshToken]);
};

export default useEMRCodeAuth;
