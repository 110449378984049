import React from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomIconBtn from '../../UI/button/CustomIconBtn';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { jsPDF } from 'jspdf';
import { downloadURI, downloadXML, downloadFile } from 'src/util/util';
import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import { Canvg } from 'canvg';
import html2canvas from 'html2canvas';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function ExportMenu(props) {
  const { user } = props;
  const { _id, familyId } = user;
  const { setSnack } = React.useContext(SnackContext);
  const { theme } = React.useContext(ThemeContext);

  const exportImage = async (export_pdf = false) => {
    const svgElement = document.getElementById('pedigree-chart');
    const diagnosesLegend = document.getElementById('diagnoses-legend');
    const pedigreeIdentifier = document.getElementById('pedigree-identifier');
    const pedigreeDates = document.getElementById('pedigree-dates');

    const shade =
      theme.appearance.value === 'light' ? 'white' : theme.appearance.shade;

    let diagnosesLegendImage, pedigreeIdentifierImage, pedigreeDatesImage;

    if (diagnosesLegend) {
      const diagnosesLegendOptions = {
        width: diagnosesLegend.clientWidth,
        height: diagnosesLegend.clientHeight,
        windowWidth: diagnosesLegend.clientWidth,
        windowHeight: diagnosesLegend.clientHeight,
        scale: 2,
        backgroundColor: shade
      };

      // Capture the "diagnoses-legend" div as an image using HTML2Canvas
      await html2canvas(diagnosesLegend, diagnosesLegendOptions).then(
        (canvas) => {
          diagnosesLegendImage = new Image();
          diagnosesLegendImage.src = canvas.toDataURL();
        }
      );
    }

    const pedigreeIdentifierOptions = {
      width: pedigreeIdentifier.clientWidth,
      height: pedigreeIdentifier.clientHeight,
      windowWidth: pedigreeIdentifier.clientWidth,
      windowHeight: pedigreeIdentifier.clientHeight,
      scale: 2,
      backgroundColor: shade
    };

    await html2canvas(pedigreeIdentifier, pedigreeIdentifierOptions).then(
      (canvas) => {
        pedigreeIdentifierImage = new Image();
        pedigreeIdentifierImage.src = canvas.toDataURL();
      }
    );

    const pedigreeDatesOptions = {
      width: pedigreeDates.clientWidth,
      height: pedigreeDates.clientHeight,
      windowWidth: pedigreeDates.clientWidth,
      windowHeight: pedigreeDates.clientHeight,
      scale: 2,
      backgroundColor: shade
    };

    await html2canvas(pedigreeDates, pedigreeDatesOptions).then((canvas) => {
      pedigreeDatesImage = new Image();
      pedigreeDatesImage.src = canvas.toDataURL();
    });

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svgElement);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = diagnosesLegendImage
      ? Math.max(diagnosesLegendImage.width, svgElement.clientWidth)
      : svgElement.clientWidth;

    canvas.height = diagnosesLegendImage
      ? svgElement.clientHeight +
        diagnosesLegendImage.height +
        pedigreeIdentifierImage.height
      : svgElement.clientHeight + pedigreeIdentifierImage.height;

    // Draw the svg image
    const canvg = Canvg.fromString(context, svgString, {
      scaleWidth: canvas.width,
      scaleHeight: canvas.height,
      ignoreDimensions: true,
      offsetY: diagnosesLegendImage
        ? diagnosesLegendImage.height + pedigreeIdentifierImage.height
        : pedigreeIdentifierImage.height
    });
    canvg.start();

    if (pedigreeIdentifierImage) {
      // Draw the pedigree identifier image
      context.drawImage(pedigreeIdentifierImage, 0, 0);
    }

    if (diagnosesLegendImage) {
      // Draw the diagnoses legend image
      context.drawImage(
        diagnosesLegendImage,
        0,
        pedigreeIdentifierImage.height
      );
    }

    if (pedigreeDatesImage) {
      // Draw the pedigree dates image
      context.drawImage(
        pedigreeDatesImage,
        canvas.width - pedigreeDatesImage.width,
        canvas.height - pedigreeDatesImage.height
      );
    }

    canvas.toBlob(function (blob) {
      const imageDataURL = URL.createObjectURL(blob);

      if (export_pdf) {
        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'in',
          format: [18, 12]
        });

        pdf.addImage(imageDataURL, 'PNG', 0, 0);
        pdf.save(`${familyId}.pdf`);
      } else {
        downloadURI(imageDataURL, `${familyId}.png`);
      }

      URL.revokeObjectURL(imageDataURL);
    }, 'image/png');
  };

  const exportPDF = () => {
    //export image as pdf
    exportImage(true);
  };
  const getXMLData = () => {
    axios
      .get(APICONSTANTS.getXMLData.replace(':user_id', _id))
      .then((res) => {
        downloadXML(res.data, familyId);
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error exporting as XML data',
          severity: 'error'
        });
      });
  };

  const getJsonData = () => {
    axios
      .get(APICONSTANTS.getGA4JsonData.replace(':user_id', _id))
      .then((res) => {
        downloadFile(JSON.stringify(res.data), familyId, 'json');
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'Error converting to JSON',
          severity: 'error'
        });
      });
  };

  const t = useAutoTranslation();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <FormGroup column>
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<AddPhotoAlternateIcon />}
                tooltip="Image"
                onClick={() => exportImage()}
              />
            }
            label={t('PNG')}
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<PostAddIcon />}
                tooltip="PDF"
                onClick={exportPDF}
              />
            }
            label={t('PDF')}
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<LibraryBooksOutlinedIcon />}
                tooltip="XML"
                onClick={getXMLData}
              />
            }
            label="XML"
          />
          <FormControlLabel
            control={
              <CustomIconBtn
                icon={<DataObjectIcon />}
                tooltip="JSON"
                onClick={getJsonData}
              />
            }
            label="JSON"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
