import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';

import { ThemeContext } from 'src/store/ContextStore';
import PatientTable from './PatientTable';
import { useIntegrationsContext } from '../IntegrationsContext';

const BUTTON_LABEL = 'Search on EMR';
const SEARCH_PATIENTS_ROUTE = `Patient`;

/**
 * Functional component to render EPIC integration button
 * checks if access token is present or not
 * fetches access & refresh token from the url if valid code is present
 * @param {Object} data {firstName, lastName, gender, dob}
 * @returns
 */
function EpicEmrButton({ data, emrSettings }) {
  const emrEndpoint = emrSettings.endpoint;
  const clientId = emrSettings.clientId;
  const redirectUri = emrSettings.redirectUri;
  const EPIC_AUTH_BASE_URL = `${emrEndpoint}/oauth2`;
  const EPIC_API_BASE_URL = `${emrEndpoint}/api/FHIR/R4`;
  const AUTHORIZE_LOGIN_URL = `${EPIC_AUTH_BASE_URL}/authorize`;

  const { theme } = useContext(ThemeContext);
  const [patient, setPatient] = useState();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const {
    content: { epicEMRState }
  } = useIntegrationsContext();

  if (!epicEMRState.access_token) {
    return (
      <Button
        href={`${AUTHORIZE_LOGIN_URL}?response_type=code&redirect_uri=${redirectUri}&client_id=${clientId}&scope=openid%20fhirUser&state=abc123&aud=${EPIC_API_BASE_URL}`}
        // target="_blank"
      >
        {BUTTON_LABEL} - EMR Login
      </Button>
    );
  }

  const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: theme.appearance.color,
    boxShadow: 24,
    p: 3,
    borderRadius: 3
  };

  const dob = new Date(data.dob);

  async function fetchPatient() {
    // dob is in yyyy-mm-dd format
    const birthdate = `${dob.getFullYear()}-${(dob.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${dob.getDate().toString().padStart(2, '0')}`;

    await axios
      .get(
        `${EPIC_API_BASE_URL}/${SEARCH_PATIENTS_ROUTE}?given=${data.firstName}&family=${data.lastName}&birthdate=${birthdate}`,
        {
          headers: {
            Authorization: `Bearer ${epicEMRState.access_token}`
          }
        }
      )
      .then((res) => {
        // check for min identifiable info
        if (res.data.entry[0].resource) {
          setPatient(res.data.entry[0].resource);
          const url = res.data.entry[0].link[0].url;
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${epicEMRState.access_token}`
              }
            })
            .then((patRes) => {
              setPatient(patRes.data);
            });
        }
      })
      .catch((err) => {
        // handle error
        console.error(err);
      });
  }

  const onClick = () => {
    // open modal and search patient
    setOpenSearchModal(true);
    fetchPatient();
  };

  return (
    <>
      <Button onClick={onClick}>{BUTTON_LABEL}</Button>
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-patients"
        aria-describedby="search-patients-description"
      >
        <Box sx={style}>
          <Typography id="search-patients" variant="h6" component="h2">
            {BUTTON_LABEL}
          </Typography>
          <Typography id="search-patients-description">
            {patient ? (
              <div>
                <PatientTable data={patient} />
              </div>
            ) : (
              <p>
                Searching for{' '}
                <strong>{`${data.firstName} ${data.lastName}`}</strong>...
              </p>
            )}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

function EpicEmrButtonWrapper({ data, ...rest }) {
  // disable button
  // if we don't have first & last name and dob - no need to check for auth
  if (data.firstName && data.lastName && data.dob) {
    return <EpicEmrButton data={data} {...rest} />;
  }

  return (
    <Tooltip title="Not enough information available, required First name, Last name & Date of Birth">
      <span>
        <Button disabled>{BUTTON_LABEL}</Button>
      </span>
    </Tooltip>
  );
}

export default EpicEmrButtonWrapper;
