import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { Grid, Chip } from '@mui/material';
import TileCard from './NoteTile';

import axios from 'axios';

import {
  AddressForm,
  AppointmentForm,
  ClinicalNotesForm,
  ConsentForm,
  ContactForm,
  ReferralForm,
  SampleForm,
  TestForm
} from './forms/FormsExport';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { AppContext } from 'src/store/ContextStore';

function ClinicalNotes({ data }) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const { familyId } = user;

  let [isModalOpen, setOpenModal] = React.useState(false);
  let [noteType, setNoteType] = React.useState('Referral');
  let [isEditing, setIsEditing] = React.useState(false);
  let [currentModel, setCurrentModel] = React.useState({});

  let [filter, setFilter] = React.useState({
    Referral: true,
    Appointment: true,
    Contact: true,
    Consent: true,
    Sample: true,
    Test: true,
    Address: true,
    'Clinical Note': true
  });

  let [tiles, setTiles] = React.useState([]);

  const addClicked = async () => {
    setOpenModal(true);
  };

  const handleChanges = (key) => {
    let replace = { ...filter };
    replace[key] = !replace[key];
    setFilter(replace);
  };

  const [noteTypes] = React.useState([
    'Referral',
    'Appointment',
    'Contact',
    'Sample',
    'Test',
    'Address',
    'Clinical Note',
    'Consent'
  ]);

  const handleClose = () => {
    setOpenModal(false);
    setIsEditing(false);
  };
  const familyMemberId =
    data?.data?.familyMemberId ??
    data?.data?.proBandId ??
    data?.data?.pid ??
    'none';

  const forms = (type, model = {}) => {
    switch (type) {
      case 'Referral':
        return (
          <ReferralForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Appointment':
        return (
          <AppointmentForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Contact':
        return (
          <ContactForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Consent':
        return (
          <ConsentForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Sample':
        return (
          <SampleForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Test':
        return (
          <TestForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Address':
        return (
          <AddressForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      case 'Clinical Note':
        return (
          <ClinicalNotesForm
            handleClose={handleClose}
            familyMemberId={familyMemberId}
            isEditing={isEditing}
            model={model}
          />
        );
      default:
        return <></>;
    }
  };

  React.useEffect(() => {
    axios
      .get(`/api/clinical-notes/all`, {
        params: { familyId }
      })
      .then((res) => {
        let edited = res.data;
        edited.sort(function (a) {
          if (a.pinned) return -1;
          return 1;
        });

        setTiles(edited);
      });
    // eslint-disable-next-line
  }, [isModalOpen]);
  //Fix empty form selection values, shouldn't be necessary with radio
  React.useEffect((setNoteType) => {
    if (noteType == '') setNoteType('Referral');
  });

  const renderTiles = () => {
    let cards = [];
    tiles.map((item) => {
      if (
        filter[item.noteType] &&
        (familyMemberId == item.familyMemberId || item.showInFamily)
      )
        cards.push(
          <TileCard
            model={item}
            key={item._id}
            type={item.noteType}
            setIsEditing={setIsEditing}
            setModalOpen={setOpenModal}
            setNoteType={setNoteType}
            setCurrentModel={setCurrentModel}
          />
        );
    });
    return cards;
  };

  return (
    <div className="user-form">
      {familyMemberId != 'none' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={noteType}
              onChange={(e) => setNoteType(e.target.value)}
            >
              <Grid container>
                {noteTypes.map((item, iter) => (
                  <Grid item key={iter} xs={6}>
                    <FormControlLabel
                      key={iter}
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
          <Box display={'flex'} gap={2} justifyContent={'start'}>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<AddCircleIcon fontSize="small" />}
              onClick={() => {
                addClicked();
              }}
            >
              {`Add ${noteType}`}
            </Button>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '1rem',
          gap: 1
        }}
      >
        <FormLabel component="label" className="form-label">
          Filter Clinical Records
        </FormLabel>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 10,
              paddingBottom: 50
            }}
          >
            {Object.keys(filter).map((key, iter) => (
              <Box
                key={key.value}
                variant="contained"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: 1,
                  width: 'fit-content',
                  py: 1.5,
                  borderRadius: '8px',
                  px: 2,
                  cursor: 'pointer',
                  bgcolor: filter[key] ? 'lightblue' : 'whitesmoke'
                }}
                onClick={(e) => handleChanges(key)}
              >
                <span>{key}</span>
              </Box>
            ))}
            <Box display={'flex'} gap={2} justifyContent={'start'}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setFilter({
                    Referral: false,
                    Appointment: false,
                    Contact: false,
                    Consent: false,
                    Sample: false,
                    Test: false,
                    Address: false,
                    'Clinical Note': false
                  });
                }}
              >
                {`CLEAR ALL`}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setFilter({
                    Referral: true,
                    Appointment: true,
                    Contact: true,
                    Consent: true,
                    Sample: true,
                    Test: true,
                    Address: true,
                    'Clinical Note': true
                  });
                }}
              >
                {`SELECT ALL`}
              </Button>
            </Box>
          </Grid>
        </Box>
        {renderTiles().map((tile, iter) => (
          <Grid key={iter} item xs={12}>
            {tile}
          </Grid>
        ))}
        {renderTiles().length == 0 && <Chip label={`No Notes Found!`} />}
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomDialog
          maxWidth={'sm'}
          title={isEditing ? `Edit ${noteType}` : `Create New ${noteType}`}
          open={isModalOpen}
          handleClose={handleClose}
          minHeight={'auto'}
          onClose={true}
        >
          {forms(noteType, currentModel)}
        </CustomDialog>
      </Modal>
    </div>
  );
}

export default ClinicalNotes;
