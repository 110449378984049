import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Button
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import React, { useState, useMemo, useEffect, useContext } from 'react';
import axios from 'src/axios';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import FormLabel from '../../UI/input/CustomFormLabel';
import TextInput from '../../UI/input/TextInput';
import { SnackContext } from 'src/store/ContextStore';
import { calculateAge } from 'src/util/util';

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15
  },
  button: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between',
    height: 55
  },
  label: {
    lineHeight: 1.3
  },
  infoAgeLabel: {
    fontSize: 10,
    opacity: 0.8
  }
});

function RiskFactorsMenu({ data }) {
  const classes = useStyles();
  const { setSnack } = useContext(SnackContext);
  const [loading, setLoading] = useState(false);
  const [gailRiskScore, setGailRiskScore] = useState(null);
  const [formValues, setFormValues] = useState({
    isBreastCancer: false,
    isMutation: false
  });

  let gender = useMemo(() => {
    return data.data.gender;
  }, [data]);

  const init = () => {
    let dob = data.data?.dob;
    if (dob) {
      let age = calculateAge(dob);
      setFormValues((pre) => ({ ...pre, age: String(age) }));
    }
  };

  const getRiskFactor = () => {
    let pid = data.data?.pid;
    if (!pid) return;
    axios
      .get(APICONSTANTS.getRiskFactor.replace(':pid', pid))
      .then((res) => {
        if (res.data) {
          const {
            isBreastCancer,
            isMutation,
            age,
            ageMen,
            nBiops,
            ageFLB,
            numRel,
            race
          } = res.data;

          setFormValues({
            isBreastCancer: isBreastCancer,
            isMutation: isMutation,
            age: String(age),
            ageMen: String(ageMen),
            nBiops: String(nBiops),
            ageFLB: String(ageFLB),
            numRel: String(numRel),
            race: race
          });
          setGailRiskScore(res.data.gailRiskScore);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    init();
    getRiskFactor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (e) => {
    const value =
      typeof e.target.value === 'string'
        ? e.target.value.trim()
        : e.target.value;

    if (e.target.type === 'radio') {
      const value = e.target.value === 'true';

      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value
      }));
    }
  };

  const calcGailRiskHandle = () => {
    if (formValues.isBreastCancer)
      return setSnack({
        status: true,
        msg: 'This tool cannot accurately calculate breast cancer risk for women with a medical history of any breast cancer or DCIS or LCIS.',
        severity: 'error'
      });

    if (formValues.isMutation)
      return setSnack({
        status: true,
        msg: 'This tool cannot accurately calculate breast cancer risk for women with known mutations in either the BRCA1 or BRCA2 gene, or other hereditary syndromes associated with higher risks of breast cancer.',
        severity: 'error'
      });

    let dob = data.data?.dob;
    if (dob) {
      let age = calculateAge(dob);
      if (age !== Number(formValues.age))
        return setSnack({
          status: true,
          msg: 'Age is not matching as per your dob in personal details tab',
          severity: 'error'
        });
    }

    if (!(formValues.age >= 35 && formValues.age <= 85))
      return setSnack({
        status: true,
        msg: 'This tool calculates risk for women between the ages of 35 and 85.',
        severity: 'error'
      });

    setLoading(true);
    const payload = {
      isBreastCancer: formValues.isBreastCancer,
      isMutation: formValues.isMutation,
      age: Number(formValues.age), // Patients's age
      laterAge: Number(formValues.age) + 5, // age+5
      ageMen: Number(formValues.ageMen), // Patient's age at the time of her first menstrual period
      nBiops: Number(formValues.nBiops), // Patient's Number of biopsies
      ageFLB: Number(formValues.ageFLB), // Patients's Age when she gave birth to her first child
      numRel: Number(formValues.numRel), // Patient's Number of 1st degree relatives with breast cancer
      race: formValues.race, // Patient's race
      pid: data.data.pid, // Patient's individual pid
      user_id: data.data.user_id // Patient's user_id
    };

    axios
      .post(APICONSTANTS.calculateGailRiskScore, payload)
      .then((resp) => {
        if (resp.data) setGailRiskScore(resp.data.riskScore);
      })
      .catch((error) => {
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let isGailRiskBtnDisabled = useMemo(() => {
    return (
      gender === 'male' ||
      loading ||
      !formValues.race ||
      !formValues.age ||
      !formValues.ageMen ||
      !formValues.nBiops ||
      !formValues.ageFLB ||
      !formValues.numRel
    );
  }, [gender, formValues, loading]);

  return (
    <div style={{ maxWidth: 390 }}>
      <Grid container spacing={2}>
        {/* isBreastCancer */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="label" className={classes.label}>
                Does the woman have a medical history of any breast cancer or of
                ductal carcinoma in situ (DCIS) or lobular carcinoma in situ
                (LCIS) or has she received previous radiation therapy to the
                chest for treatment of Hodgkin lymphoma?
              </FormLabel>
              <RadioGroup
                aria-label="isBreastCancer"
                name="isBreastCancer"
                value={String(formValues.isBreastCancer)}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        {/* isMutation */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="label" className={classes.label}>
                Does the woman have a mutation in either the BRCA1 or BRCA2
                gene, or a diagnosis of a genetic syndrome that may be
                associated with elevated risk of breast cancer?
              </FormLabel>
              <RadioGroup
                aria-label="isMutation"
                name="isMutation"
                value={String(formValues.isMutation)}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        {/* age */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormLabel component="label" className="form-label">
                    Current Age{' '}
                    {data.data?.dob && (
                      <span className={classes.infoAgeLabel}>
                        Current age based on DoB{' '}
                        <span style={{ fontStyle: 'italic' }}>
                          [insert calculated DoB].
                        </span>
                      </span>
                    )}
                  </FormLabel>
                </Grid>

                <TextField
                  size="small"
                  name="age"
                  value={formValues.age}
                  onChange={handleChange}
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* race */}
        <Grid item xs={12}>
          <div className="margin-top-4"></div>
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <TextInput
              label="Race/Ethnicity?"
              type="select"
              name="race"
              value={formValues.race}
              onChange={handleChange}
              options={CONSTANTS.raceOptions}
            />
          </FormControl>
        </Grid>

        {/* biopsies */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <div className="margin-top-4"></div>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <TextInput
                label="Number of breast biopsies?"
                type="select"
                name="nBiops"
                value={formValues.nBiops}
                onChange={handleChange}
                options={CONSTANTS.biopsiesOptions}
              />
            </FormControl>
          </Grid>
        )}

        {/* menstruation */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <div className="margin-top-4"></div>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <TextInput
                label="Age of first menstruation?"
                type="select"
                name="ageMen"
                value={formValues.ageMen}
                onChange={handleChange}
                options={CONSTANTS.menstruationOptions}
              />
            </FormControl>
          </Grid>
        )}

        {/* first birth */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <div className="margin-top-4"></div>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <TextInput
                label="Age at first birth?"
                type="select"
                name="ageFLB"
                value={formValues.ageFLB}
                onChange={handleChange}
                options={CONSTANTS.flbOptions}
              />
            </FormControl>
          </Grid>
        )}

        {/* numRel */}
        {gender === 'female' && (
          <Grid item xs={12}>
            <div className="margin-top-4"></div>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <TextInput
                label="Number of 1st degree relatives that have had breast cancer?"
                type="select"
                name="numRel"
                value={formValues.numRel}
                onChange={handleChange}
                options={CONSTANTS.relativeOptions}
              />
            </FormControl>
          </Grid>
        )}

        {gender === 'female' && Boolean(gailRiskScore) && (
          <Grid item xs={12}>
            <div className="margin-top-4"></div>
            <Alert severity="info">
              <span>GAIL RISK SCORE - {gailRiskScore}</span>
            </Alert>
          </Grid>
        )}

        {gender === 'female' && (
          <Grid item xs={12} className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={calcGailRiskHandle}
              disabled={isGailRiskBtnDisabled}
              loading={loading}
            >
              Calculate Gail Risk Score
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default RiskFactorsMenu;
