import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import logo from 'src/assets/images/trakgeneLogo.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8,
    border: '1px solid #dadce0',
    padding: 50,
    paddingTop: 30,
    paddingBottom: 20,
    width: '35%',
    [theme.breakpoints.down('lg')]: {
      width: '45%'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  content: {
    width: '100%',
    marginTop: theme.spacing(4)
  },
  logo: {
    width: '300px'
  }
}));

export default function BoxContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <img src={logo} alt="Trakgene Logo" className={classes.logo} />
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}
