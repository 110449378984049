import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { generateRandomPassword } from 'src/util/util';

const useStyles = makeStyles(() => ({
  red: {
    color: 'red'
  },
  green: {
    color: 'green'
  }
}));

export function PasswordStrengthAlert({ password }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Alert severity="info">
        <Typography variant="body2" gutterBottom>
          Password must be or contain:
        </Typography>
        <Typography
          variant="body2"
          display="block"
          className={password.length >= 8 ? classes.green : classes.red}
        >
          at least 8 characters long.
        </Typography>
        <Typography
          variant="body2"
          display="block"
          className={/[A-Z]/.test(password) ? classes.green : classes.red}
        >
          at least one upper case letter.
        </Typography>
        <Typography
          variant="body2"
          display="block"
          className={/[a-z]/.test(password) ? classes.green : classes.red}
        >
          at least one lower case letter.
        </Typography>
        <Typography
          variant="body2"
          display="block"
          className={/[\d]/.test(password) ? classes.green : classes.red}
        >
          at least one number.
        </Typography>
        <Typography
          variant="body2"
          display="block"
          className={
            /[^a-zA-Z0-9 ]/.test(password) ? classes.green : classes.red
          }
        >
          at least one special character.
        </Typography>
      </Alert>
    </Grid>
  );
}

export default function PasswordInput(props) {
  const {
    label,
    name,
    value,
    onChange,
    error,
    helperText,
    autoFocus,
    disabled,
    allowGenerate,
    setPassword
  } = props;
  const [showValue, setShowValue] = React.useState(false);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {label && (
        <Grid item container alignItems="baseline" spacing={2}>
          <Grid item>
            <FormLabel component="label" className="form-label">
              {label}
            </FormLabel>
          </Grid>
          {allowGenerate && (
            <Grid item>
              <Button
                size="small"
                color="primary"
                className="button-no-transformation"
                onClick={() => setPassword(generateRandomPassword())}
              >
                Generate randomly
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      <Grid item xs={12}>
        <OutlinedInput
          fullWidth
          type={showValue ? 'text' : 'password'}
          value={value || ''}
          onChange={onChange}
          name={name}
          error={!!error}
          inputProps={{ 'aria-label': props.ariaLabel || 'enter password' }}
          // autoFocus={autoFocus ?? undefined}
          disabled={disabled ?? undefined}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowValue((prevValue) => !prevValue)}
                onMouseDown={(e) => e.preventDefault()}
                size="large"
              >
                {showValue ? (
                  <Visibility fontSize="small" />
                ) : (
                  <VisibilityOff fontSize="small" />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {helperText && (
          <Typography variant="caption" color={error ? 'error' : undefined}>
            {helperText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
