import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';

import axios from 'src/axios';

import { Box, TextField } from '@mui/material';

import { SnackContext, AppContext } from 'src/store/ContextStore';

export default function AddressForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        addressLine1: '',
        addressLine2: '',
        town: '',
        city: '',
        county: '',
        state: '',
        postCode: '',
        correspondenceAddress: false,
        primaryAddress: false
      };
  const [formValues, setFormValues] = React.useState(defaultValues);

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
    if (familyMemberId == 'none') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: true
      }));
    }
  };

  const { setSnack } = React.useContext(SnackContext);

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.address, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Address Line 1</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="addressLine1"
              value={formValues.addressLine1}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Address Line 2</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="addressLine2"
              value={formValues.addressLine2}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Town</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="town"
              value={formValues.town}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>City</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="city"
              value={formValues.city}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>County</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="county"
              value={formValues.county}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>State</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="state"
              value={formValues.state}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Postcode</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="postcode"
              value={formValues.postcode}
            />
          </Box>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Correspondence Address
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.correspondenceAddress ?? false}
                onChange={onToggleSwitch}
                name="correspondenceAddress"
              />
            </Grid>
            <Grid item>
              <Typography>
                {formValues.correspondenceAddress ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Primary Address
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.primaryAddress ?? false}
                onChange={onToggleSwitch}
                name="primaryAddress"
              />
            </Grid>
            <Grid item>
              <Typography>
                {formValues.primaryAddress ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          handleClose={handleClose}
          onClick={handleSubmit}
          addLabel={`Add new address`}
        />
      </Grid>
    </form>
  );
}
