import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PATIENT_KEYS = [
  'id',
  'name',
  'active',
  'birthdate',
  'address',
  'gender',
  'maritalStatus',
  'managingOrganization'
];

// includes set of rules when getting a specific info from patient data
const getPatientDetail = (data, key) => {
  switch (key) {
    case 'address': {
      if (data[key]) {
        const address = data[key][0];
        // TODO fix multi line info
        return `${address.line[0]}, ${address.city}, ${address.country}`;
      } else {
        return '';
      }
    }
    case 'managingOrganization':
      return data[key].display;
    case 'maritalStatus':
      return data[key].text;
    case 'name':
      return data[key][0].text;
    default:
      return data[key];
  }
};

// returns an array of {label, value}
const createRows = (data) => {
  return PATIENT_KEYS.map((key) => ({
    label: key,
    value: getPatientDetail(data, key)
  }));
};

/**
 *
 * @param {Object} data
 * @param {Object} data.name
 * @returns Table with patient details
 */
export default function PatientTable({ data }) {
  const rows = createRows(data);

  console.log(rows);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Patient</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.label}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
