import React from 'react';

import { LoadingContext, SnackContext } from 'src/store/ContextStore';
import MainContainer from 'src/components/UI/container/MainContainer';
import SnackBar from 'src/components/UI/snack/SnackBar';
import BackdropLoader from 'src/components/UI/loader/BackdropLoader';
import CustomFormWithAuth from './CustomFormWithAuth';

export default function CustomForm() {
  const [loading, setLoading] = React.useState(false);
  const loadingProviderValue = React.useMemo(
    () => ({ loading, setLoading }),
    [loading]
  );

  const [snack, setSnack] = React.useState({
    status: false,
    msg: '',
    severity: ''
  });
  const snackProviderValue = React.useMemo(() => ({ setSnack }), []);

  const container = React.useMemo(
    () => (
      <MainContainer navItems={[]} className="grey-bg">
        <CustomFormWithAuth />
      </MainContainer>
    ),
    []
  );

  return (
    <>
      <LoadingContext.Provider value={loadingProviderValue}>
        <SnackContext.Provider value={snackProviderValue}>
          {container}
        </SnackContext.Provider>
      </LoadingContext.Provider>
      <BackdropLoader loading={loading} />
      <SnackBar
        open={snack.status}
        message={snack.msg}
        severity={snack.severity}
        setOpen={() => setSnack((prev) => ({ ...prev, status: false }))}
      />
    </>
  );
}
