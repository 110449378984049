import React, { useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import PedigreeEditor from 'src/components/pedigree/PedigreeEditor';
import { AppContext, SnackContext } from 'src/store/ContextStore';
import LegendMenu from './pedigree-sidebar/LegendMenu';
import HeaderTitle from '../UI/header/HeaderTitle';
import { defaultPedigreeConfig } from './pedigreeUtil';
import axios from 'src/axios';
import { APICONSTANTS } from '../Constants';

const defaultLabelFilters = {
  firstName: true,
  lastName: true,
  age: true,
  upn: true
};

const useStyles = makeStyles({
  container: { width: '100%', position: 'relative' },
  legendMenu: {
    position: 'absolute',
    right: 0,
    zIndex: -1,
    width: '100%'
  },
  titleContainer: {
    position: 'absolute',
    left: 0,
    zIndex: -1,
    width: '100%'
  }
});

export default function PedigreeBox({ patient }) {
  const [pedigreeConfig, setPedigreeConfig] = React.useState(
    defaultPedigreeConfig
  );

  const { setSnack } = React.useContext(SnackContext);

  React.useEffect(() => {
    axios
      .get(APICONSTANTS.pedigreeConfig)
      .then((res) => {
        if (res.data?.pedigreeConfig) {
          setPedigreeConfig((prev) => ({
            ...prev,
            ...res.data.pedigreeConfig
          }));
        }
      })
      .catch((err) => {
        setPedigreeConfig(defaultPedigreeConfig);
      });
  }, []);

  // Config for pedigree font and symbol-size
  const onConfigSave = () => {
    const data = {
      symbol_size: pedigreeConfig.symbol_size,
      fontSize: pedigreeConfig.fontSize
    };

    return axios
      .post(APICONSTANTS.pedigreeConfig, { data })
      .then((res) => {
        if (res.status === 200) {
          setPedigreeConfig((prev) => ({
            ...prev,
            ...res.data.pedigreeConfig
          }));
          setSnack({
            status: true,
            msg: 'Config Saved Successfully',
            severity: 'success'
          });
        }
      })
      .catch((/*err*/) =>
        setSnack({
          status: true,
          msg: 'An error occured',
          severity: 'error'
        }));
  };

  const classes = useStyles();
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const [labelsSet, setLabelsSet] = React.useState(defaultLabelFilters);

  const options = useMemo(
    () => ({ ...pedigreeConfig, labelsSet }),
    [pedigreeConfig, labelsSet]
  );

  let headerTitle = useMemo(() => {
    return `PEDIGREE: ${user?.familyId}`;
  }, [user]);

  return (
    <Box className={classes.container}>
      {/* Pedigree Identifier used for when exporting as img or pdf from pedigree sidebar.
          This will not appear on pedigree canvas */}
      <div className={classes.titleContainer} id="pedigree-identifier">
        <HeaderTitle
          variant="h5"
          text={headerTitle}
          autoWidth
          fontWeight={500}
        />
      </div>
      {/* Legend used for when exporting as img or pdf from pedigree sidebar.
          This will not appear on pedigree canvas */}
      {users.diagnoses && users.diagnoses.length > 0 && (
        <div className={classes.legendMenu}>
          <LegendMenu diagnoses={users.diagnoses} id="diagnoses-legend" />
        </div>
      )}
      <PedigreeEditor
        onConfigSave={onConfigSave}
        options={options}
        setOptions={setPedigreeConfig}
        labelsSet={labelsSet}
        setLabelsSet={setLabelsSet}
        patient={patient}
      />
    </Box>
  );
}
