import React from 'react';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

const SelectSpouseCard = (props) => {
  const {
    handleChange,
    selectedSpouse,
    spouse: { firstName, age },
    label: upn,
    value
  } = props;

  return (
    <Box border={1} borderColor="grey.800" sx={{ py: 1 }}>
      <Grid item container alignItems="center">
        <Grid item xs={1}>
          <Checkbox
            checked={selectedSpouse === value}
            onChange={handleChange}
            size="small"
            value={value}
          />
        </Grid>

        <Grid item xs={3}>
          <div>
            <span style={{ opacity: 0.7 }}>Upn :</span> {upn}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <span style={{ opacity: 0.7 }}>Name :</span> {firstName}
          </div>
        </Grid>
        <Grid item xs={1}>
          <div>
            <span style={{ opacity: 0.7 }}>Age : </span> {age}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SelectSpouseCard;
