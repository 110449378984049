import { createContext } from 'react';

// for storing user details
export const UserContext = createContext(null);

// for showing loader
export const LoadingContext = createContext(null);

// for snackbar
export const SnackContext = createContext(null);

// user ques
export const FormContext = createContext(null);

export const AppContext = createContext(null);

// for setting theme
export const ThemeContext = createContext(null);
