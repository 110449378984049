import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import axios from 'src/axios';
// import { validateEmail } from 'src/util/util';
import { SnackContext } from 'src/store/ContextStore';
import { useFormContext } from 'src/components/custom-form/FormContext';
import NextButton from 'src/components/UI/button/NextButton';
import ConfirmationModal from 'src/components/UI/modals/ConfirmationModal';
import { FORM_TYPES } from 'src/components/form-builder/CreateQuestionnaire/constants/formTypes';

import { APICONSTANTS, CONSTANTS } from './Constants';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import FormFields from './UserCustomFormFields.jsx';

import { jsPDF } from 'jspdf';
import { Box, Button, Modal } from '@mui/material';
import { Interweave } from 'interweave';
import { organizeFormGroups } from '../form-builder/CreateQuestionnaire/components/Preview/Preview';
import HeaderTitle from '../UI/header/HeaderTitle';

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 1px 5px rgb(0 74 116 / 15%)',
    maxWidth: '900px',
    padding: '24px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#fff'
  }
}));

export default function UserCustomForm({ readOnly }) {
  const classes = useStyles();
  const { setSnack } = React.useContext(SnackContext);

  const {
    form: schema,
    responses,
    _id,
    token,
    dispatchSubmitForm
  } = useFormContext();

  const [initialFormikValues, setInitialFormikValues] = useState(
    Object.keys(responses).length ? responses : {}
  );

  const [validationSchema, setValidationSchema] = useState(null);

  const [specificConditon, setSpecificConditon] = useState('');

  //setting render condition of starting node = true
  schema.config.schema[schema.config.startingGroupId].renderCondition = true;

  useEffect(() => {
    const yupValidationSchema = {};
    const initialValues = initialFormikValues;
    Object.keys(schema.config.schema).map((schemaId) => {
      let data = schema.config.schema[schemaId];
      (data.condition
        ? data.renderCondition === undefined
          ? true
          : data.renderCondition
        : true) &&
        data.formElements.map((element) => {
          //encoding label and replacing period  to resolve conflicting with formik
          let key = encodeURIComponent(element.label.replaceAll('.', '_'));

          if (element.isRequired) {
            if (
              element.type === FORM_TYPES.MULTIPLE_CHOICE ||
              element.type === FORM_TYPES.LINEAR_SCALE
            ) {
              yupValidationSchema[key] = yup
                .string()
                .required(`${element.label} is required`)
                .oneOf(element.options);
            } else if (element.type === FORM_TYPES.SHORT_TEXT) {
              yupValidationSchema[key] = yup
                .array()
                .min(1)
                .of(yup.string().required())
                .required();
            } else if (element.type === FORM_TYPES.CHECKBOX_INPUT) {
              yupValidationSchema[key] = yup
                .array()
                .of(
                  yup.object().shape({
                    checked: yup.boolean()
                  })
                )
                .test({
                  message: `${element.label} is required`,
                  test: (val) => !val.every((val) => val.checked === false)
                });
            } else {
              yupValidationSchema[key] = yup
                .string()
                .required(`${element.label} is required`);
            }
          }

          if (element.type === FORM_TYPES.CHECKBOX_INPUT) {
            const values = [];
            if (!initialValues[[key]]) {
              for (const option of element.options) {
                values.push({ label: option, checked: false, value: option });
              }
              if (element.isIdentifier) {
                values.push({ label: 'Other', checked: false, value: '' });
              }
            }
            initialValues[[key]] = initialValues[[key]] || [...values];
          } else if (element.type === FORM_TYPES.SHORT_TEXT) {
            initialValues[[key]] = initialValues[[key]] || [''];
          } else {
            initialValues[[key]] = initialValues[[key]] || '';
          }
        });
    });

    setInitialFormikValues(initialValues);
    setValidationSchema(yup.object(yupValidationSchema));
  }, [specificConditon]);

  const [openModal, setOpenModal] = React.useState(false);

  const [response, setResponse] = useState('');

  function saveFormDetails(values, shouldSubmit = false) {
    const formData = new FormData();

    let responses = {};
    Object.keys(values).map((key) => {
      if (values[key] instanceof File) {
        formData.append(key, values[key]);
        responses[key] = values[key].name;
      } else {
        responses[key] = values[key];
      }
    });

    formData.append('responses', JSON.stringify(responses));
    formData.append('_id', _id);

    let method = axios.put;
    if (shouldSubmit) {
      method = axios.post;
    }

    const response = method(APICONSTANTS.schemaAndResponses, formData, {
      headers: {
        [CONSTANTS.authTokenKey]: token,
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        if (shouldSubmit) dispatchSubmitForm();
        else {
          setSnack({
            status: true,
            msg: 'Saved successfully!',
            severity: 'success'
          });
        }
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'An error has occurred!',
          severity: 'error'
        });
      });

    setResponse(response);
  }

  function openSubmitModal() {
    setOpenModal(true);
  }

  const [shouldsubmit, setShouldSubmit] = React.useState(false);

  const formik = useFormik({
    initialValues: initialFormikValues,
    onSubmit: (values) => {
      shouldsubmit ? saveFormDetails(values, true) : saveFormDetails(values);
    },
    validationSchema: validationSchema
  });

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    {
      Object.keys(schema.config.schema).map((schemaId) => {
        let formData = schema.config.schema[schemaId];

        const key = formData.condition?.condition.split('| ')[1];
        const value = formData.condition?.value;
        const formikValue = formik.values[key];

        const previousFormRenderCondition =
          formData.previousConnections
            .map((id) => {
              return schema.config.schema[id]?.renderCondition;
            })
            .indexOf(true) > -1
            ? true
            : false;

        function arrayEquals(a, b) {
          // received b is an array of object , grabbing values
          const c = [];
          for (const item of b) {
            if (item.checked) {
              c.push(item.value);
            }
          }
          c.sort();
          return (
            Array.isArray(a) &&
            Array.isArray(c) &&
            a.length === c.length &&
            a.every((val, index) => val === c[index])
          );
        }

        let condition;
        if (
          key &&
          value &&
          Array.isArray(value) &&
          Array.isArray(formikValue)
        ) {
          condition =
            arrayEquals(value.sort(), formikValue.sort()) &&
            previousFormRenderCondition;
        } else {
          if (key)
            condition =
              formik.values[encodeURIComponent(key)] === value &&
              previousFormRenderCondition;
        }

        if (formData.condition) {
          setSpecificConditon(condition === undefined ? true : condition);
          schema.config.schema[schemaId].renderCondition =
            condition === undefined ? true : condition;
        } else {
          setSpecificConditon(false);
        }
      });
    }

    const groups = organizeFormGroups(schema.config);

    setGroups(groups);
  }, [formik.values]);

  const submitForm = (submit = false) => {
    formik.handleSubmit();
    if (submit) {
      setShouldSubmit(true);
      if (response) {
        return response;
      }
    }
  };

  const generatePdf = () => {
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(document.querySelector('#content'), {
      margin: [40, 60, 40, 40],
      callback: function (pdf) {
        pdf.save('doc.pdf');
      }
    });
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    marginInline: 10,
    width: '30%',
    minWidth: 500,
    height: '80%',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    display: 'flex',
    // justifyContent: 'center',
    paddingBlock: '1rem',
    paddingInline: '2rem'
  };

  return (
    <>
      <div>
        {readOnly && (
          <Button onClick={handleOpen} color="secondary" variant="contained">
            Generate Pdf
          </Button>
        )}
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <div style={{ minWidth: 400, width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={generatePdf}
                  color="secondary"
                  variant="contained"
                >
                  download
                </Button>
              </div>
              <div
                style={{
                  paddingBlock: 20
                }}
                id="content"
              >
                <Typography variant="h4" className="primary-color" gutterBottom>
                  {schema.title}
                </Typography>
                <Typography gutterBottom>{schema.description}</Typography>

                {Object.keys(formik.values).map((val) => {
                  let data = formik.values[val].toString();
                  if (typeof formik.values[val] === 'object') {
                    if (formik.values[val]?.type === 'file')
                      data = (
                        <a
                          href={formik.values[val].key}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {formik.values[val].name}
                        </a>
                      );
                    if (typeof formik.values[val][0] === 'object') {
                      let items = [];
                      for (const item of formik.values[val]) {
                        if (item.checked) items.push(item.value);
                      }
                      data = items.toString();
                      console.log(data);
                    }
                  }
                  return (
                    <div
                      key={val}
                      style={{ width: '100%', marginRight: '1rem' }}
                    >
                      <div
                        style={{
                          marginRight: '1rem'
                        }}
                      >
                        <div
                          style={{
                            fontSize: '1rem',
                            color: 'black',
                            fontWeight: 'bold',
                            marginRight: '1rem',
                            paddingBlock: '25px'
                          }}
                        >
                          <Interweave
                            content={decodeURIComponent(
                              val.replaceAll('_', '.')
                            )}
                          />
                        </div>
                        <div />
                        {data}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Box>
        </Modal>

        {groups &&
          groups.map((group, index) => {
            if (group[0].data.renderCondition) {
              return (
                <div
                  key={index}
                  className={classes.container}
                  // className={styles.previewContainer}
                  style={{ marginBlock: 20 }}
                >
                  {group.map((form, index) => {
                    if (form.type === 'form') {
                      return (
                        form &&
                        (form.data.condition
                          ? form.data.renderCondition
                          : true) && (
                          <div key={form.data.id}>
                            <HeaderTitle text={form.data.title} />
                            <Typography gutterBottom>
                              {form.data.description}
                            </Typography>
                            <FormikProvider value={formik}>
                              {form.data.formElements.map((element, index) => {
                                return (
                                  <FormFields
                                    element={element}
                                    key={index}
                                    formik={formik}
                                    readOnly={readOnly}
                                  />
                                );
                              })}
                            </FormikProvider>
                          </div>
                        )
                      );
                    } else {
                      return (
                        form &&
                        (form.data.condition
                          ? form.data.renderCondition
                          : true) && (
                          <div key={form.data.id}>
                            {/* <Typography
                              variant="h4"
                              className="primary-color"
                              gutterBottom
                            >
                              {form.data.title}
                            </Typography>
                            <Typography gutterBottom>
                              {form.data.description}
                            </Typography> */}
                            <FormikProvider value={formik}>
                              {form.data.formElements.map((element, index) => {
                                return (
                                  <FormFields
                                    element={element}
                                    key={index}
                                    formik={formik}
                                    readOnly={readOnly}
                                  />
                                );
                              })}
                            </FormikProvider>
                          </div>
                        )
                      );
                    }
                  })}
                </div>
              );
            }
          })}

        {!readOnly && (
          <NextButton
            label="Submit"
            onClick={openSubmitModal}
            onSaveClick={() => submitForm()}
          />
        )}
        {!readOnly && (
          <ConfirmationModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            onSaveClick={() => submitForm(true)}
          />
        )}
      </div>
    </>
  );
}
