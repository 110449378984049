import { Grid } from '@mui/material';
import React from 'react';
import HeaderTitle from '../UI/header/HeaderTitle';

import UsersTableInfo from '../proband-info/UsersTableInfo';

function PatientProband() {
  return (
    <Grid container>
      <HeaderTitle text={'Proband Information'} />
      <Grid item xs={12}>
        <UsersTableInfo patient={true} />
      </Grid>
    </Grid>
  );
}

export default PatientProband;
