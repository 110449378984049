import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Filters from 'src/components/pedigree/Filters';
import SelectFamily from './SelectFamily';
import { AppContext, ThemeContext } from 'src/store/ContextStore';
import { EVENTS } from 'src/components/admin/appReducer';
import TabPanel, { tabStyles } from 'src/components/UI/appbar/TabPanel';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function a11yProps(index) {
  return {
    id: `person-tab-${index}`,
    'aria-controls': `person-tabpanel-${index}`
  };
}

export default function SelectBar({ patient }) {
  const { theme } = React.useContext(ThemeContext);
  const classes = tabStyles(theme);
  const { content, dispatchUpdate } = React.useContext(AppContext);
  const [value, setValue] = React.useState(0);

  const { pedigreeUsers: rows, pedigreeSelectedUsers: users } = content;
  const setRows = (_rows) => {
    dispatchUpdate({ type: EVENTS.SET_PEDIGREE_USERS, value: _rows });
  };
  const setUsers = (value) => {
    dispatchUpdate({ type: EVENTS.SET_PEDIGREE_SELECTED_USER, value });
  };

  const t = useAutoTranslation();

  React.useEffect(() => {
    // checking if selected users gender, name, mobile, dob or dod has updated
    if (
      users &&
      users.selectedUser &&
      Object.keys(users.selectedUser).length !== 0
    ) {
      const { gender, firstName, lastName, dob, dod, mobile } =
        users.selectedUser;
      const id = users.selectedUser.tableData.id;
      const existingUser = rows[id];
      if (
        gender !== existingUser.gender ||
        firstName !== existingUser.firstName ||
        lastName !== existingUser.lastName ||
        dob !== existingUser.dob ||
        dod !== existingUser.dod ||
        mobile !== existingUser.mobile
      ) {
        const _rows = [...rows];
        _rows[id] = {
          ...rows[id],
          gender,
          firstName,
          lastName,
          dob,
          dod,
          mobile
        };
        setRows(_rows);
      }
    }
  }, [users.selectedUser]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const nextBtnClicked = () => {
    // move to next tab
    setValue((value + 1) % 2);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.colorPrimary}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="person-tabs"
          classes={{
            indicator: classes.indicator
          }}
        >
          <Tab label={t('Select Family')} {...a11yProps(0)} />
          {/* <Tab label="Apply Filters" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <SelectFamily
          index={value}
          rows={rows}
          setRows={setRows}
          setUsers={setUsers}
          patient={patient}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Filters clicked={nextBtnClicked} applyFilters={setRows} />
      </TabPanel>
    </div>
  );
}
