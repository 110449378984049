import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import axios from 'src/axios';
import TextInput from 'src/components/UI/input/TextInput';
import BoxContainer from 'src/components/UI/container/BoxContainer';
import { validateEmail } from 'src/util/util';
import { LoadingContext } from 'src/store/ContextStore';
import { GLOBAL_CONSTANTS } from 'src/components/Constants';
import { APICONSTANTS, CONSTANTS } from './Constants';

export default function EmailAuthentication({ setAuth }) {
  // from the url fetch token
  const params = new URLSearchParams(window.location.search);
  const token = params.get(GLOBAL_CONSTANTS.tokenKey);

  // used to extract or save b64 encoded email to localstorage
  const key = React.useMemo(() => {
    const _splitToken = token.split('.');
    return _splitToken[_splitToken.length - 1];
  }, [token]);

  const [email, setEmail] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const { loading, setLoading } = React.useContext(LoadingContext);

  const verifyAuthentication = (_be_fhq) => {
    return axios
      .post(
        APICONSTANTS.verifyEmail,
        { _be_fhq },
        {
          headers: { [CONSTANTS.authTokenKey]: token }
        }
      )
      .then((res) => {
        if (res.data.isValid) setAuth(true);
        else setShowError(res.data.error);
      })
      .catch((err) =>
        setShowError(err.response.data.error ?? 'An error has occurred!')
      )
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    setLoading(true);
    // check localstorage - if present pass it to parent
    const _be_fhq = window.localStorage.getItem(key);
    if (_be_fhq) {
      verifyAuthentication(_be_fhq);
    } else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function to encode email to base64, pass it to parent, save to localstorage
  const submitHandler = (e) => {
    e.preventDefault();
    setShowError('');
    const input = email.trim();
    // encode to base64
    if (validateEmail(input)) {
      const _be_fhq = btoa(input);
      // const _be_fhq = Buffer.from(input).toString('base64');
      window.localStorage.setItem(key, _be_fhq);
      verifyAuthentication(_be_fhq);
    } else setShowError('Invalid email!');
  };

  return (
    <BoxContainer>
      <form className={`user-form`} onSubmit={submitHandler}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {showError && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {showError}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              To verify the access, please enter the email this link was sent to
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              autoFocus
              // updateParent
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="small"
              disabled={loading}
              // endIcon={loading ? <CircularProgress size={15} /> : undefined}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </BoxContainer>
  );
}
