import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ChangePasswordForm from './ChangePasswordForm';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function MenuItems(props) {
  const [openModal, setOpenModal] = React.useState(false);
  const t = useAutoTranslation();
  return (
    <>
      <MenuItem onClick={() => setOpenModal(true)}>
        {t(`Change Password`)}
      </MenuItem>
      <CustomDialog
        title={'Change Password'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        minHeight="auto"
      >
        <ChangePasswordForm handleMenuClose={props.handleMenuClose} />
      </CustomDialog>
    </>
  );
}
