import React from 'react';
import Grid from '@mui/material/Grid';

const MFASteps = () => {
  return (
    <Grid item xs={12} style={{ textAlign: 'center' }}>
      <div style={{ marginBottom: 10, fontSize: '1.2rem' }}>
        <strong>Step 1</strong> - Download Authenticator App. Microsoft
        Authenticator (
        <a
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_IN&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>{' '}
        |{' '}
        <a
          href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>
        ) Google Authenticator (
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
          target="_blank"
          rel="noreferrer"
        >
          Android
        </a>{' '}
        |{' '}
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605"
          target="_blank"
          rel="noreferrer"
        >
          iOS
        </a>
        )
      </div>
      <div style={{ marginBottom: 10, fontSize: '1.2rem' }}>
        <strong>Step 2</strong> - Open Authenticator App and Scan QR Code
      </div>
      <div style={{ fontSize: '1.2rem' }}>
        <strong>Step 3</strong> - Select Account and Enter 6 Digit
        Authentication Code.
      </div>
    </Grid>
  );
};

export default MFASteps;
