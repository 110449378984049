import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSetState } from 'react-use';
import { useHistory } from 'react-router-dom';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false
};

export const TourContext = createContext({
  state: appState,
  setState: () => undefined
});
TourContext.displayName = 'TourContext';

export function TourProvider(props) {
  const [state, setState] = useSetState(appState);
  const history = useHistory();

  const startTour = useCallback(
    (userType) => {
      //Navigate to home page then start tour
      if (userType === CONSTANTS.userTypes.PATIENT)
        history.push(CONSTANTS.patientDashboardPath);
      else history.push(CONSTANTS.adminDashboardPath);

      setState({ run: true, tourActive: true, stepIndex: 0 });
    },
    [history, setState]
  );

  const endTour = useCallback(() => {
    setState({ run: false, tourActive: false, stepIndex: 0 });

    try {
      // Update tourSkip in backend
      axios.put(APICONSTANTS.updateTourSkip, { tourSkip: true });
    } catch (error) {
      console.log('ERRR');
    }
  }, [setState]);

  const value = useMemo(
    () => ({
      state,
      setState,
      startTour,
      endTour
    }),
    [setState, state, startTour, endTour]
  );

  return <TourContext.Provider value={value} {...props} />;
}

export function useTourContext() {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}
