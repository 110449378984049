import React, { useContext, useMemo, useRef, useState } from 'react';
import * as d3 from 'd3';
import makeStyles from '@mui/styles/makeStyles';
import Pedigreechart from './PedigreeChart/Pedigreechart';
import {
  AppContext,
  SnackContext,
  UserContext,
  ThemeContext
} from 'src/store/ContextStore';
import {
  Button,
  Grid,
  Typography,
  Box,
  Popper,
  IconButton,
  Slider
} from '@mui/material';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import {
  defaultPedigreeConfig,
  RELATIONS,
  relationship,
  validatePedigree
} from './pedigreeUtil';
import axios from 'src/axios';
import { GENDER } from '../questionnaire/Constants';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import UnhideModal from './UnhideModal';
import { useAutoTranslation } from 'src/hooks/useTranslate';

import PedigreeConfirmModel from './PedigreeConfirmModel';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { APICONSTANTS, CONSTANTS } from '../Constants';
import { init_Cache, next_pos, prev_pos } from './PedigreeChart/cache';
import PedigreeSideBar from './pedigree-sidebar/PedigreeSideBar';
import { SIDEBAR_VALUE } from './pedigree-sidebar/util';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import { EVENTS, getUniqueDiagnoses } from 'src/components/admin/appReducer';

import DiagnosisModal from './DiagnosisModal';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: 'flex',
    gap: 20,
    position: 'absolute',
    top: 55,
    left: 12,
    [theme.breakpoints.up('sm')]: {
      top: 12,
      left: 315
    }
  },
  hideTxtContainer: (props) => ({
    position: 'absolute',
    zIndex: 10,
    top: props.patient ? 55 : 100,
    left: 12,
    [theme.breakpoints.up('sm')]: {
      top: 55,
      left: 12
    }
  }),
  rightContainer: {
    position: 'absolute',
    right: 30,
    top: 10,
    zIndex: 9,
    width: 260,
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  datesContainer: {
    position: 'absolute',
    right: 30,
    bottom: 30,
    width: 260,
    zIndex: -1
  }
}));

import {
  Info,
  Settings,
  TextDecreaseOutlined,
  TextIncreaseOutlined
} from '@mui/icons-material';
import LegendMenu from './pedigree-sidebar/LegendMenu';
import Menu from './Menu/Menu';
import FormLabel from '../UI/input/CustomFormLabel';
import TextInput from '../UI/input/TextInput';
import {
  getOrganizeTwinData,
  removeTwinIdentifiers
} from './PedigreeChart/utils';
import { green } from '@mui/material/colors';

function PedigreeEditor({
  labelsSet,
  patient,
  setLabelsSet,
  options,
  setOptions,
  onConfigSave
}) {
  const classes = useStyles();
  const { user: loggedInUser } = React.useContext(UserContext);

  let isStudent = useMemo(() => {
    return loggedInUser.userType === CONSTANTS.userTypes.STUDENT;
  }, [loggedInUser]);

  const { content, dispatchUpdate } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user, family } = users;
  const { setSnack } = useContext(SnackContext);
  const { theme } = useContext(ThemeContext);

  const [sidebar, setSidebar] = useState({
    open: false,
    expand: false,
    expandAccordian: [SIDEBAR_VALUE.legend]
  });
  const [openModal, setOpenModal] = React.useState(false);

  const t = useAutoTranslation();

  const [deepCopy, setDeepCopy] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [cancel, setCancel] = useState(true);

  const [open, setOpen] = React.useState(false);

  // state for context menu
  const [menu, setMenu] = React.useState(false);

  const [configMenu, setConfigMenu] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setMenu(false);
  };

  const [error, setError] = React.useState(false);

  // pedigree state
  const [pedigreeState, setPedigreeState] = useState(
    CONSTANTS.pedigreeState.unchanged
  );

  const famliyCopy = JSON.parse(JSON.stringify(family));
  const config =
    user.pedigreeConfig && JSON.parse(JSON.stringify(user.pedigreeConfig));

  const replacer = (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (key === 'parent_node') {
        return undefined;
      }
    }
    return value;
  };

  const history = useHistory();
  const [triggerExit, setTriggerExit] = useState({
    onOk: false,
    path: ''
  });

  const handleGoToIntendedPage = React.useCallback(
    (location) => history.push(location),
    [history]
  );

  React.useEffect(() => {
    if (triggerExit.onOk) {
      handleGoToIntendedPage(triggerExit.path);
    }

    const unblock = history.block((location) => {
      if (
        location.pathname !== '/pedigree/' &&
        pedigreeState === CONSTANTS.pedigreeState.modified
      ) {
        handleOpen();
      } else {
        setTriggerExit({ onOk: true, path: location.pathname });
      }

      setTriggerExit((obj) => ({ ...obj, path: location.pathname }));
      if (triggerExit.onOk) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [
    handleGoToIntendedPage,
    history,
    triggerExit.onOk,
    triggerExit.path,
    pedigreeState
  ]);

  const initiatePedigree = () => {
    user.pid = user._id;
    // user.gender = user.gender && user.gender !== GENDER.unknown ? user.gender : GENDER.male;
    user.gender = user.gender ?? GENDER.unknown;

    let initialData;

    if (family.length === 0) {
      user.top_level = 'true';
      delete user.mother;
      delete user.father;
      delete user.spouse;
      initialData = [user];
    } else {
      if (user?.pedigreeConfig?.length === 0 || !user.pedigreeConfig) {
        const data = validatePedigree(user, famliyCopy);
        initialData = [...data, user];
      } else {
        config.map((p) => {
          const member = famliyCopy.find((m) => m.pid === p.pid);
          if (member) {
            p.member_id = member._id;
            p.diagnoses = member.diagnoses;
            p.diagnosesAge = member.diagnosesAge;
            p.relationship = member.relationship;
            p.firstName = member.firstName;
            p.lastName = member.lastName;
            p.isHide = member.isHide ?? false;
            p.upn = member.upn ?? '';
            p.familyMemberId = member.familyMemberId ?? '';
            p.genderIdentity = member?.genderIdentity;
          }
        });

        const mother = config.find(
          (p) => p.relationship === relationship.mother
        );
        const father = config.find(
          (p) => p.relationship === relationship.father
        );
        const spouse = config.find(
          (p) => p.relationship === relationship.spouse
        );

        if (!mother || !father) {
          if (spouse && (spouse?.mother || spouse?.father)) {
            user.mother = spouse?.mother;
            user.father = spouse?.father;
            user.noparents = 'true';
          } else {
            user.top_level = 'true';
          }
        } else {
          user.mother = mother?.pid;
          user.father = father?.pid;
        }

        user.spouse = spouse?.pid;

        initialData = getOrganizeTwinData([user, ...config]);
      }
    }
    return initialData;
  };

  React.useEffect(() => {
    const initialData = initiatePedigree();

    setDeepCopy(JSON.parse(JSON.stringify(initialData, replacer)));
    setDataset([...initialData]);
  }, [user, cancel, labelsSet]);

  React.useMemo(() => {
    sessionStorage.clear();
    const initialData = initiatePedigree();

    init_Cache(JSON.stringify(initialData, replacer), user.familyId);
  }, [user]);

  const [data, setData] = useState(null);

  // State for pedigree is in selection mode
  const [selectionMode, setSelectionMode] = useState(false);
  const selectionModeRef = useRef(false);

  const handleClick = (event, d, nodeRef) => {
    // If user is not patient
    if (!patient && !selectionModeRef.current) {
      // Make selected node highlighted
      d3.select(nodeRef).style('stroke-width', '.25em');
      d3.select(nodeRef).style('stroke', theme.accent);

      setData({ ...d, nodeRef: nodeRef });
      setSidebar((pre) => ({
        ...pre,
        open: true,
        expandAccordian: [SIDEBAR_VALUE.legend, SIDEBAR_VALUE.addRelative] // Make Legend & Add Relative default expanded
      }));
    }
  };

  const undo = () => {
    const pos = prev_pos();
    const data = sessionStorage.getItem(`${user.familyId + '-' + pos}`);
    if (data) {
      setDeepCopy(() => {
        return JSON.parse(data);
      });
      setDataset(() => {
        return JSON.parse(data);
      });
    }
  };

  const redo = () => {
    const pos = next_pos();
    const data = sessionStorage.getItem(`${user.familyId + '-' + pos}`);
    if (data) {
      setDeepCopy(() => {
        return JSON.parse(data);
      });
      setDataset(() => {
        return JSON.parse(data);
      });
    }
  };

  const onSumbit = () => {
    const updatedDeepcopy = removeTwinIdentifiers(deepCopy);
    const data = updatedDeepcopy.filter((p) => p.pid !== user._id && !p.hidden);
    const config = updatedDeepcopy.filter((p) => p.pid !== user._id);
    const personalDetails = updatedDeepcopy.filter(
      (p) => p.pid === user._id
    )[0];

    if (pedigreeState === CONSTANTS.pedigreeState.modified) {
      axios
        .post(APICONSTANTS.savePedigree.replace(':id', user._id), {
          personalDetails,
          data,
          config,
          familyId: user.familyId
        })
        .then(({ status, data }) => {
          if (status === 201) {
            const newDataset = deepCopy.map((pre) => {
              if (pre.proBandId) {
                // Here update dateModified for proband
                return {
                  ...pre,
                  dateModified: data.dateModified
                };
              } else {
                if (pre.not_set === 'true' || pre.upn) {
                  return { ...pre };
                } else {
                  let updatedFamilyData = data.family.find(
                    (familyData) => pre.pid === familyData.pid
                  );

                  return {
                    ...pre,
                    member_id: updatedFamilyData?.member_id,
                    upn: updatedFamilyData?.upn,
                    familyMemberId: updatedFamilyData?.familyMemberId
                  };
                }
              }
            });

            setDeepCopy(() => {
              return JSON.parse(JSON.stringify(newDataset));
            });
            setDataset(newDataset);

            setPedigreeState(CONSTANTS.pedigreeState.unchanged);

            // Here update diagnoses
            const diagnoses = getUniqueDiagnoses(newDataset);
            dispatchUpdate({
              type: EVENTS.SET_PEDIGREE_SELECTED_USER,
              value: {
                ...users,
                diagnoses: diagnoses
              }
            });

            setSnack({
              status: true,
              msg: 'Successfully saved',
              severity: 'success'
            });
          }
        })
        .catch((/*err*/) => {
          setSnack({
            status: true,
            msg: 'An error occured',
            severity: 'error'
          });
        });
    } else {
      setSnack({
        status: true,
        msg: 'No changes detected in Pedigree',
        severity: 'info'
      });
    }
  };

  const onContinue = async (e, save = false) => {
    if (save) {
      onSumbit();
    }
    setTriggerExit((obj) => ({
      ...obj,
      onOk: true
    }));
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const unHideAllHandle = () => {
    handleCloseModal();
    const newDataset = deepCopy.map((pre) => ({ ...pre, isHide: false }));

    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(newDataset));
    });
    setDataset(newDataset);
  };

  const unHideHandle = (updatedDatasetID) => {
    handleCloseModal();
    const newDataset = deepCopy.map((pre) => {
      let isHide = updatedDatasetID.includes(pre.pid) ? false : pre.isHide;

      return {
        ...pre,
        isHide: isHide
      };
    });

    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(newDataset));
    });
    setDataset(newDataset);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const onNodeClick = (e, d) => {
    if (!patient) {
      setMenu({ d });
      setAnchorEl(e.currentTarget);
    }
  };

  const pedigree = useMemo(() => {
    if (dataset) {
      return (
        <Pedigreechart
          options={options}
          dataset={dataset}
          handleClick={handleClick}
          undo={undo}
          redo={redo}
          onNodeClick={onNodeClick}
          onCloseMenu={handleClose}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset, options]);

  const handleCloseDialog = () => {
    setError(false);
  };

  const isHide = React.useMemo(() => {
    return dataset?.some((data) => data.isHide);
  }, [dataset]);

  const hideDataset = React.useMemo(() => {
    return dataset?.filter((data) => data.isHide);
  }, [dataset]);

  let headerTitle = useMemo(() => {
    return `PEDIGREE: ${user?.familyId}`;
  }, [user]);

  const handleSelectionExit = (state) => {
    setSelectionMode(state);
  };

  const contextMenu = React.useMemo(() => {
    return (
      <Menu
        node={menu.d}
        setPedigreeState={setPedigreeState}
        data={data}
        deepCopy={deepCopy}
        setDataset={setDataset}
        setDeepCopy={setDeepCopy}
        handleClose={handleClose}
        dataset={dataset}
        setError={setError}
        selectionMode={selectionMode}
        selectionModeRef={selectionModeRef}
        handleSelectionExit={handleSelectionExit}
      />
    );
  }, [menu, dataset]);

  const relMap = {
    [RELATIONS.siblings]: 'sibling',
    [RELATIONS.children]: 'parent',
    [RELATIONS.partner]: 'spouse'
  };

  return (
    <Box position={'relative'}>
      {/* Pedigree Dates used for when exporting as img or pdf from pedigree sidebar.
          This will not appear on pedigree canvas */}
      <Box
        className={classes.datesContainer}
        id="pedigree-dates"
        sx={{
          border: 0,
          padding: '8px',
          backgroundColor: '#f3f3f3',
          borderRadius: '10px'
        }}
      >
        <PedigreeDates dataset={dataset} theme={theme} />
      </Box>

      {/* Pedigree SideBar (Hide for patient) */}
      {!patient && (
        <PedigreeSideBar
          data={data}
          deepCopy={deepCopy}
          setDeepCopy={setDeepCopy}
          setDataset={setDataset}
          dataset={dataset}
          setPedigreeState={setPedigreeState}
          setSidebar={setSidebar}
          sidebar={sidebar}
          setData={setData}
          labelsSet={labelsSet}
          setLabelsSet={setLabelsSet}
          setError={setError}
        />
      )}

      {/* Pedigree Context Menu (Hide for patient) */}
      {!patient && !selectionMode && (
        <Popper open={menu} anchorEl={anchorEl}>
          {contextMenu}
        </Popper>
      )}

      <CustomDialog
        open={error}
        title={'Info'}
        handleClose={handleCloseDialog}
        minHeight={100}
        distanceTop={100}
        allowBackgroundClose={true}
        showCancelBtn={true}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Info fontSize="large" />
          {error}
        </Box>
      </CustomDialog>

      <CustomDialog
        open={configMenu}
        title={'Pedigree Settings'}
        handleClose={() => {
          setConfigMenu(false);
        }}
        showActions={true}
        onSave={onConfigSave}
        minHeight={400}
        distanceTop={150}
        allowBackgroundClose={true}
      >
        <ConfigMenu options={options} setOptions={setOptions} />
      </CustomDialog>

      <Grid className={classes.rightContainer}>
        <Box position={'absolute'} left={-50} top={-3}>
          <IconButton onClick={() => setConfigMenu(true)}>
            <Settings />
          </IconButton>
        </Box>

        {/* Heading */}
        <HeaderTitle
          variant="h5"
          text={headerTitle}
          autoWidth
          fontWeight={500}
        />

        {/* Legend Menu */}
        <div style={{ marginBottom: users.diagnoses.length ? '15px' : '0px' }}>
          <DiagnosisModal
            setPedigreeState={setPedigreeState}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setDataset={setDataset}
            diagnoses={users.diagnoses}
          />
          <LegendMenu
            diagnoses={users.diagnoses}
            id="diagnoses-legend-canvas"
            setPedigreeState={setPedigreeState}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setDataset={setDataset}
          />
        </div>

        {/* Pedigree Dates */}
        <Box
          sx={{
            border: 0,
            padding: '8px',
            backgroundColor: '#f3f3f3',
            borderRadius: '10px'
          }}
        >
          <PedigreeDates dataset={dataset} theme={theme} />
        </Box>
      </Grid>

      <Grid>
        {isHide && (
          <Grid item xs={12} className={classes.hideTxtContainer}>
            <Alert severity="info">
              This pedigree has hidden family members.
            </Alert>
          </Grid>
        )}

        <CustomDialog
          title={'Unhide Family Members'}
          open={openModal}
          handleClose={handleCloseModal}
          minHeight="auto"
        >
          <UnhideModal
            hideDataset={hideDataset}
            unHideAllHandle={unHideAllHandle}
            unHideHandle={unHideHandle}
          />
        </CustomDialog>

        <PedigreeConfirmModel
          open={open}
          handleClose={handleClose}
          onContinue={onContinue}
        />
        {selectionMode && (
          <Box
            position={'absolute'}
            zIndex={1000}
            bgcolor={'#eeeeee'}
            top={5}
            width={'100%'}
            height={44}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Alert severity="warning" icon=" " sx={{ fontWeight: 'bold' }}>
              Entered Selection Mode : Select a{' '}
              {relMap[selectionMode?.relation]} for UPN {selectionMode.node.upn}
            </Alert>
          </Box>
        )}
        <div>{pedigree}</div>
        {/* Hide for patient */}
        {!patient && !selectionMode && (
          <Grid className={classes.btnContainer}>
            <Button color="secondary" variant="contained" onClick={onSumbit}>
              {t(`Save`)}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                sessionStorage.clear();
                setCancel(!cancel);
              }}
            >
              {t(`Cancel`)}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setOpenModal(true)}
              disabled={!isHide}
            >
              {t(`UnHide`)}
            </Button>

            {/* FONT SIZE */}
            <FontSlider options={options} setOptions={setOptions} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PedigreeEditor;

const PedigreeDates = ({ dataset, theme }) => {
  if (!theme.dateFormat) theme = { dateFormat: 'MM/DD/YYYY' };
  const t = useAutoTranslation();

  let probandUser = useMemo(() => {
    return dataset?.find((data) => !!data.proBandId) || {};
  }, [dataset]);

  return (
    <Grid container>
      <Grid item container direction="row" xs={12}>
        <Typography variant="h6" style={{ fontSize: '1rem' }}>
          <strong>{t(`Date Created:`)} </strong>
          <span>
            {moment(probandUser.dateGenerated).format(theme.dateFormat)}
          </span>
        </Typography>
      </Grid>

      <Grid item container direction="row" xs={12}>
        <Typography variant="h6" style={{ fontSize: '1rem' }}>
          <strong>{t(`Last Modified Date:`)} </strong>
          <span>
            {moment(
              probandUser.dateModified || probandUser.dateGenerated
            ).format(theme.dateFormat)}
          </span>
        </Typography>
      </Grid>

      <Grid item container direction="row" xs={12}>
        <Typography variant="h6" style={{ fontSize: '1rem' }}>
          <strong>{t(`Print Date:`)} </strong>
          <span>{moment(new Date()).format(theme.dateFormat)}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

const FontSlider = ({ options, setOptions }) => {
  const onChange = (e, val) => {
    if (val) {
      let fontSize = parseInt(options.fontSize) + val;
      if (fontSize < 10) fontSize = 10;
      if (fontSize > 30) fontSize = 30;
      setOptions((prev) => ({ ...prev, fontSize: fontSize }));
    } else {
      setOptions((prev) => ({ ...prev, fontSize: e.target.value }));
    }
  };

  return (
    <Box
      position={'absolute'}
      width={200}
      bottom={-45}
      left={-305}
      display={'flex'}
      alignItems={'center'}
      gap={2}
    >
      <IconButton onClick={(e) => onChange(e, -2)}>
        <TextDecreaseOutlined fontSize="small" />
      </IconButton>
      <Slider
        size="medium"
        step={2}
        marks
        value={options.fontSize}
        min={10}
        max={30}
        name="fontSize"
        onChange={(e) => onChange(e)}
      />
      <IconButton onClick={(e) => onChange(e, 2)}>
        <TextIncreaseOutlined fontSize="small" />
      </IconButton>
    </Box>
  );
};

const ConfigMenu = ({ options, setOptions }) => {
  const handleChange = (e) => {
    setOptions((prev) => {
      const opt = {
        ...prev,
        [e.target.name]: e.target.value
      };

      return opt;
    });
  };

  const reset = () => {
    setOptions(defaultPedigreeConfig);
  };

  return (
    <form className="user-form">
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={2}>
            <FormLabel component="label" className="form-label">
              Symbol
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', w: 1, gap: 3, alignItems: 'center' }}>
              <TextInput
                variant="outlined"
                onChange={handleChange}
                fullWidth
                name="symbol_size"
                value={options.symbol_size}
                placeholder="45"
              />

              <Slider
                step={2}
                marks
                value={options.symbol_size}
                min={35}
                max={55}
                name="symbol_size"
                onChange={handleChange}
              />

              <strong>{options.symbol_size}px</strong>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={2}>
            <FormLabel component="label" className="form-label">
              Font Size
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', w: 1, gap: 3, alignItems: 'center' }}>
              <TextInput
                variant="outlined"
                name="fontSize"
                value={options.fontSize}
                placeholder="16"
                onChange={handleChange}
              />
              <Slider
                step={2}
                marks
                value={options.fontSize}
                min={10}
                max={25}
                name="fontSize"
                onChange={handleChange}
              />
              <strong>{options.fontSize}px</strong>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ position: 'absolute', right: 10, bottom: 80 }}>
        <Button variant="contained" size="small" onClick={reset}>
          Reset to Defaults
        </Button>
      </Box>
    </form>
  );
};
