import React, { useEffect } from 'react';
import Render from './Render';
import { ThemeContext } from 'src/store/ContextStore';

function Pedigreechart({
  options,
  dataset,
  handleClick,
  undo,
  redo,
  onNodeClick,
  onCloseMenu
}) {
  const { theme } = React.useContext(ThemeContext);

  const colors = {
    shade:
      theme.appearance.value === 'light' ? 'white' : theme.appearance.shade,
    text: theme.appearance.text
  };

  const refresthDataset = (dataset) => {
    dataset.forEach((mem) => {
      delete mem.id;
      delete mem.parentNode;
    });
  };

  const render = React.useMemo(() => {
    refresthDataset(dataset);

    return (
      <Render
        undo={undo}
        redo={redo}
        options={options}
        dataset={dataset}
        handleClick={handleClick}
        colors={colors}
        onNodeClick={onNodeClick}
        onCloseMenu={onCloseMenu}
      />
    );
  }, [dataset, options, undo, redo]);

  return <div>{render}</div>;
}

export default Pedigreechart;
