import React from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '../../../UI/input/CustomFormLabel';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import { APICONSTANTS } from 'src/components/Constants';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import axios from 'src/axios';
import { Box, Button, MenuItem, Select, TextField } from '@mui/material';

import DateInput from '../../../UI/input/DateInput';
import { FormControl } from '@mui/material';
import { formatDate } from 'src/util/util';
import { SnackContext, AppContext } from 'src/store/ContextStore';

import { handleUpload } from './formService';
import TextInput from 'src/components/UI/input/TextInput';

export default function ReferralForm({
  handleClose,
  familyMemberId,
  isEditing,
  model
}) {
  const { content } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const defaultValues = isEditing
    ? { ...model, fileAttachments: [] }
    : {
        familyId: user.familyId,
        familyMemberId: familyMemberId,
        referralName: '',
        referralId: '',
        referralDate: new Date(),
        referredBy: '',
        referralStatus: 'Open', //Open Closed
        referralType: 'None',
        fileAttachments: [], //Make this form
        referralNotes: '',
        showInFamily: true,
        dateClosed: null,
        reasonForClosure: ''
      };
  const [formValues, setFormValues] = React.useState(defaultValues);

  const [typeOptions] = React.useState([
    'Self',
    'GP',
    'Hospital',
    'Private Provider'
  ]);

  const handleChange = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value
    }));
  };

  const handleDelete = (e) => {
    let fileAttachments = formValues.fileAttachments;
    const deleted = formValues.fileAttachments[e];

    axios.delete(`${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });
    fileAttachments = fileAttachments.filter((attachment, i) => i !== e);
    setFormValues((prevValues) => ({
      ...prevValues,
      fileAttachments
    }));
  };

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = formValues.fileAttachments;
    fileAttachments.push(e.target.files[0].name);
    setFormValues({
      ...formValues,
      fileAttachments: fileAttachments
    });
  };

  //Sure about this! Will be good for show in Family
  const onToggleSwitch = (e) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.checked
    }));
    if (familyMemberId == 'none') {
      setFormValues((prevValues) => ({
        ...prevValues,
        showInFamily: true
      }));
    }
  };

  const { setSnack } = React.useContext(SnackContext);

  //Think about this
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    return axios
      .put(APICONSTANTS.referral, formValues)
      .then((res) => {
        handleClose();
        if (res.status === 200) {
          setSnack({
            status: true,
            msg: 'Successfully saved',
            severity: 'success'
          });
        } else {
          setSnack({
            status: true,
            msg: `Upload failed!`,
            severity: 'error'
          });
        }
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: `Upload failed! ${err}`,
          severity: 'error'
        });
      });
  };

  return (
    //Should be fine to reuse old class?
    <form className="user-form">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referral Name</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="referralName"
              value={formValues.referralName}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referral ID</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="referralId"
              value={formValues.referralId}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referral Date</FormLabel>
          </Grid>
          <DateInput
            value={formValues.referralDate}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                referralDate: e
              }))
            }
            variant="outline"
            minDate={isEditing ? new Date(1600, 0, 0) : new Date()}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referred By</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="referred By"
              value={formValues.referralBy}
            />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referral Status</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <FormControl fullWidth>
              <Select
                style={{ width: '100%' }}
                name="referralStatus"
                value={formValues.referralStatus}
                onChange={handleChange}
              >
                {['Open', 'Closed'].map((option) => (
                  <MenuItem value={option} key={option} onClick={handleChange}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {formValues.referralStatus == 'Closed' && (
          <>
            <Grid item xs={6}>
              <Grid sx={{ paddingBlock: 1 }}>
                <FormLabel>Date Closed</FormLabel>
              </Grid>
              <DateInput
                name="dateClosed"
                value={formValues.dateClosed}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    dateClosed: e
                  }))
                }
                variant="outline"
                minDate={
                  isEditing ? new Date(1600, 0, 0) : formValues.referralDate
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Grid sx={{ paddingBlock: 1 }}>
                <FormLabel>Reason For Closure</FormLabel>
              </Grid>
              <Box sx={{ display: 'flex', position: 'relative' }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  name="reasonForClosure"
                  value={formValues.reasonForClosure}
                  multiline={true}
                />
              </Box>
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', position: 'relative', paddingBlock: 1 }}>
            <TextInput
              label="Referral Type"
              type="autocomplete"
              name="referralType"
              value={formValues.referralType || null}
              onChange={(value) => {
                setFormValues({
                  ...formValues,
                  referralType: value
                });
              }}
              options={typeOptions || []}
              helperText="Please select or enter a referral type"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid sx={{ paddingBlock: 1 }}>
            <FormLabel>Referral Notes</FormLabel>
          </Grid>
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <TextField
              variant="outlined"
              fullWidth
              onChange={handleChange}
              name="referralNotes"
              value={formValues.referralNotes}
              multiline={true}
            />
          </Box>
        </Grid>
        <Grid item xs={7} style={{ paddingTop: '1.5rem' }}>
          <FormLabel>File Attachments </FormLabel>
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" color="secondary" component="label">
            Upload Attachments
            <input
              accept="image/*,.pdf,.txt"
              hidden
              type="file"
              onChange={handleFile}
            />
          </Button>
          <Stack spacing={1} style={{ paddingTop: '1rem' }}>
            {formValues.fileAttachments.map((attachment, i) => (
              <Chip
                key={i}
                label={attachment}
                variant="outlined"
                onDelete={() => handleDelete(i)}
              />
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6} sm={7}>
            <FormLabel component="label" className="form-label">
              Show in family
            </FormLabel>
          </Grid>
          <Grid item container xs={6} sm={3} alignItems="center">
            <Grid item>
              <Switch
                color="secondary"
                checked={formValues.showInFamily ?? false}
                onChange={onToggleSwitch}
                name="showInFamily"
              />
            </Grid>
            <Grid item>
              <Typography>{formValues.showInFamily ? 'Yes' : 'No'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <ModalButtons
          isEditing={isEditing}
          hideContinueAdding={true}
          onClick={handleSubmit}
          handleClose={handleClose}
          addLabel={`Add new referral`}
        />
      </Grid>
    </form>
  );
}
