import React from 'react';
import MenuItems from '../admin/MenuItems';
import MainContainer from '../UI/container/MainContainer';
import { CONSTANTS, NAV_ITEMS } from '../Constants';
import PatientMain from './PatientMain';
import { AppContext, SnackContext, UserContext } from 'src/store/ContextStore';
import SnackBar from 'src/components/UI/snack/SnackBar';
import Deactivated from 'src/components/UI/container/Deactivated';
import { red } from '@mui/material/colors';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import Tour from 'src/components/tour/Tour';

const SERVER_DOWN = 'server_down';
const SERVER_MAINTENANCE = 'server_maintenance';

function PatientPanel() {
  const [navItems, setNavItems] = React.useState(NAV_ITEMS.patient);

  const [snack, setSnack] = React.useState({
    status: false,
    msg: '',
    severity: ''
  });
  const snackProviderValue = React.useMemo(() => ({ setSnack }), []);

  const { user } = React.useContext(UserContext);

  return (
    <>
      {/* Virtual Tour */}
      <Tour />

      {user?.status === CONSTANTS.client_status.INACTIVE && (
        <Deactivated
          title="This account has been deactivated!"
          subtext="Please contact the admin of your genetics department if there any questions."
        />
      )}
      {user?.email && user?.status === CONSTANTS.client_status.ACTIVE && (
        <>
          <SnackContext.Provider value={snackProviderValue}>
            <MainContainer navItems={navItems} menuItems={<MenuItems />}>
              <PatientMain setNavItems={setNavItems} />
            </MainContainer>
          </SnackContext.Provider>
          <SnackBar
            open={snack.status}
            message={snack.msg}
            severity={snack.severity}
            setOpen={() => setSnack((prev) => ({ ...prev, status: false }))}
          />
        </>
      )}
      {user?.status === SERVER_MAINTENANCE && (
        <Deactivated
          icon={<ManageHistoryIcon style={{ fontSize: 100 }} />}
          title="System Maintenance in Progress"
          subtext="We're currently updating the system. This may take some time. Thank you for your patience."
        />
      )}
      {user?.status === SERVER_DOWN && (
        <Deactivated
          icon={<ErrorOutlineIcon style={{ color: red[400], fontSize: 100 }} />}
          title="Well, this is unexpected!"
          subtext="An error has occurred and we are working to fix the problem. We will be back up and running shortly."
        />
      )}
    </>
  );
}

export default PatientPanel;
