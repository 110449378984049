import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import TextInput from './TextInput';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'flex-end',
    marginTop: 10,
    gap: 10
  },
  colorPickerContainer: {
    display: 'flex',
    gap: 10
  },
  colorPreview: {
    height: 50,
    width: 50,
    background: 'conic-gradient(red, yellow, lime, aqua, blue, magenta, red)',
    borderRadius: '50%',
    position: 'relative'
  },
  hiddenInput: {
    opacity: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0
  },
  textInput: {
    border: 'none',
    borderRadius: 4,
    fontSize: '1rem',
    padding: '0 14px',
    width: 90
  },
  colorBox: {
    width: '40px'
  }
}));

const ColorPicker = ({ color, onChange }) => {
  const classes = useStyles();
  const [colorHex, setColorHex] = useState(color);

  const validateColor = (value) => /^#([0-9a-f]{3}){1,2}$/i.test(value);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setColorHex(value);

    if (validateColor(value)) {
      onChange({ target: { name: 'color', value } });
    }
  };

  useEffect(() => {
    setColorHex(color);
  }, [color]);

  return (
    <div className={classes.colorPickerContainer}>
      <div className={classes.colorPreview}>
        <input
          type="color"
          onChange={handleInputChange}
          value={colorHex}
          name="color"
          className={classes.hiddenInput}
        />
      </div>
      <div style={{ width: '140px' }}>
        <TextInput
          type="text"
          value={colorHex?.toUpperCase()}
          onChange={handleInputChange}
          className={classes.textInput}
        />
      </div>
      <div
        className={classes.colorBox}
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
};

export default ColorPicker;
