import React from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import FormLabel from 'src/components/UI/input/CustomFormLabel';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function RadioInput(props) {
  const {
    label,
    name,
    value,
    onChange,
    options,
    row,
    disabled,
    helperText,
    error
  } = props;

  const t = useAutoTranslation();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item>
        <FormLabel required={props.required}>{label}</FormLabel>
      </Grid>
      <Grid item>
        <FormControl component="fieldset" error={error}>
          <RadioGroup
            aria-label={name}
            name={name}
            value={value}
            onChange={onChange}
            row={row ?? undefined}
          >
            {options.map((el, i) => (
              <FormControlLabel
                key={i}
                disabled={disabled && el.value !== value ? true : undefined}
                value={el.value}
                control={<Radio size="small" />}
                label={t(el.label)}
              />
            ))}
          </RadioGroup>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
