import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function HeaderTitle(props) {
  const { theme } = React.useContext(ThemeContext);

  const style = { color: theme.header, fontWeight: 500 };
  if (props.fontWeight) style.fontWeight = 500;
  const { showBtn = false } = props;

  const t = useAutoTranslation();

  return (
    <Grid
      item
      xs={props.autoWidth ? 'auto' : 12}
      style={props.style ? props.style : {}}
    >
      <Typography
        variant={props.variant ? props.variant : 'h5'}
        component={props.component ? props.component : 'h2'}
        style={style}
        gutterBottom
      >
        {t(props.text)}
      </Typography>
      {/* <div className="margin-bottom-2" /> */}
      {showBtn && (
        <Button
          style={props.buttonStyle}
          disabled={props.disableAddBtn}
          variant="contained"
          color="secondary"
          onClick={props.addClicked}
          // className={classes.addButton}
          endIcon={<AddCircleIcon fontSize="small" />}
          size={props.addBtnSize ?? undefined}
        >
          {props.addText}
        </Button>
      )}
    </Grid>
  );
}
