import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { validateDate } from 'src/util/util';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormHelperText } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';

const useStyles = makeStyles({
  datepicker: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#000'
    },
    '& .MuiInputBase-root': {
      '& .MuiButtonBase-root': {
        padding: 0
      }
    }
  }
});

const defaultInvalidDateMessage = 'Invalid date.';

export default function DateInput(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    disableFuture,
    fullWidth,
    disabled,
    variant,
    showPlaceholder,
    minDateMessage,
    maxDateMessage,
    minDate,
    allowDateSelection = true,
    error,
    helperText,
    liveText = false,
    helperTextColour
  } = props;

  const { theme } = useContext(ThemeContext);
  let format = props.format ?? theme.dateFormat;

  if (format == 'MM/DD/YYYY') format = 'MM/dd/yyyy';
  if (format == 'DD.MM.YYYY') format = 'dd/MM/yyyy';
  if (format == 'DD/MM/YYYY') format = 'dd/MM/yyyy';

  const views = ['year'];
  if (allowDateSelection) views.push('date');

  const [invalidDateMessage, setInvalidDateMessage] = React.useState('');
  const [date, setDate] = React.useState(value);

  // React.useEffect(() => {
  //   console.log(value);
  //   setDate(null);
  // }, [error, helperText, value]);

  // used to disable the invalid date message when typing
  const onFocus = React.useCallback(() => {
    setInvalidDateMessage('');
  }, []);

  // check if valid date, then set the value otherwise show the error
  const onBlur = (e) => {
    if (date === null || validateDate(date)) onChange(date);
    else setInvalidDateMessage(defaultInvalidDateMessage);
  };

  React.useEffect(() => {
    if (liveText) {
      setInvalidDateMessage('');
      if (error && helperText) setInvalidDateMessage(helperText);
    }
  });

  const onDateChange = (date) => {
    if (error && helperText) setInvalidDateMessage(helperText);
    onChange(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={value ? new Date(value) : null}
        onChange={onDateChange}
        disableFuture={disableFuture}
        format={format}
        // onAccept={onAccept}
        minDate={minDate ? new Date(minDate) : new Date('01/01/1600')}
      />
      <FormHelperText
        style={helperTextColour ? { color: helperTextColour } : {}}
      >
        {invalidDateMessage}
      </FormHelperText>
    </LocalizationProvider>
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    // className={classes.datepicker}
    // openTo={date && allowDateSelection ? 'date' : 'year'}
    // views={views}
    // disableToolbar
    // autoOk
    // variant="inline"
    // format={format}
    // disableFuture={disableFuture ?? undefined}
    // // margin="normal"
    // value={date}
    // onChange={setDate}
    // onBlur={onBlur}
    // onFocus={onFocus}
    // onAccept={onAccept}
    // KeyboardButtonProps={{
    //   'aria-label': 'date'
    // }}
    // orientation="landscape"
    // disabled={disabled ?? undefined}
    // fullWidth={fullWidth ?? undefined}
    // inputVariant={variant ?? undefined}
    // placeholder={showPlaceholder ? format : undefined}
    // minDate={minDate ?? new Date('01/01/1600')}
    // minDateMessage={
    //   minDateMessage ?? 'Invalid Date. Date can not be in the past.'
    // }
    // maxDateMessage={
    //   maxDateMessage ?? 'Invalid Date. Date can not be in the future.'
    // }
    // invalidDateMessage={invalidDateMessage}
    // />
  );
}
