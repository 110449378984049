import React from 'react';

import useEMRCodeAuth from './hooks/useEMRCodeAuth';
import useEMRLaunchAuth from './hooks/useEMRLaunchAuth';

export default function EpicEMRWrapper({ children, emrSettings }) {
  // hook to get access token from `code` query param
  useEMRCodeAuth();

  // hook to redirect to `code` query param from `launch` token
  // this re-triggers `code` authentication so that new values can be decoded
  useEMRLaunchAuth(emrSettings);

  return <>{children}</>;
}
