import React from 'react';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';

export default function Success(props) {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <Grid item>
        <Zoom in={true}>
          <CheckCircleOutlineIcon
            style={{ color: green[500], fontSize: 200 }}
          />
        </Zoom>
      </Grid>
      <Grid item>
        <Typography variant="h5">
          {props.text || 'Questionnaire has been submitted successfully.'}
        </Typography>
        <Typography>
          {props.subText || 'The link is no longer valid.'}
        </Typography>
      </Grid>
    </Grid>
  );
}
