import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: '10',
    position: '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    position: 'sticky',
    // borderTop: '1px solid #e8e8e8',
    bottom: 0,
    backgroundColor: ({ bgColor }) => {
      return bgColor ?? '#ffffff';
    },
    padding: '15px 0 15px 0',
    display: 'flex',
    gap: 20,
    // justifyContent: 'flex-end',
    justifyContent: 'center',
    width: '100%'
  }
  // btn: {
  //     backgroundColor: '#379fb4',
  //     borderColor: '#379fb4',
  //     color: '#fff',
  //     '&:hover': {
  //         backgroundColor: '#2d2d2d',
  //         borderColor: '#2d2d2d'
  //     }
  // }
}));

const NextButton = (props) => {
  const {
    theme: {
      appearance: { color }
    }
  } = React.useContext(ThemeContext);

  const classes = useStyles({ bgColor: color });

  const t = useAutoTranslation();
  return (
    <div className={`${classes.root} margin-top-4`}>
      <Button
        disabled={props.disabled || props.loading}
        endIcon={props.loading ? <CircularProgress size="small" /> : undefined}
        color="secondary"
        variant="contained"
        type={props.onClick ? 'button' : 'submit'}
        className={classes.btn}
        onClick={props.onClick}
      >
        {props.label ? t(props.label) : t('Save & Continue')}
      </Button>
      {props.intermediateAction && (
        <Button
          color="secondary"
          variant="contained"
          type="button"
          onClick={props.intermediateAction}
        >
          {t(props.intermediateBtnLabel) || t('Save & Return')}
        </Button>
      )}
      {props.onSaveClick && (
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={props.onSaveClick}
        >
          {t(props.saveBtnLabel) || t('Save')}
        </Button>
      )}
      {props.onSavePDFCopy && (
        <Button
          onClick={props.onSavePDFCopy}
          color="secondary"
          variant="contained"
        >
          {t('Save PDF Copy')}
        </Button>
      )}
      {props.onPreviousClick && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={props.onPreviousClick}
        >
          {t('back')}
        </Button>
      )}
    </div>
  );
};

export default NextButton;
