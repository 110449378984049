import React, { useEffect } from 'react';
import ProgressTracker from '../form-builder/ProgressTracker';
import { Box, Grid, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HeaderTitle from '../UI/header/HeaderTitle';
import axios from 'src/axios';
import { APICONSTANTS, CONSTANTS } from '../Constants';
import { AppContext, ThemeContext, UserContext } from 'src/store/ContextStore';
import { EVENTS } from '../admin/appReducer';
import PatientLinks from './PatientLinks';

const useStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    backgroundColor: '#efefef',
    paddingTop: 6,
    paddingInline: 12
  },
  selectBar: {
    display: 'flex',
    alignItems: 'center',
    border: '3px solid #535354 ',
    borderRadius: 10,
    paddingBlock: 6,
    paddingInline: 12
  }
}));

function PatientDashboard() {
  const classes = useStyles();
  const { setTheme } = React.useContext(ThemeContext);

  const {
    content: { selectedGroup },
    dispatchUpdate
  } = React.useContext(AppContext);

  const { user } = React.useContext(UserContext);

  useEffect(() => {
    axios
      .get(`${APICONSTANTS.getCustomTheme}/${selectedGroup}`)
      .then((res) => {
        if (res.data) setTheme(res.data);
      })
      .catch((/*err*/) => {
        setTheme(CONSTANTS.customTheme);
      });
  }, [selectedGroup]);

  React.useMemo(() => {
    axios
      .get(APICONSTANTS.formBuilderGeneratedLinks)
      .then((res) => {
        let _form = [];
        const patient_formSchema = res.data.map((d) => {
          if (_form.indexOf(d.form.title) === -1) {
            _form.push(d.form.title);
            return {
              title: d.form.title,
              _id: d.form._id
            };
          }
        });
        dispatchUpdate({
          type: EVENTS.SET_FORM_BUILDER_TAB,
          tab: 'schemas',
          value: patient_formSchema.filter((f) => f)
        });
        dispatchUpdate({
          type: EVENTS.SET_FORM_BUILDER_TAB,
          tab: 'linksGenerated',
          value:
            selectedGroup === 'default'
              ? res.data
              : res.data?.filter(
                  (data) => data.client_group_id === selectedGroup
                )
        });
      })
      .catch(() => {
        //
      });
  }, [selectedGroup]);

  const onGroupChange = (e) => {
    if (e.target.value && e.target.value !== 'default') {
      axios
        .post(APICONSTANTS.setPatientSelectedUserGroup, {
          selectedUserGroup: e.target.value
        })
        .then((res) => {
          dispatchUpdate({
            type: EVENTS.SET_SELECTED_GROUP,
            value: e.target.value
          });
        })
        .catch((err) => {
          dispatchUpdate({
            type: EVENTS.SET_SELECTED_GROUP,
            value: 'default'
          });
        });
    } else {
      dispatchUpdate({
        type: EVENTS.SET_SELECTED_GROUP,
        value: 'default'
      });
    }
  };

  return (
    <Grid container spacing={6}>
      {/* <Grid item xs={12}>
        <Box style={{ backgroundColor: '#f0f6ff' }} padding={3}>
          <HeaderTitle text={'Notifications'} />
          <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
            <Alert severity="info">This is an information message!</Alert>
            <Alert severity="info">This is an information message!</Alert>
          </div>
        </Box>
      </Grid> */}
      {user?.userGroup && user.userGroup.length > 1 && (
        <Grid item xs={12}>
          <Grid container className={classes.selectBar}>
            <Grid item xs={10}>
              <HeaderTitle text={'Selected Group'} />
            </Grid>
            <Grid item xs={12} md={2}>
              <Select
                fullWidth
                value={selectedGroup}
                variant={'outlined'}
                onChange={onGroupChange}
              >
                <MenuItem key={'default'} value={'default'}>
                  Default
                </MenuItem>
                {user?.userGroup?.map((option) => {
                  return (
                    <MenuItem key={option._id} value={option._id}>
                      {`${option.label}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box className={classes.bar}>
          <HeaderTitle text={'Progress'} />
        </Box>
        <ProgressTracker selectedGroup={selectedGroup} />
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.bar} marginY={4}>
          <HeaderTitle text={'Your responses'} />
        </Box>

        <PatientLinks />
      </Grid>
    </Grid>
  );
}

export default PatientDashboard;
