import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const PhenotypesDetailsCard = ({
  name,
  id,
  definition,
  synonyms = [],
  comment
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {name}
        </Typography>
        <Typography variant="body1" sx={{ borderBottom: '1px solid #ccc' }}>
          {id}
        </Typography>

        <Typography
          variant="body2"
          sx={{ borderBottom: '1px solid #ccc', py: 1 }}
        >
          {definition}
        </Typography>
        <Typography
          variant="body2"
          sx={{ borderBottom: '1px solid #ccc', py: 1 }}
        >
          <span style={{ fontWeight: 'bold' }}>Synonyms:</span>{' '}
          {synonyms.join(', ')}
        </Typography>
        <Typography
          variant="body2"
          sx={{ borderBottom: '1px solid #ccc', py: 1 }}
        >
          <span style={{ fontWeight: 'bold' }}>Comment:</span> {comment}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default PhenotypesDetailsCard;
