export const basePath = '/questionnaires';

export const APICONSTANTS = Object.freeze({
  schemaAndResponses: `${basePath}/schema-with-responses`,
  verifyEmail: `${basePath}/authenticate`
});

export const CONSTANTS = Object.freeze({
  authTokenKey: 'x-fhq-key',

  user_status: {
    DEACTIVATED: 'DEACTIVATED',
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    SUBMITTED: 'SUBMITTED'
  }
});
