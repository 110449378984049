import { MenuItem, Select } from '@mui/material';
import React, { useContext, useState } from 'react';

// ContextAPI
import { PopupContext } from '../../../../CreateQuestionnaire';

// Constants
import {
  FORM_TYPES,
  getUserSideFormTypes
} from '../../../../constants/formTypes';
import CheckboxInput from '../formTypes/CheckboxInput/CheckboxInput';
import Dropdown from '../formTypes/Dropdown/Dropdown';
import EmbedContent from '../formTypes/EmbedContent/EmbedContent';
import LinearScale from '../formTypes/LinearScale/LinearScale';
import MultipleChoice from '../formTypes/MultipleChoice/MultipleChoice';
import ParaInput from '../formTypes/ParaInput/ParaInput';
import TextInput from '../formTypes/TextInput/TextInput';
import EmailInput from '../formTypes/EmailInput/EmailInput';
import DateTimeInput from '../formTypes/DateTimeInput/DateTimeInput';
import FileUpload from '../formTypes/FileUplaod/FileUpload';
import Signature from '../formTypes/Signature/Signature';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function AddFormElement() {
  const [popupContext] = useContext(PopupContext);
  const [formType, setFormType] = useState(popupContext.data.type);
  const [formTypesArray] = useState(getUserSideFormTypes());

  const FormType = () => {
    switch (formType) {
      case FORM_TYPES.SHORT_TEXT:
        return <TextInput edit={popupContext.edit} />;
      case FORM_TYPES.LONG_TEXT:
        return <ParaInput edit={popupContext.edit} />;
      case FORM_TYPES.CHECKBOX_INPUT:
        return <CheckboxInput edit={popupContext.edit} />;
      case FORM_TYPES.DROPDOWN:
        return <Dropdown edit={popupContext.edit} />;
      case FORM_TYPES.MULTIPLE_CHOICE:
        return <MultipleChoice edit={popupContext.edit} />;
      case FORM_TYPES.LINEAR_SCALE:
        return <LinearScale edit={popupContext.edit} />;
      case FORM_TYPES.EMBED_CONTENT:
        return <EmbedContent edit={popupContext.edit} />;
      case FORM_TYPES.EMAIL_INPUT:
        return <EmailInput edit={popupContext.edit} />;
      case FORM_TYPES.DATE_TIME_INPUT:
        return <DateTimeInput edit={popupContext.edit} />;
      case FORM_TYPES.FILE_UPLOAD:
        return <FileUpload edit={popupContext.edit} />;
      case FORM_TYPES.SIGNATURE:
        return <Signature edit={popupContext.edit} />;
      default:
        return <></>;
    }
  };

  const t = useAutoTranslation();

  return (
    <div>
      <h3>{t(`Select the form type`)}</h3>
      <Select
        variant="outlined"
        placeholder="Form Type"
        value={formType}
        style={{ width: '100%' }}
        onChange={(e) => {
          setFormType(e.target.value);
        }}
      >
        {formTypesArray.map((ft, index) => (
          <MenuItem key={`FormType_${index}`} value={ft.key}>
            {t(ft.value)}
          </MenuItem>
        ))}
      </Select>
      <div style={{ minWidth: 400 }}>
        <FormType />
      </div>
    </div>
  );
}

export default AddFormElement;
