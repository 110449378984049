import React from 'react';
import { CallMade, DataObject, Code, MoreVert } from '@mui/icons-material/';
import GetAppIcon from '@mui/icons-material/GetApp';
import { red } from '@mui/material/colors';
import CustomIconBtn from 'src/components/UI/button/CustomIconBtn';
import DropButton from 'src/components/UI/button/DropButton';
import { CONSTANTS } from 'src/components/Constants';
import Visibility from '@mui/icons-material/Visibility';

const UserTableActions = ({
  status,
  isProband,
  onDeleteClick,
  onViewPatientClick,
  onViewClick,
  getXMLData,
  getJsonData,
  markAsImported
}) => {
  const isEditable = status !== CONSTANTS.user_status.CREATED;
  const isMarkAsImported =
    isEditable && status === CONSTANTS.user_status.SUBMITTED;

  const isSubmitted = status === CONSTANTS.user_status.SUBMITTED;

  const options = [
    {
      icon: <CallMade fontSize="small" style={{ color: '#2ba9bf' }} />,
      label: 'View Submission',
      onClick: onViewPatientClick
    },
    {
      icon: <Code fontSize="small" style={{ color: '#2ba9bf' }} />,
      label: 'XML',
      onClick: getXMLData
    },
    {
      icon: <DataObject fontSize="small" style={{ color: '#2ba9bf' }} />,
      label: 'JSON (GA4GH standard)',
      onClick: getJsonData
    }
  ];

  return (
    <div style={{ minWidth: 200 }}>
      {isSubmitted ? (
        <CustomIconBtn
          type="view"
          onClick={onViewClick}
          tooltip="View / Edit"
          disabled={status === CONSTANTS.user_status.IMPORTED}
        />
      ) : (
        <CustomIconBtn icon={<Visibility fontSize="small" />} disabled={true} />
      )}
      {isMarkAsImported && isProband && (
        <CustomIconBtn
          type="imported"
          onClick={markAsImported}
          style={{ color: '#2ba9bf' }}
        />
      )}
      <CustomIconBtn type="delete" onClick={onDeleteClick} color={red[700]} />
      {isEditable && isProband ? (
        <DropButton
          options={options}
          tooltip="More options"
          icon={<MoreVert fontSize="small" />}
        />
      ) : (
        <CustomIconBtn icon={<MoreVert fontSize="small" />} disabled={true} />
      )}
    </div>
  );
};

export default UserTableActions;
