import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordInput, {
  PasswordStrengthAlert
} from 'src/components/UI/input/PasswordInput';
import Button from '@mui/material/Button';
import axios from 'src/axios';
import { APICONSTANTS } from '../Constants';
import { SnackContext } from 'src/store/ContextStore';
import { validatePasswordStrength } from 'src/util/util';

const defaultValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

export default function ChangePasswordForm(props) {
  const { handleMenuClose } = props;

  const { setSnack } = React.useContext(SnackContext);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [loading, setLoading] = React.useState(false);

  const onChange = (e) => {
    e.preventDefault();
    setFormValues((prevValue) => ({
      ...prevValue,
      [e.target.name]: e.target.value
    }));
  };

  const { currentPassword, newPassword, confirmPassword } = formValues;

  const confirmPassError =
    confirmPassword !== '' && confirmPassword !== newPassword;

  const isValid = () =>
    currentPassword?.trim() &&
    newPassword?.trim() &&
    confirmPassword?.trim() &&
    confirmPassword === newPassword &&
    validatePasswordStrength(newPassword);

  const onSaveClick = () => {
    if (isValid()) {
      setLoading(true);
      axios
        .post(APICONSTANTS.changePassword, { currentPassword, newPassword })
        .then(() => {
          setFormValues(defaultValues);
          setLoading(false);
          // handleClose();
          handleMenuClose && handleMenuClose();
          setSnack({
            status: true,
            msg: 'Password successfully updated!',
            severity: 'success'
          });
        })
        .catch(() => {
          setLoading(false);
          setSnack({
            status: true,
            msg: 'Error updating password!',
            severity: 'error'
          });
        });
    }
  };

  return (
    <form className="user-form">
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <PasswordInput
            ariaLabel="enter current password"
            onChange={onChange}
            name="currentPassword"
            value={currentPassword}
            label="Current Password"
            autoFocus
          />
        </Grid>

        <Grid item xs={10}>
          <PasswordInput
            ariaLabel="enter new password"
            onChange={onChange}
            name="newPassword"
            value={newPassword}
            label="New Password"
          />
        </Grid>

        <Grid item xs={10} container>
          <PasswordStrengthAlert password={newPassword} />
        </Grid>

        <Grid item xs={10}>
          <PasswordInput
            onChange={onChange}
            name="confirmPassword"
            value={confirmPassword}
            label="Confirm Password"
            ariaLabel="enter confirm password"
            error={confirmPassError}
            helperText={
              confirmPassError ? 'The password fields do not match' : undefined
            }
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item>
            <Button
              onClick={onSaveClick}
              variant="contained"
              color="secondary"
              disabled={!isValid() || loading}
              endIcon={loading ? <CircularProgress size={15} /> : undefined}
            >
              Save
            </Button>
          </Grid>
          {handleMenuClose && (
            <Grid item>
              <Button onClick={handleMenuClose} variant="outlined">
                Cancel
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
