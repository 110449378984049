import React from 'react';
import { Box, Modal } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';
import TextModal from './TextModal';

const Comment = ({
  node,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  setPedigreeState
}) => {
  const { theme } = React.useContext(ThemeContext);

  const style = {
    bgcolor: theme.appearance.color,
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    borderRadius: 2,
    p: 2
  };

  return (
    <>
      <Modal open={node} onClose={handleClose}>
        <Box sx={style}>
          <TextModal
            menu={true}
            node={node}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            handleClose={handleClose}
            setPedigreeState={setPedigreeState}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Comment;
