import React from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function LabelsMenu(props) {
  const { labelsSet, setLabelsSet } = props;

  const handleChange = (e) =>
    setLabelsSet({ ...labelsSet, [e.target.name]: e.target.checked });

  const t = useAutoTranslation();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <FormGroup column>
          <FormControlLabel
            control={
              <Checkbox
                checked={labelsSet.firstName}
                onChange={handleChange}
                size="small"
                name="firstName"
              />
            }
            label={t('First Name')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={labelsSet.lastName}
                onChange={handleChange}
                size="small"
                name="lastName"
              />
            }
            label={t('Last Name')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={labelsSet.age}
                onChange={handleChange}
                size="small"
                name="age"
              />
            }
            label={t('Age')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={labelsSet.upn}
                onChange={handleChange}
                size="small"
                name="upn"
              />
            }
            label="UPN"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
