import React, { useContext } from 'react';
import { PopupContext } from '../../CreateQuestionnaire';

// MUI
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';

// Popup Types
import AddGroup from './types/AddGroup/AddGroup';
import AddFormElement from './types/AddFormElement/AddFormElement';

// Constants
import { POPUP_TYPES } from '../../constants/popupTypes';
import { Box, Modal } from '@mui/material';

import LoadConfig from './types/LoadConfig';

// Popup Styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 500,
  bgcolor: 'background.paper',
  // border: "1px solid rgb(0, 213, 255)",
  borderRadius: '10px',
  boxShadow: 24,
  maxWidth: '90vw',
  p: 4
};

function Popup() {
  const [popupContext, setPopupContext] = useContext(PopupContext);

  return (
    <div>
      <Modal
        open={popupContext.show}
        onClose={() => setPopupContext({ ...popupContext, show: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {popupContext.type === POPUP_TYPES.ADD_GROUP && (
            <AddGroup
              edit={popupContext.edit}
              formType={popupContext.formType}
            />
          )}
          {popupContext.type === POPUP_TYPES.ADD_FORM_ELEMENT && (
            <AddFormElement />
          )}
          {popupContext.type === POPUP_TYPES.LOAD_CONFIG && (
            <LoadConfig
              data={popupContext.data}
              setPopupContext={setPopupContext}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default Popup;
