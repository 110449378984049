import axios from 'src/axios';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { APICONSTANTS } from 'src/components/Constants';

const isKeyMissing = (key, lang) => {
  const resources = i18n.getResource(lang, 'translation');
  // console.log(resources);
  return !resources[key];
};

const addKey = (key, translation, lang) => {
  i18n.addResourceBundle(lang, 'translation', {
    [key]: translation
  });

  i18n.changeLanguage(lang);
};

//cache
const translationCache = {};

// Requesting server for translation & storing in DB & caching the request for avoiding multiple request
const translateText = async (text, currentLanguage) => {
  try {
    if (translationCache[text]) {
      await translationCache[text];
    } else {
      const translationPromise = (async () => {
        const response = await axios.post(APICONSTANTS.updateTranslation, {
          text,
          lang: currentLanguage
        });

        const translated = response.data;

        addKey(String(text), translated, currentLanguage);

        delete translationCache[text];
      })();

      translationCache[text] = translationPromise;
      await translationPromise;
    }
  } catch (error) {
    console.error('Translation error occured');
  }
};

// Custom hook for transtion
export const useAutoTranslation = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const { t: tx } = useTranslation();

  const t = (key) => {
    if (currentLanguage === 'en') return tx(key);
    key = String(key).replace(/\./g, '_');
    if (isKeyMissing(key, currentLanguage) && !translationCache[key]) {
      translateText(key, currentLanguage);
    }

    return tx(key).replace(/_/g, '.');
  };

  return t;
};
