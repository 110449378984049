import React from 'react';
import Typography from '@mui/material/Typography';

import { getIcon } from './util';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const TourContent = ({ title, subTitle, icon }) => {
  const t = useAutoTranslation();

  return (
    <div>
      {title && <Typography variant="h6">{t(title)}</Typography>}
      <br />
      {subTitle && <Typography variant="h6">{t(subTitle)}</Typography>}
    </div>
  );
};

export default TourContent;
