import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';

// ContextAPI
import { FormData, PopupContext } from '../../CreateQuestionnaire';
import { getFormType } from '../../constants/formTypes.js';
import { USER_SIDE_TEMPLATE_TYPES } from '../../constants/templateTypes.js';

// Constants
import { POPUP_TYPES } from '../../constants/popupTypes';
import FormComponent from '../SectionComponent/SectionComponent';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Styles
import styles from './GroupElement.module.css';
import GraphStructureService from '../../services/graph.structurer.service.js';
import Alert from '@mui/material/Alert';
// import { WarningAmberOutlined } from '@mui/icons-material';
import { TEMPLATE } from '../../constants/templateTypes.js';
import { CodeOutlined, GetApp } from '@mui/icons-material';
import { copyToClipBoard } from 'src/util/util';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function GroupElement({ groupId }) {
  const gs = new GraphStructureService();
  const [popupContext, setPopupContext] = useContext(PopupContext);
  const [formDataContext, setFormDataContext] = useContext(FormData);
  const [data] = useState(formDataContext.schema[groupId]);

  // Handle Functions
  const handleAddGroup = (formType) => {
    setPopupContext({
      ...popupContext,
      show: true,
      type: POPUP_TYPES.ADD_GROUP,
      data,
      edit: false,
      formType: formType
    });
  };

  const handleEditSection = () => {
    setPopupContext({
      ...popupContext,
      show: true,
      type: POPUP_TYPES.ADD_GROUP,
      data,
      sectionData: data,
      edit: true
    });
  };

  const handleAddTemplate = (item) => {
    const updatedFormDataContext = { ...formDataContext };
    const templateName = USER_SIDE_TEMPLATE_TYPES[item.toolName];
    TEMPLATE[templateName].forEach((formElement) => {
      const updatedFormElement = { ...formElement, id: uuidv4() };
      updatedFormDataContext.schema[groupId].formElements.push(
        updatedFormElement
      );
    });
    setFormDataContext(updatedFormDataContext);
  };

  const handleAddFormElement = (item) => {
    if (USER_SIDE_TEMPLATE_TYPES[item.toolName]) {
      handleAddTemplate(item);
      return;
    }
    setPopupContext({
      show: true,
      type: POPUP_TYPES.ADD_FORM_ELEMENT,
      data: {
        id: groupId,
        type: getFormType(item.toolName)
      }
    });
  };

  const handleDeleteSection = () => {
    const updatedFormData = gs.removeSection(groupId, formDataContext);
    setFormDataContext({ ...updatedFormData });
  };

  const [, drop] = useDrop({
    accept: 'tool',
    drop: (item) => {
      handleAddFormElement(item);
    },
    collect: (monitor) => ({
      hover: monitor.isOver({ shallow: true })
    })
  });

  if (data.formType === undefined || data.formType === null)
    data.formType = true;

  const copyConfig = () => {
    copyToClipBoard(JSON.stringify(data.formElements));
  };

  const setConfig = (data) => {
    const formData = JSON.parse(data);
    const updatedFormDataContext = { ...formDataContext };
    formData.forEach((formElement) => {
      const updatedFormElement = { ...formElement, id: uuidv4() };
      updatedFormDataContext.schema[groupId].formElements.push(
        updatedFormElement
      );
    });
    setFormDataContext(updatedFormDataContext);
  };

  const loadConfig = () => {
    setPopupContext({
      show: true,
      type: POPUP_TYPES.LOAD_CONFIG,
      data: {
        id: groupId,
        setConfig
      }
    });
  };

  const t = useAutoTranslation();

  return (
    <div id={groupId} className={styles.GroupElement} ref={drop}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          width: '50vw'
        }}
      >
        <div style={{ marginRight: '20px' }}>
          <h2 style={{ margin: '0px' }}>{data.title}</h2>
          <p style={{ margin: '0px' }}>{data.description}</p>
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <EditIcon style={{ cursor: 'pointer' }} onClick={handleEditSection} />
          {formDataContext.schema[groupId].previousConnections.filter((n) => n)
            .length ? (
            <DeleteIcon
              onClick={handleDeleteSection}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <></>
          )}

          <GetApp onClick={copyConfig} className="pop" />
          <CodeOutlined onClick={loadConfig} className="pop" />
        </div>
      </div>
      {data.warning && (
        <Alert
          // icon={<WarningAmberOutlined fontSize="inherit" />}
          severity="error"
        >
          {data.warning}
        </Alert>
      )}
      <div style={{ marginTop: '10px' }}>
        {data.formElements.map((element, index) => {
          return (
            <div key={`FormElement_${index}`}>
              <FormComponent element={element} groupId={groupId} />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          margin: '10px',
          marginTop: '30px',
          textAlign: 'center'
        }}
      >
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          onClick={() => {
            handleAddGroup(false);
          }}
        >
          {t(`ADD SECTION`)}
        </Button>

        {data.formType && (
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            onClick={() => {
              handleAddGroup(true);
            }}
          >
            {t(`ADD FORM`)}
          </Button>
        )}
      </div>
    </div>
  );
}

export default GroupElement;
