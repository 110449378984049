import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { SnackContext } from 'src/store/ContextStore';
import axios from 'src/axios';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import TextInput from 'src/components/UI/input/TextInput';
import { useHistory } from 'react-router-dom';

function DeletionMenu({
  node,
  handleClose,
  setDataset,
  setDeepCopy,
  deepCopy
}) {
  const { setSnack } = React.useContext(SnackContext);
  const history = useHistory();
  const [confirmFamMemberId, setConfirmFamMemberId] = React.useState('');
  const [error, setError] = React.useState(false);

  const open = useMemo(() => !!node.data, [node]);

  const updateData = (dataset) => {
    // setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset));
    });
    setDataset(dataset);
  };

  const proceedToDelete = async () => {
    if (String(confirmFamMemberId).trim() !== node.data.upn) {
      setError(true);
      return;
    }

    await axios
      .delete(
        APICONSTANTS.deletePedigreeFamilyMember
          .replace(':user_id', node.data.user_id)
          .replace(':member_id', node.data.member_id)
      )
      .then((res) => {
        if (res.data.member) {
          const updated_member = res.data.member;

          const idx = deepCopy.findIndex(
            (person) => updated_member.pid === person.pid
          );
          deepCopy[idx] = updated_member;

          updateData(deepCopy);
        } else {
          history.push(CONSTANTS.pedigreeToolPath);
        }

        setSnack({
          status: true,
          msg: 'Deleted successfully!',
          severity: 'success'
        });
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: 'An Error Occured!',
          severity: 'error'
        })
      );
    handleClose();
  };

  const onChange = (e) => {
    setConfirmFamMemberId(e.target.value);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      showActions={true}
      saveBtnLabel={'Confirm Delete'}
      title={'Delete Confirmation'}
      titleAppearance={'warning'}
      minHeight={200}
      onSave={proceedToDelete}
      allowBackgroundClose={handleClose}
    >
      <Box>
        <Typography fontWeight={'bold'}>
          {`Warning: This action will permanently delete the following family member's details, including all associated records and data.`}
        </Typography>
        <Box py={2}>
          <Typography>UPN : {node.data.upn}</Typography>
          <Typography>
            Full Name: {node.data.firstName} {node.data.lastName}
          </Typography>
          <Typography>Family Member Id : {node.data.familyMemberId}</Typography>
          <Typography>Date of Birth: {node.data.dob}</Typography>
        </Box>
        <Typography variant="subtitle2">
          Please note that this action cannot be undone, and all historical
          information linked to this family member will be permanently removed
          from the system.
        </Typography>
        <Box className="user-form" pt={2}>
          <TextInput
            label="Enter Family Member UPN to confirm deletion"
            error={error}
            helperText={error && 'Invalid Family Member UPN'}
            placeholder={node.data.upn}
            onChange={onChange}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default DeletionMenu;
