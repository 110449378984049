// Libraries
import React from 'react';
import { useContext } from 'react';
import { FormData } from '../../CreateQuestionnaire';
import PreviewForm from './PreviewForm';
import styles from './Preview.module.css';
// import { Alert } from '@mui/lab';

export const organizeFormGroups = (formData) => {
  const sortedFormData = [];
  const groups = [];
  let currentGroup = [];
  Object.keys(formData.schema).map((schemaId) => {
    const form = formData.schema[schemaId];
    if (form.formType === undefined || form.formType === null)
      form.formType = true;
    if (form.formType) {
      sortedFormData.push({ type: 'form', data: form, connected: false });
    } else {
      const connectedData = formData.schema[form.previousConnections[0]];
      const connectedIndex = sortedFormData.findIndex(
        (f) => f.data.id === connectedData.id
      );

      if (connectedIndex >= 0) {
        sortedFormData.splice(connectedIndex + 1, 0, {
          type: 'connectedForm',
          data: form,
          connected: true,
          connectedData
        });
      } else {
        sortedFormData.push({
          type: 'connectedForm',
          data: form.data,
          connected: true,
          connectedData
        });
      }
    }
  });

  for (let i = 0; i < sortedFormData.length; i++) {
    const form = sortedFormData[i];

    if (form.type === 'form') {
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }

      currentGroup = [form];
    } else {
      currentGroup.push(form);
    }
  }

  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  return groups;
};

function Preview({ data }) {
  const FORM_DATA = useContext(FormData);
  let formData;
  if (Object.keys(FORM_DATA).length > 0) {
    formData = FORM_DATA[0];
  } else {
    formData = data.config;
  }

  const groups = organizeFormGroups(formData);

  return (
    <div>
      {groups.map((group, index) => {
        return (
          <div
            key={index}
            className={styles.previewContainer}
            style={{ marginBlock: 20 }}
          >
            {group.map((form, index) => {
              if (form?.type === 'form') {
                return (
                  <div key={index}>
                    <PreviewForm data={form.data} />
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <PreviewForm data={form.data} connected={form.connected} />
                  </div>
                );
              }
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Preview;
