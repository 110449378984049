import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import { AlertTitle, Box, Fade, Icon } from '@mui/material';
import {
  CancelOutlined,
  CheckCircleOutlineRounded,
  ErrorOutlineRounded,
  InfoOutlined,
  WarningAmberRounded
} from '@mui/icons-material';

function Alert(props) {
  return <MuiAlert elevation={6} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const SnackBar = (props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(props.open);

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    //setOpen(false);
    props.setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={
          props.custom
            ? { vertical: '', horizontal: '' }
            : { vertical: 'top', horizontal: 'center' }
        }
        TransitionComponent={Fade}
        TransitionProps={{ enter: true }}
      >
        <div>
          {props.custom ? (
            <CustomAlert
              onClose={handleClose}
              severity={props.severity}
              message={props.message}
            />
          ) : (
            <Alert onClose={handleClose} severity={props.severity}>
              {props.message}
            </Alert>
          )}
        </div>
      </Snackbar>
    </div>
  );
};

export default SnackBar;

const CustomAlert = ({ severity, message, handleClose }) => {
  const getIcon = () => {
    switch (severity) {
      case 'success':
        return <CheckCircleOutlineRounded fontSize="inherit" />;
      case 'error':
        return <CancelOutlined fontSize="inherit" />;
      case 'warning':
        return <WarningAmberRounded fontSize="inherit" />;
      case 'info':
        return <InfoOutlined fontSize="inherit" />;
      default:
        return null;
    }
  };

  return (
    <Alert
      onClose={handleClose}
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 210,
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 2
      }}
      severity={severity}
      icon={false}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ fontSize: 58 }}>{getIcon()}</Box>
        <AlertTitle>{severity.toUpperCase()}</AlertTitle>
        {message}
      </Box>
    </Alert>
  );
};
