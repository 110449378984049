import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { FORM_TYPES } from 'src/components/form-builder/CreateQuestionnaire/constants/formTypes';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { AddCircleRounded, BackspaceRounded } from '@mui/icons-material';
import { FieldArray } from 'formik';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const useStyles = makeStyles(() => ({
  linketScale: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '15px'
  },
  label: {
    fontSize: '1rem',
    color: 'black',
    fontWeight: 'bold',
    paddingBlock: '10px'
  }
}));

const FormFields = ({ element, formik, readOnly }) => {
  const classes = useStyles();

  const fontColor = {
    style: { color: 'rgb(50, 50, 50)' }
  };

  //encoding label and replacing period  to resolve conflicting with formik
  let key = encodeURIComponent(element.label.replaceAll('.', '_'));

  const t = useAutoTranslation();

  switch (element.type) {
    case FORM_TYPES.SHORT_TEXT:
      return (
        <div style={{ marginBlock: '10px' }}>
          <FormLabel>
            <div className={classes.label}>
              {t(element.label)}
              {element.isRequired && (
                <span style={{ color: 'red', padding: 5 }}>*</span>
              )}
            </div>
          </FormLabel>
          <FieldArray
            name={key}
            render={(arrayHelpers) => (
              <div>
                <div>
                  {formik.values[key] &&
                    formik.values[key].map((val, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          marginBottom: 10,
                          alignItems: 'center'
                        }}
                      >
                        <TextField
                          disabled={readOnly}
                          name={`${[key] + `[${index}]`}`}
                          value={formik.values[key][index]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[key] && Boolean(formik.errors[key])
                          }
                          inputProps={fontColor}
                          variant="outlined"
                          fullWidth
                        />
                        {!readOnly && !!index && (
                          <BackspaceRounded
                            style={{ fontSize: 'medium', color: '#ff4d4d' }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove
                          </BackspaceRounded>
                        )}
                      </div>
                    ))}
                  <FormHelperText>
                    {formik.touched[key] &&
                      Boolean(formik.errors[key]) &&
                      'Above fields are required'}
                  </FormHelperText>
                  {element.isIdentifier && !readOnly && (
                    <div
                      onClick={() => {
                        if (
                          formik.values[key][formik.values[key].length - 1] !==
                          ''
                        )
                          arrayHelpers.push('');
                      }}
                      style={{
                        display: 'flex',
                        paddingBlock: 10,
                        alignItems: 'center'
                      }}
                    >
                      <AddCircleRounded
                        style={{ fontSize: 'large', color: 'green' }}
                      />{' '}
                      <TextField
                        disabled
                        variant="outlined"
                        placeholder="add more"
                        fullWidth
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      );
    case FORM_TYPES.EMAIL_INPUT:
      return (
        <div style={{ marginBlock: '10px' }}>
          <FormLabel>
            <div className={classes.label}>
              {t(element.label)}
              {element.isRequired && (
                <span style={{ color: 'red', padding: 5 }}>*</span>
              )}
            </div>
          </FormLabel>
          <TextField
            disabled={readOnly}
            variant="outlined"
            value={formik.values[key] ? formik.values[key] : ''}
            onChange={formik.handleChange}
            name={key}
            type={'email'}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            helperText={formik.touched[key] && formik.errors[key]}
            inputProps={fontColor}
            fullWidth
          />
        </div>
      );
    case FORM_TYPES.FILE_UPLOAD:
      return (
        <div style={{ marginBlock: '10px' }}>
          <FormLabel>
            <div className={classes.label}>
              {t(element.label)}
              {element.isRequired && (
                <span style={{ color: 'red', padding: 5 }}>*</span>
              )}
            </div>
          </FormLabel>
          <div>
            {formik.values[key] && formik.values[key].type && readOnly ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formik.values[key].key}
              >
                <Button
                  onClick
                  variant="contained"
                  color="secondary"
                  component="label"
                >
                  View
                </Button>
              </a>
            ) : (
              <div
                style={{
                  alignContent: 'center',
                  display: 'flex'
                  // justifyContent: 'center'
                }}
              >
                {readOnly ? (
                  <span
                    style={{
                      padding: '.6rem',
                      borderRadius: 5,
                      backgroundColor: 'lavender'
                    }}
                  >
                    Not uploaded
                  </span>
                ) : (
                  <>
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                      >
                        Upload File
                        <input
                          onChange={(e) => {
                            formik.setFieldValue(key, e.target.files[0]);
                          }}
                          accept="image/*,.pdf,.txt "
                          hidden
                          type="file"
                        />
                      </Button>
                      {formik.values[key] && formik.values[key].name && (
                        <span
                          style={{
                            padding: '.6rem',
                            borderRadius: 5,
                            backgroundColor: 'lavender',
                            marginInline: 10
                          }}
                        >
                          {formik.values[key].name}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <FormHelperText>
            {formik.touched[key] && Boolean(formik.errors[key]) && (
              <span style={{ color: 'red' }}>Above field is required</span>
            )}
          </FormHelperText>
        </div>
      );
    case FORM_TYPES.DATE_TIME_INPUT:
      return (
        <div style={{ marginBlock: '10px' }}>
          <FormLabel>
            <div className={classes.label}>
              {t(element.label)}
              {element.isRequired && (
                <span style={{ color: 'red', padding: 5 }}>*</span>
              )}
            </div>
          </FormLabel>
          <TextField
            disabled={readOnly}
            variant="outlined"
            value={formik.values[key] ? formik.values[key] : ''}
            onChange={formik.handleChange}
            name={key}
            type={'date'}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            helperText={formik.touched[key] && formik.errors[key]}
            inputProps={fontColor}
            fullWidth
          />
        </div>
      );

    case FORM_TYPES.LONG_TEXT:
      return (
        <div style={{ marginBlock: '10px', paddingBlock: '10px' }}>
          <FormLabel>
            <div className={classes.label}>
              {t(element.label)}
              {element.isRequired && (
                <span style={{ color: 'red', padding: 5 }}>*</span>
              )}
            </div>
          </FormLabel>
          <TextField
            disabled={readOnly}
            multiline
            value={formik.values[key] ? formik.values[key] : ''}
            onChange={formik.handleChange}
            id={key}
            onBlur={formik.handleBlur}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            helperText={formik.touched[key] && formik.errors[key]}
            minRows={6}
            variant="outlined"
            fullWidth
            inputProps={fontColor}
          />
        </div>
      );
    case FORM_TYPES.MULTIPLE_CHOICE:
      return (
        <div
          style={{
            paddingBlock: '10px',
            marginBlock: '10px'
          }}
        >
          <FormControl
            error={formik.touched[key] && Boolean(formik.errors[key])}
            onBlur={formik.handleBlur}
            disabled={readOnly}
          >
            <FormLabel>
              <div className={classes.label}>
                {t(element.label)}
                {element.isRequired && (
                  <span style={{ color: 'red', padding: 5 }}>*</span>
                )}
              </div>
            </FormLabel>

            <RadioGroup
              name={key}
              value={formik.values[key] ? formik.values[key] : ''}
              onChange={formik.handleChange}
            >
              {element.options.map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={option}
                    inputprops={fontColor}
                  />
                );
              })}
            </RadioGroup>

            <FormHelperText>
              {formik.touched[key] && formik.errors[key]}
            </FormHelperText>
          </FormControl>
        </div>
      );
    case FORM_TYPES.DROPDOWN:
      return (
        <div style={{ paddingBlock: '10px', marginBlock: '10px' }}>
          <FormControl
            // style={{ width: '100%' }}
            error={formik.touched[key] && Boolean(formik.errors[key])}
            name={key}
            disabled={readOnly}
          >
            <FormLabel>
              <div className={classes.label}>
                {t(element.label)}
                {element.isRequired && (
                  <span style={{ color: 'red', padding: 5 }}>*</span>
                )}
              </div>
            </FormLabel>
            <Select
              onBlur={formik.handleBlur}
              value={formik.values[key] ? formik.values[key] : ''}
              name={key}
              autoWidth={true}
              style={{ paddingRight: 15 }}
              variant="outlined"
              onChange={formik.handleChange}
              // label={key}
              inputProps={fontColor}
            >
              {element.options.map((option) => {
                return (
                  <MenuItem name={key} key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {formik.touched[key] && formik.errors[key]}
            </FormHelperText>
          </FormControl>
        </div>
      );
    case FORM_TYPES.CHECKBOX_INPUT:
      return (
        <div
          style={{
            paddingBlock: '10px',
            marginBlock: '15px'
          }}
        >
          <FormControl
            error={formik.touched[key] && Boolean(formik.errors[key])}
            onBlur={formik.handleBlur}
            disabled={readOnly}
            style={{ width: '100%' }}
          >
            <FormLabel>
              <div className={classes.label}>
                {t(element.label)}
                {element.isRequired && (
                  <span style={{ color: 'red', padding: 5 }}>*</span>
                )}
              </div>
            </FormLabel>
            <FieldArray
              name={key}
              render={(arrayHelpers) => (
                <div>
                  <div>
                    {formik.values[key] &&
                      formik.values[key].map((val, index) => {
                        return (
                          <div key={index}>
                            <div
                              style={{
                                display: 'flex',
                                gap: '1rem',
                                marginBottom: 10,
                                alignItems: 'center'
                              }}
                            >
                              <Checkbox
                                disabled={readOnly}
                                name={`${[key] + `[${index}].checked`}`}
                                onChange={formik.handleChange}
                                checked={formik.values[key][index].checked}
                              />
                              <span
                                disabled={readOnly}
                                name={`${[key] + `[${index}].label`}`}
                              >
                                {formik.values[key][index].label}
                              </span>
                            </div>
                            {element.isIdentifier &&
                              formik.values[key][index].label === 'Other' &&
                              formik.values[key][index].checked && (
                                <TextField
                                  disabled={readOnly}
                                  name={`${[key] + `[${index}].value`}`}
                                  value={formik.values[key][index].value}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  placeholder="Specify"
                                  fullWidth
                                />
                              )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            />
            <FormHelperText>
              {formik.touched[key] && formik.errors[key]}
            </FormHelperText>
          </FormControl>
        </div>
      );
    case FORM_TYPES.LINEAR_SCALE:
      return (
        <div
          style={{
            paddingBlock: '10px',
            marginBlock: '15px'
          }}
        >
          <FormControl disabled={readOnly}>
            <FormLabel
              error={formik.touched[key] && Boolean(formik.errors[key])}
              onBlur={formik.handleBlur}
            >
              <div className={classes.label}>
                {t(element.label)}
                {element.isRequired && (
                  <span style={{ color: 'red', padding: 5 }}>*</span>
                )}
              </div>
            </FormLabel>
            <RadioGroup
              row
              name={key}
              value={formik.values[key] ? formik.values[key] : ''}
              onChange={formik.handleChange}
            >
              {element.options.map((option, index) => {
                return (
                  <div key={index} className={classes.linketScale}>
                    <span>{option}</span>
                    <FormControlLabel
                      style={{ marginLeft: '10px' }}
                      value={option}
                      control={<Radio />}
                    />
                  </div>
                );
              })}
            </RadioGroup>
            <FormHelperText style={{ color: 'red' }}>
              {formik.touched[key] && formik.errors[key]}
            </FormHelperText>
          </FormControl>
        </div>
      );
    case FORM_TYPES.EMBED_CONTENT:
      return (
        <div
          style={{
            paddingBlock: '10px',
            marginBlock: '15px'
          }}
        >
          <div>
            <div dangerouslySetInnerHTML={{ __html: t(element.label) }} />
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default FormFields;
