import React from 'react';
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../Constants';
import Flag from 'react-world-flags';
import { ArrowDropDownCircle } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  customSelect: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    gap: 6,
    right: 5
  },
  select: {
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  }
}));

function TranslationMenu({ color }) {
  const classes = useStyles();

  const options = CONSTANTS.languages;

  const selectRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const onSelect = () => {
    setOpen(true);
    selectRef.current.focus();
  };

  const { i18n } = useTranslation();

  const handleChange = (e) => {
    localStorage.setItem('lang', e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <Grid item>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          position: 'relative'
        }}
      >
        {color && (
          <div onClick={onSelect} className={classes.customSelect}>
            <Flag
              code={CONSTANTS.flags[i18n.language]}
              height={20}
              width={20}
              style={{ borderRadius: 5 }}
            />
            <span style={{ fontSize: 13, fontWeight: 'bolder' }}>
              {i18n.language.toUpperCase()}
            </span>
            <ArrowDropDownCircle style={{ fontSize: 13 }} />
          </div>
        )}

        <FormControl fullWidth style={color && { opacity: 0 }}>
          <Select
            ref={selectRef}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            fullWidth
            className={classes.select}
            style={{
              color: color,
              paddingRight: color ? 0 : 20
            }}
            variant={'outlined'}
            value={i18n.language}
            onChange={handleChange}
          >
            {options.map((option) => {
              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  className={classes.menuItem}
                >
                  <Flag
                    code={CONSTANTS.flags[option.value]}
                    height={35}
                    width={35}
                    style={{ borderRadius: 10 }}
                  />
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </Grid>
  );
}

export default TranslationMenu;
