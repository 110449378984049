import axios from 'axios';
import { APICONSTANTS } from 'src/components/Constants';
import moment from 'moment';

const getReferralTags = async (theme, memberId, familyId, response) => {
  if (!response) response = await axios.get(`/api/${APICONSTANTS.referral}`);

  let referralOptions = response.data;
  referralOptions = referralOptions.filter((referral) => {
    return (
      (referral.familyMemberId == memberId || referral.showInFamily) &&
      referral.familyId == familyId
    );
  });
  let referralTags = [];
  for (const referral of referralOptions) {
    referralTags.push(
      referral.referralName +
        ' ' +
        moment(referral.createdDate).format(theme.dateFormat)
    );
  }
  return referralTags;
};

const getConsentTags = async (theme, memberId, familyId, response) => {
  if (!response) response = await axios.get(`/api/${APICONSTANTS.consent}`);
  let options = response.data;
  options = options.filter((option) => {
    return option.familyMemberId == memberId && option.familyId == familyId;
  });
  let Tags = [];
  for (const option of options) {
    Tags.push(
      option.consentType +
        ' ' +
        moment(option.createdDate).format(theme.dateFormat)
    );
  }
  return Tags;
};

//Keeping these here just in case I need them, for now they need fixing which I have no reason to do
//const getAppointmentTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.appointment}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.clinicName + ' ' + option.appointmmentDate.split('T')[0]);
//  }
//  return Tags;
//};

//const getContactTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.contact}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.contactType + ' ' + option.contactDate.split('T')[0]);
//  }
//  return Tags;
//};
//
//
//const getSampleTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.sample}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.sampleType + ' ' + option.dateTaken.split('T')[0]);
//  }
//  return Tags;
//};
//
//const getTestTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.test}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.testName + ' ' + option.resultDueDate.split('T')[0]);
//  }
//  return Tags;
//};
//
//const getAddressTags = async (response) => {
//  if (!response) response = await axios.get(`/api/${APICONSTANTS.address}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.postCode + ' ' + option.town);
//  }
//  return Tags;
//};
//
//const getClinicalNoteTags = async (response) => {
//  if (!response)
//    response = await axios.get(`/api/${APICONSTANTS.clinicalNote}`);
//  const options = response.data;
//  let Tags = [];
//  for (const option of options) {
//    //Consent has no name so I couldn't use that
//    Tags.push(option.clinician + ' ' + option.date.split('T')[0]);
//  }
//  return Tags;
//};

const handleUpload = async (e) => {
  if (e.target.files.length !== 0) {
    const formData = new FormData();
    formData.append('file_attachment', e.target.files[0]);
    axios.post(`/api/${APICONSTANTS.fileAttachment}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export {
  getConsentTags,
  getReferralTags,
  //getAddressTags,
  //getClinicalNoteTags,
  //getTestTags,
  //getSampleTags,
  //getContactTags,
  //getAppointmentTags,
  handleUpload
};
