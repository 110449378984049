export const getCount = () => parseInt(sessionStorage.getItem('count'));
const setCount = (count) => sessionStorage.setItem('count', count);

export const getPos = () => parseInt(sessionStorage.getItem('pos'));
export const setPos = (count) => sessionStorage.setItem('pos', count);

export const prev_pos = () => {
  let pos = getPos() - 1;
  if (pos < 0) pos = 0;
  setPos(pos);
  return pos;
};

export const next_pos = () => {
  const count = isNaN(getCount()) ? 0 : getCount();
  let pos = getPos() + 1;
  if (pos >= count) pos = count;
  setPos(pos);
  return pos;
};

export const init_Cache = (data, famId) => {
  const count = isNaN(getCount()) ? 0 : getCount() + 1;
  setPos(count);
  setCount(count);
  sessionStorage.setItem(`${famId + '-' + count}`, data);
};

export const set_Cache = (data, famId) => {
  let count = isNaN(getCount()) ? 0 : getCount() + 1;
  const pos = getPos();
  if (pos < count) count = pos + 1;
  setCount(count);
  setPos(count);
  sessionStorage.setItem(`${famId + '-' + count}`, data);
};
