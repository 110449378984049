import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CustomDialog from '../dialog/CustomDialog';

function ModalContent() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography gutterBottom>
          You are about to submit this questionnaire.
        </Typography>
        <Typography gutterBottom>
          Upon submission you will no longer have access to view or edit this
          questionnaire.
        </Typography>
        <Typography>
          If you wish to continue with the submission, please click{' '}
          <strong>{'Submit'}</strong>.
        </Typography>
        <Typography>
          If you want to go back and make further changes please click{' '}
          <em>{'Cancel'}</em>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function ConfirmationModal({
  openModal,
  setOpenModal,
  onSaveClick
}) {
  return (
    <CustomDialog
      title={'Confirm submission'}
      open={openModal}
      handleClose={() => setOpenModal(false)}
      minHeight="auto"
      showActions={true}
      saveBtnLabel={'Submit'}
      onSave={onSaveClick}
    >
      <ModalContent />
    </CustomDialog>
  );
}
