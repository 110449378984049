import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { diagnosesMap } from 'src/components/questionnaire/Constants';
import { diagnsesShapeMap } from '../pedigreeUtil';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import DiagnosisModal from '../DiagnosisModal';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 15
  },
  boxHolder: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '1rem',
    width: '100%'
  },
  item: {
    display: 'flex',
    marginRight: '8px'
  }
});

export default function LegendMenu({
  diagnoses,
  id,
  deepCopy,
  setDeepCopy,
  setDataset,
  setPedigreeState
}) {
  const classes = useStyles();

  const t = useAutoTranslation();
  return (
    <>
      <Grid item>
        <Grid container className={classes.container} id={id}>
          {diagnoses.map((el, i) => {
            return (
              <div key={i} className={classes.boxHolder}>
                <span
                  className={classes.item}
                  style={{
                    backgroundColor: el.color ?? 'black',
                    width: 15,
                    height: 15
                  }}
                ></span>
                <span>{t(el.name)}</span>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
