import React, { createContext, useContext, useMemo, useReducer } from 'react';

import {
  INITIAL_INTEGRATIONS_STATE,
  integrationsReducer
} from './integrationsReducer';

const IntegrationsContext = createContext(null);

const IntegrationsContextProvider = ({ children }) => {
  // stores the access token and patient details
  const [content, dispatchUpdate] = useReducer(
    integrationsReducer,
    INITIAL_INTEGRATIONS_STATE
  );
  const value = useMemo(
    () => ({ content, dispatchUpdate }),
    [content, dispatchUpdate]
  );

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsContextProvider;

export const useIntegrationsContext = () => useContext(IntegrationsContext);
