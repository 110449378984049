import React from 'react';
import { createContext, useState } from 'react';

import InitialPage from './InitialPage';
import Popup from './components/Popup/Popup';

export const FormData = createContext({});
export const VisitedNodeContext = createContext({});
export const PopupContext = createContext({ show: false });
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const { width, height } = getWindowDimensions();

// for editing the form => a edit context is created

export const EditMode = createContext({});

function CreateQuestionnaire({ editSchema, schemaId }) {
  const formData = useState(editSchema ? editSchema : {});
  const visitedContext = useState({});
  const popupContext = useState(
    editSchema
      ? {
          show: false,
          type: 'ADD_GROUP',
          data: editSchema,
          sectionData: editSchema,
          edit: false
        }
      : { show: false }
  );

  // Default edit mode is false
  const edit = editSchema
    ? { status: true, schemaId: schemaId }
    : { status: false };

  return (
    <>
      {width <= 720 ? (
        <DndProvider backend={TouchBackend}>
          <EditMode.Provider value={edit}>
            <FormData.Provider value={formData}>
              <VisitedNodeContext.Provider value={visitedContext}>
                <PopupContext.Provider value={popupContext}>
                  <InitialPage />
                  <Popup />
                </PopupContext.Provider>
              </VisitedNodeContext.Provider>
            </FormData.Provider>
          </EditMode.Provider>
        </DndProvider>
      ) : (
        <DndProvider backend={HTML5Backend}>
          <EditMode.Provider value={edit}>
            <FormData.Provider value={formData}>
              <VisitedNodeContext.Provider value={visitedContext}>
                <PopupContext.Provider value={popupContext}>
                  <InitialPage />
                  <Popup />
                </PopupContext.Provider>
              </VisitedNodeContext.Provider>
            </FormData.Provider>
          </EditMode.Provider>
        </DndProvider>
      )}
    </>
  );
}

export default CreateQuestionnaire;
