import axios from 'axios';

const instance = axios.create({
  baseURL: '/api'
});

// TODO: for 401 show login page
instance.interceptors.response.use(
  (response) => {
    if (response.headers['reload-required']) window.location.reload();
    return response;
  },
  (err) => {
    if (err.headers && err.headers['reload-required']) window.location.reload();
    return Promise.reject(err);
  }
);

export default instance;
