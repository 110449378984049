import React, { useMemo } from 'react';
import { APICONSTANTS, HPO_CONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import axios from 'src/axios';
import { Box, CircularProgress, Grid, Chip } from '@mui/material';
import { AddCircleRounded, AutoAwesome, MoreHoriz } from '@mui/icons-material';
import InfoTooltip from 'src/components/UI/addons/InfoTooltip';
import { AppContext } from 'src/store/ContextStore';
import { EVENTS } from 'src/components/admin/appReducer';

function Suggestions({ data, setDataset }) {
  const { setSnack } = React.useContext(SnackContext);
  const { content, dispatchUpdate } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  let user_id = useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.familyMemberId;
  }, [data]);

  // Id for proband
  let proBandId = useMemo(() => {
    return data?.data?.proBandId;
  }, [data]);

  const [fetchData, setFetchData] = React.useState({
    hpoTerms: [],
    Genes: [],
    Diagnoses: []
  });

  const addSuggestion = (type, value) => {
    if (!type || !value) return;

    const currentValues = fetchData[type] || [];

    // Check if the object with the same id already exists
    const exists = currentValues.some((item) => item.id === value.id);

    // If it doesn't exist, add the new value
    if (!exists) {
      let new_values = [...currentValues, value];

      setFetchData((prev) => ({
        ...prev,
        [type.toString()]: new_values
      }));

      let api = {
        Genes: { key: 'genotypes', value: 'genotypes' },
        Diagnoses: { key: 'diagnoses', value: 'diagnoses' }
      };

      const id = proBandId ?? familyMemberId;
      console.log(type, api);

      const payload = {
        familyMemberId: id,
        user_id: user_id,
        isProband: proBandId ? true : false,
        ...(type === 'Diagnoses'
          ? { [api[type].value]: [value] }
          : { [api[type].value]: new_values })
      };

      axios
        .post(APICONSTANTS[api[type].key], payload)
        .then((res) => {
          if (res.data?.success) {
            if (type === 'Diagnoses') {
              dispatchUpdate({
                type: EVENTS.SET_PEDIGREE_DIAGNOSES,
                value: res.data.diagnoses
              });
              setDataset((prev) => {
                return prev.map((elem) => {
                  if (elem.pid === data.data.pid) {
                    return {
                      ...elem,
                      diagnoses: res.data.allDiagnoses
                    };
                  }
                  return elem;
                });
              });
            }
            setSnack({
              status: true,
              msg: `Added to ${[api[type].key]}`.toLocaleUpperCase(),
              severity: 'success'
            });
          }
        })
        .catch((err) =>
          setSnack({
            status: true,
            msg: 'An error has occurred!',
            severity: 'error'
          })
        );
    } else {
      setSnack({
        status: true,
        msg: 'Already added!',
        severity: 'error'
      });
    }
  };

  const getHPOTerm = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    Promise.all([
      axios.get(
        APICONSTANTS.getGenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      ),
      axios.get(
        APICONSTANTS.getDiagnosesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      ),
      axios.get(
        APICONSTANTS.getPhenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      )
    ])
      .then(([geno_res, diagno_res, pheno_res]) => {
        setFetchData({
          Genes: geno_res?.data.genotypes?.map((gene) => ({
            id: gene.id,
            name: gene.name
          })),
          Diagoses: diagno_res.data.diagnoses.map((dig) => ({
            id: dig.id,
            name: dig.name
          })),
          hpoTerms: pheno_res?.data.phenotypes.map((hp) => hp.id)
        });
      })
      .catch((err) => {
        setSnack({
          status: true,
          msg: 'Error fetching Values',
          severity: 'error'
        });
      });
  };

  React.useEffect(() => {
    getHPOTerm();
  }, []);

  return (
    <Grid container spacing={1}>
      {fetchData.hpoTerms.length === 0 && (
        <Grid
          xs={12}
          sx={{
            gap: 1,
            alignItems: 'center',
            display: 'flex',
            fontSize: '.8rem',
            fontWeight: 'bold',
            bgcolor: '#fff4e6',
            p: 1,
            borderRadius: 1
          }}
        >
          <AutoAwesome fontSize="small" />
          <span>
            Add phenotypes and other clinical data to generate suggestions
          </span>
        </Grid>
      )}
      {fetchData.hpoTerms.map((id) => {
        return (
          <SuggestionsBox
            key={id}
            hpoTermId={id}
            addSuggestion={addSuggestion}
          />
        );
      })}
    </Grid>
  );
}

export default Suggestions;

const SuggestionsBox = ({ hpoTermId, addSuggestion }) => {
  const [max, setMax] = React.useState({ genes: 5, diagnoses: 5 });
  const [data, setData] = React.useState({
    genes: [],
    diagnoses: []
  });

  const hpo_api = `${HPO_CONSTANTS['hpoBaseURL']}/annotation/${hpoTermId}`;

  const fetchMore = (type) => {
    setMax((prev) => ({ ...prev, [type]: prev[type] + 5 }));
  };

  React.useMemo(() => {
    axios.get(hpo_api).then((hpo_res) => {
      setData({
        genes: hpo_res.data.genes,
        diagnoses: hpo_res.data.diseases
      });
    });
  }, []);

  let hpo_associations = [
    { type: 'Genes', name: 'genes', id: 'id', value: 'name' },
    {
      type: 'Diagnoses',
      name: 'diagnoses',
      id: 'id',
      value: 'id',
      info: 'name'
    }
  ];

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          bgcolor: '#e5f6fd',
          p: 1,
          borderRadius: 1,
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            gap: 1,
            alignItems: 'center',
            display: 'flex',
            fontSize: '.8rem',
            fontWeight: 'bold'
          }}
        >
          <AutoAwesome fontSize="small" />
          <span>Associated Genes & Diagnoses : {hpoTermId}</span>
        </Box>
        {hpo_associations.map((term) => {
          return (
            <Box
              key={term.type}
              sx={{ display: 'flex', flexDirection: 'column', gap: 0.7 }}
            >
              <Box sx={{ px: 1, fontSize: '.8rem', fontWeight: 'bold' }}>
                {term.type}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.7,
                  alignItems: 'center'
                }}
              >
                {data[term.name].slice(0, max[term.name]).map((res) => {
                  return (
                    <DataChip
                      term={term}
                      key={res[term.id]}
                      res={res}
                      addSuggestion={addSuggestion}
                    />
                  );
                })}
                {data[term.name]?.length > 0 &&
                  data[term.name]?.length >= max[term.name] && (
                    <Chip
                      label={<MoreHoriz fontSize="small" color="action" />}
                      size="small"
                      onClick={() => fetchMore(term.name)}
                    />
                  )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};

const DataChip = ({ term, res, addSuggestion }) => {
  const [loading, setLoading] = React.useState(false);

  const onClick = async (type, data) => {
    setLoading(true);
    await addSuggestion(type, data);
    setLoading(false);
  };
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '.8rem',
        fontWeight: 'bold',
        gap: 0.8,
        bgcolor: 'white',
        cursor: 'pointer'
      }}
      onClick={() =>
        onClick(term.type, {
          id: res['id'],
          name: res['name']
        })
      }
    >
      {term.info && <InfoTooltip label={res[term.info]} fontSize="0.5rem" />}
      <span>{res[term.value]}</span>
      {loading ? (
        <CircularProgress size={15} />
      ) : (
        <AddCircleRounded color="info" fontSize="0.5rem" />
      )}
    </Box>
  );
};
