import React, { useState } from 'react';
import SelectSpouseCard from './SelectSpouseCard';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

export default function SelectSpouseModal(props) {
  const { spousesWithChildren, handleSelect } = props;
  const [selectedSpouse, setSelectedSpouse] = useState('');

  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedSpouse(e.target.value);
    } else {
      setSelectedSpouse(null);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={1}>
        {spousesWithChildren.map((person) => (
          <Grid item xs={12} key={person.value}>
            <SelectSpouseCard
              selectedSpouse={selectedSpouse}
              handleChange={handleChange}
              {...person}
            />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item>
          <Button
            onClick={() => handleSelect(selectedSpouse)}
            variant="contained"
            color="secondary"
            disabled={!selectedSpouse}
          >
            Select
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
