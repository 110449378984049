import React from 'react';
import Table from 'src/components/UI/table/Table';
import axios from 'src/axios';
import { capitalizeLetter } from 'src/util/util';
import { APICONSTANTS, CONSTANTS } from '../Constants';
import { LoadingContext } from 'src/store/ContextStore';
import AddFamily from './AddFamily';
import { useHistory } from 'react-router-dom';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { Chip } from '@mui/material';

export default function SelectFamily(props) {
  const { setLoading } = React.useContext(LoadingContext);
  const [selectedId, setSelectedId] = React.useState(-1);

  const filterStatus = [
    CONSTANTS.user_status.CREATED,
    CONSTANTS.user_status.IN_PROGRESS,
    CONSTANTS.user_status.PENDING
  ];

  React.useEffect(() => {
    if (props.rows && props.index === 0) {
      if (props.rows.length === 0) setLoading(true);

      axios
        .get(APICONSTANTS.getProbandUsers)
        .then((res) => {
          setLoading(false);
          const rows = res.data.filter(
            (el) => !filterStatus.includes(el.status)
          );
          props.setRows(rows);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [props.index]);

  const history = useHistory();

  const t = useAutoTranslation();

  const columns = [
    {
      title: '#',
      render: (rowData) => rowData.tableData.id + 1,
      width: '10%',
      sorting: false
    },
    { title: t('Family ID'), field: 'familyId', width: 100 },
    { title: 'TrakGene ID', field: 'trakGeneId', width: 100 },
    {
      title: t('First Name'),
      field: 'firstName',
      width: 200,
      render: (rowData) => capitalizeLetter(rowData.firstName)
    },
    {
      title: t('Last Name'),
      field: 'lastName',
      width: 200,
      render: (rowData) => capitalizeLetter(rowData.lastName)
    },
    {
      title: t('Number of People'),
      field: 'none',
      render: (rowData) => rowData.familyMembers.length + 1,
      width: 50
    },
    {
      title: t('Tags'),
      field: 'tags.tag',
      width: 300,
      render: (rowData) => {
        if (rowData.tags) {
          const chips = rowData.tags.map(({ tag, tagColor }) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              style={{
                backgroundColor: tagColor,
                fontWeight: 500
              }}
            />
          ));
          return <div style={{ display: 'flex', gap: 2 }}>{chips}</div>;
        }
      },
      customFilterAndSearch: (term, rowData) => {
        if (rowData.tags) {
          const tagsText = rowData.tags
            .map(({ tag }) => tag && tag.toLowerCase())
            .join(' ');
          return tagsText.includes(term.toLowerCase());
        }
        return false;
      }
    }
  ];

  const onRowClick = (rowData) => {
    setLoading(true);
    // fetch family details for the selected row
    axios
      .get(APICONSTANTS.getFamiliesInfo.replace(':user_id', rowData._id))
      .then((res) => {
        setSelectedId(rowData.tableData.id);
        setLoading(false);
        props.setUsers({ family: res.data, selectedUser: rowData });
        history.push(
          props.patient
            ? CONSTANTS.patientPedigreeChartPath.replace(
                ':_id',
                rowData.familyId
              )
            : CONSTANTS.pedigreeChartPath.replace(':_id', rowData.familyId)
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [open, setOpen] = React.useState(false);

  const addClicked = () => {
    setOpen(true);
  };

  return (
    <>
      {!props.patient && <AddFamily open={open} setOpen={setOpen} />}
      <Table
        // buttonStyle={{ marginLeft: 230 }}
        rows={props.rows}
        columns={columns}
        addClicked={!props.patient && addClicked}
        addText="Add New Family"
        onRowClick={onRowClick}
        pageSize={8}
        title=""
        dense={true}
        highlightOnSelect={true}
        selectedTableDataId={selectedId}
        highlightColor={'#f7e406'}
      />
    </>
  );
}
