/**
 * Hook used when TG is opened from EMR
 * used to authenticate the launch token query param
 * Refer https://fhir.epic.com/Documentation?docId=oauth2&section=EmbeddedOauth2Launch
 * Calls the Epic Oauth api with launch token then redirects to the TG url with `code` query param
 */
import { useEffect } from 'react';

// hook to check launch query param is present and authenticate
const useEMRLaunchAuth = (emrSettings) => {
  const emrEndpoint = emrSettings?.endpoint;
  const clientId = emrSettings?.clientId;
  const redirectUri = emrSettings?.redirectUri;
  const EPIC_AUTH_BASE_URL = `${emrEndpoint}/oauth2`;
  const AUTHORIZE_LOGIN_URL = `${EPIC_AUTH_BASE_URL}/authorize`;
  const EPIC_API_BASE_URL = `${emrEndpoint}/api/FHIR/R4`;

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const launchToken = queryParams.get('launch');
    // const iss = queryParams.get('iss');
    if (launchToken && clientId && emrEndpoint) {
      // get the auth code
      const params = new URLSearchParams();
      params.append('response_type', 'code');
      params.append('client_id', clientId);
      params.append('redirect_uri', redirectUri);
      params.append('scope', 'launch');
      params.append('launch', launchToken);
      params.append('aud', EPIC_API_BASE_URL);
      params.append('state', 'abc');

      fetch(AUTHORIZE_LOGIN_URL, {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response) => {
        window.location.href = response.url;
      });
    }
    // should only happen on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useEMRLaunchAuth;
