import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CONSTANTS } from '../Constants';
import { Grid } from '@mui/material';
import PedigreeBox from './PedigreeBox';
import { AppContext } from 'src/store/ContextStore';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: { position: 'absolute', top: 0, left: 0 }
});

function PedigreeChartView({ patient }) {
  const classes = useStyles();
  const { content } = React.useContext(AppContext);
  const {
    pedigreeSelectedUsers: { selectedUser }
  } = content;

  const history = useHistory();

  React.useEffect(() => {
    if (!selectedUser?._id) {
      history.push(CONSTANTS.pedigreeToolPath);
    }
  }, [selectedUser, history]);

  const pedigree = React.useMemo(() => {
    return <>{selectedUser?._id && <PedigreeBox patient={patient} />}</>;
  }, [patient, selectedUser]);

  return (
    <Grid container className={classes.container}>
      {pedigree}
    </Grid>
  );
}

export default PedigreeChartView;
