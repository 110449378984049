import React, { useState, useContext, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { io } from 'socket.io-client';
import moment from 'moment';

import axios from 'src/axios';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import { UserContext, SnackContext } from 'src/store/ContextStore';
import { LoadingContext } from 'src/store/ContextStore';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import ChatProfile from './ChatProfile';
import SingleChat from './SingleChat';
import SendMsgBox from './SendMsgBox';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  chatSection: {
    width: '100%',
    height: '70vh',
    marginTop: 10
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  chatProfileSection: {
    borderRight: '1px solid #e0e0e0',
    height: '100%',
    overflowY: 'auto'
  },
  messageArea: {
    height: '50vh',
    overflowY: 'auto'
  }
});

/* eslint-disable no-undef */

const PatientChat = () => {
  const [chats, setChats] = useState([]); //All chat messages
  const [message, setMessage] = useState(''); //Single msg
  const [chatProfiles, setChatProfiles] = useState([]); //Side profiles of receiver(Physician)
  const [physicians, setPhysicians] = useState([]); //List of all phy id to send msg initially
  const [activeChat, setActiveChat] = useState({}); // {conversationId, receiverId}

  const socketRef = useRef(null);
  const activeConversationRef = useRef(null); //Active Conversation id ref
  const bottomEl = useRef(null);

  const { setSnack } = useContext(SnackContext);
  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const { _id: userId, email: userEmail } = user || {};
  const classes = useStyles();

  const updateMessageHandle = (conversationId, data) => {
    try {
      axios.put(
        APICONSTANTS.updateMessagesByConversationId.replace(
          ':conversationId',
          conversationId
        ),
        data
      );
    } catch (error) {
      console.log('ERROR-', error);
    }
  };

  const initSocket = () => {
    try {
      socketRef.current = io('');

      socketRef.current.on('connect', () => {
        console.log('SOCKET ID--->>', socketRef.current.id);
      });

      //Increment unread msg count - incUnreadMsgCount event
      socketRef.current.on(
        CONSTANTS.socketEvents.incUnreadMsgCount,
        (message) => {
          let { conversationId } = message;

          //If msg come for in active conversation then increment unread count
          if (activeConversationRef.current !== conversationId) {
            //Increment unread count for inactive conversation-
            setChatProfiles((preChatProfiles) => {
              return preChatProfiles.map((pre) => {
                if (pre.conversationId === conversationId) {
                  return {
                    ...pre,
                    unreadMessageCount: pre.unreadMessageCount + 1
                  };
                } else {
                  return {
                    ...pre
                  };
                }
              });
            });
          }
        }
      );

      //physicianAssigned event
      socketRef.current.on(CONSTANTS.socketEvents.physicianAssigned, (data) => {
        //When physician is joined room it will called
        const { conversationId, receiver, receiverId } = data;

        if (activeConversationRef.current === conversationId) {
          setActiveChat((pre) => ({ ...pre, receiverId: receiverId }));
        }

        setChatProfiles((preChatProfiles) => {
          let chatIndex = preChatProfiles.findIndex(
            (pre) => pre?.receiverId === null
          );
          if (chatIndex !== -1) {
            let clonedChatProfiles = [...preChatProfiles];
            clonedChatProfiles.splice(chatIndex, 1, {
              conversationId: conversationId,
              receiver: receiver,
              receiverId: receiverId,
              unreadMessageCount: 0
            });
            return [...clonedChatProfiles];
          } else {
            return [...preChatProfiles];
          }
        });
      });

      //message event
      socketRef.current.on(CONSTANTS.socketEvents.message, (message) => {
        let { conversationId, senderId } = message;

        if (activeConversationRef.current === conversationId) {
          setChats((pre) => [...pre, message]);

          //Here update msg key of isRead:true
          updateMessageHandle(conversationId, { receiverId: senderId });
        }
      });

      socketRef.current.on('disconnect', () => {
        console.log('DISCONNECT---', socketRef.current.id);
      });
    } catch (error) {
      console.log('ERROR----', error);
    }
  };

  const scrollToBottom = () => {
    bottomEl?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getChatsHandle = (convId) => {
    //Get chats by conversation id-
    try {
      axios
        .get(
          APICONSTANTS.getMessagesByConversationId.replace(
            ':conversationId',
            convId
          )
        )
        .then(({ data }) => {
          setChats(data || []);
        });
    } catch (error) {
      console.log('ERROR-', error);
    }
  };

  const onProfileClick = (chatProfileData, chatProfilesResp) => {
    const { conversationId, receiverId } = chatProfileData;

    if (conversationId === activeChat.conversationId) return;
    activeConversationRef.current = conversationId;

    setActiveChat({
      conversationId: conversationId,
      receiverId: receiverId
    });

    let chatProfilesToMap = chatProfilesResp || chatProfiles;

    //Update unRead msg count to 0 for that conversation-
    setChatProfiles(
      chatProfilesToMap.map((pre) => {
        if (pre.conversationId === conversationId) {
          return {
            ...pre,
            unreadMessageCount: 0
          };
        } else {
          return {
            ...pre
          };
        }
      })
    );

    //Also update isRead to true in all messages for that conversation
    // NOTE: FIX for null  error
    if (receiverId) updateMessageHandle(conversationId, { receiverId });
    getChatsHandle(conversationId);

    //Enter Patient into a room for that conversation Id- joinRoom event
    if (socketRef.current)
      socketRef.current.emit(CONSTANTS.socketEvents.joinRoom, conversationId);
  };

  const getConversations = () => {
    setLoading(true);
    try {
      axios
        .get(APICONSTANTS.getConversation.replace(':user_id', userId))
        .then(({ data }) => {
          const { conversations } = data || {};

          if (conversations && conversations.length > 0) {
            //Also make by default selected-
            onProfileClick(conversations[0], conversations);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.log('ERROR-', error);
    }
  };

  const createConversation = () => {
    try {
      axios
        .post(APICONSTANTS.createConversation)
        .then(({ data }) => {
          setSnack({
            status: true,
            msg: 'Conversation created!',
            severity: 'success'
          });
          const { conversationId } = data;
          activeConversationRef.current = conversationId;

          setActiveChat({
            conversationId: conversationId,
            receiverId: null
          });

          setChatProfiles((preChatProfiles) => {
            return [
              {
                conversationId: conversationId,
                receiver: null,
                receiverId: null,
                unreadMessageCount: 0
              },
              ...preChatProfiles
            ];
          });
          setChats([]);

          //Enter Patient into a room for that conversation Id- joinRoom event
          if (socketRef.current)
            socketRef.current.emit(
              CONSTANTS.socketEvents.joinRoom,
              conversationId
            );
        })
        .catch((error) => {
          setSnack({
            status: true,
            msg: 'General conversation already exist!',
            severity: 'error'
          });
        });
    } catch (error) {
      console.log('ERR-');
    }
  };

  const getPhysicians = () => {
    try {
      axios
        .get(APICONSTANTS.getPhysicanToSendMsg.replace(':user_id', userId))
        .then(({ data }) => {
          setPhysicians(data.map((pre) => pre._id));
        });
    } catch (error) {
      console.log('ERROR-', error);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  useEffect(() => {
    if (!userId) return;

    initSocket();
    getConversations();
    getPhysicians();

    return () => {
      if (socketRef.current) socketRef.current.disconnect;
    };
  }, [userId]);

  const onSendMsg = (evt) => {
    evt.preventDefault();
    if (
      !message ||
      !activeChat.conversationId ||
      !userId ||
      !socketRef.current ||
      !socketRef.current.connected
    ) {
      setSnack({
        status: true,
        msg: 'Something went wrong! Please check your internet.',
        severity: 'error'
      });
      return;
    }

    let messageToSend = {
      message: message,
      messageType: 'text',
      senderId: userId,
      sender: userEmail,
      receiverId: activeChat.receiverId, //Initially it will be null
      conversationId: activeChat.conversationId,
      timestamp: moment(),
      status: 'SENT', // sent, delivered, read
      isRead: false //Initially false
    };

    if (!activeChat.receiverId) {
      //This will send to msg to all physician initially in list- sendGeneralMessage event
      socketRef.current.emit(
        CONSTANTS.socketEvents.sendGeneralMessage,
        messageToSend,
        physicians
      );
    } else {
      //This will send to msg to assigned physician- sendMessage event
      socketRef.current.emit(CONSTANTS.socketEvents.sendMessage, messageToSend);
    }

    setChats((pre) => [...pre, messageToSend]);
    setMessage('');
  };

  const onMsgChange = (evt) => {
    setMessage(evt.target.value);
  };

  return (
    <>
      <Grid container>
        <HeaderTitle
          text={'Chats'}
          showBtn={true}
          addText="Add New Conversation"
          autoWidth={true}
          style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}
          addClicked={createConversation}
        />

        <Grid container component={Paper} className={classes.chatSection}>
          <Grid item xs={3} className={classes.chatProfileSection}>
            {/* LEFT CHAT PROFILE */}

            {chatProfiles.map((chatProfile) => (
              <ChatProfile
                chatProfile={chatProfile}
                key={chatProfile.conversationId}
                onProfileClick={onProfileClick}
                activeId={activeChat.conversationId}
              />
            ))}
          </Grid>
          {/* Right Panel */}
          {activeChat.conversationId ? (
            <Grid item xs={9}>
              {/* CHATS SECTION */}
              <List className={classes.messageArea}>
                {chats.map((chat) => (
                  <SingleChat key={chat.messageId} chat={chat} />
                ))}
                <div ref={bottomEl}></div>
              </List>

              <Divider />
              {/* Bottom Msg Box */}
              <SendMsgBox
                onSendMsg={onSendMsg}
                onMsgChange={onMsgChange}
                message={message}
                isGeneralConversation={!activeChat.receiverId}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default PatientChat;
