import React from 'react';

import { useFormContext } from 'src/components/custom-form/FormContext';
import Deactivated from 'src/components/UI/container/Deactivated';
import Success from 'src/components/UI/addons/Success';
import UserCustomForm from './UserCustomForm';
import { CONSTANTS } from './Constants';

export default function CustomFormContainer() {
  const { status, form, isInvalidLink, isDeactivatedLink } = useFormContext();

  if (isDeactivatedLink) {
    return <Deactivated />;
  }

  if (isInvalidLink) {
    return (
      <Deactivated
        title="You have stumbled upon an invalid page"
        subtext="Please contact the department that issued this link."
      />
    );
  }

  if (status === CONSTANTS.user_status.SUBMITTED) return <Success />;

  if (form) return <UserCustomForm />;

  return null;
}
