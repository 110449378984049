import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const useStyles = makeStyles(() => ({
  spacing: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export default function ModalButtons(props) {
  const classes = useStyles();

  const {
    handleClose,
    onClick,
    isEditing,
    continueAdding,
    handleContinueAdding,
    addLabel,
    continueAddingLabel,
    intermediateAction,
    intermediateBtnLabel,
    hideContinueAdding = false,
    hideCancelBtn = false
  } = props;

  // used to disable btn
  const [loading, setLoading] = React.useState(false);

  const t = useAutoTranslation();

  const ContinueAddingForm = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            color="secondary"
            checked={continueAdding}
            onChange={handleContinueAdding}
            size="small"
            name="continueAdding"
          />
        }
        label={t(continueAddingLabel) || t('Continue adding')}
      />
    );
  };

  const saveClickHandler = (e) => {
    setLoading(true);
    onClick(e)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {!isEditing && intermediateAction && !hideContinueAdding && (
        <Grid item xs={12}>
          <ContinueAddingForm />
        </Grid>
      )}
      <Grid container item xs={12} justifyContent="center">
        <div className={classes.spacing}>
          {!isEditing && !intermediateAction && !hideContinueAdding && (
            <ContinueAddingForm />
          )}
          {!hideCancelBtn && (
            <Button onClick={handleClose} variant="outlined" color="secondary">
              {t(`Cancel`)}
            </Button>
          )}

          {intermediateAction && (
            <Button
              variant="contained"
              type="button"
              color="secondary"
              onClick={intermediateAction}
            >
              {t(intermediateBtnLabel) || t('Save & Continue later')}
            </Button>
          )}
          <Button
            onClick={saveClickHandler}
            variant="contained"
            color="secondary"
            disabled={loading}
            endIcon={loading ? <CircularProgress size={15} /> : undefined}
            type="submit"
          >
            {isEditing ? t(`Save Changes`) : t(addLabel)}
          </Button>
        </div>
      </Grid>
    </>
  );
}
