import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'src/axios';
import { APICONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';

export default function Filters(props) {
  const [familyId, setFamilyId] = React.useState('');
  const [trakGeneId, setTrakGeneId] = React.useState('');
  const [name, setName] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const { setSnack } = React.useContext(SnackContext);

  const isValidFilter = () => {
    return name || trakGeneId || familyId;
  };

  const applyFilters = () => {
    if (isValidFilter()) {
      setLoading(true);
      axios
        .post(APICONSTANTS.filterProbandUsers, { name, trakGeneId, familyId })
        .then((res) => {
          setLoading(false);
          // update values in parent component
          props.applyFilters(res.data);
          props.clicked();
          setSnack({
            status: true,
            msg: 'Filters applied',
            severity: 'success'
          });
        })
        .catch(() => {
          // handle err
          setLoading(false);
          setSnack({
            status: true,
            msg: 'Error applying filters',
            severity: 'error'
          });
        });
    }
  };

  const clearFilters = () => {
    setFamilyId('');
    setTrakGeneId('');
    setName('');
    props.applyFilters([]);
  };

  return (
    <form noValidate onSubmit={applyFilters}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <TextField
              label={'Family ID'}
              placeholder="Enter Family Id"
              value={familyId}
              onChange={(e) => setFamilyId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={'TrakGene ID'}
              placeholder="Enter TrakGene Id"
              value={trakGeneId}
              onChange={(e) => setTrakGeneId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={'Name'}
              placeholder="Enter Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="margin-top-2 margin-bottom-2">
            <Divider />
          </div>
        </Grid>

        <Grid item>
          <Button
            // variant="contained"
            onClick={applyFilters}
            disabled={loading}
            type="submit"
            endIcon={
              loading ? <CircularProgress color="secondary" size={20} /> : ''
            }
            className="custom-btn"
          >
            Apply
          </Button>
        </Grid>

        <Grid item>
          <Button variant="outlined" onClick={clearFilters}>
            Clear
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
