import React from 'react';
import Zoom from '@mui/material/Zoom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import BoxContainer from 'src/components/UI/container/BoxContainer';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { red } from '@mui/material/colors';

export default function Deactivated({ icon, title, subtext }) {
  const t = useAutoTranslation();

  return (
    <BoxContainer>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={3}>
          <Zoom in={true}>
            {icon ?? <CancelIcon style={{ color: red[400], fontSize: 100 }} />}
          </Zoom>
        </Grid>
        {/* <div className="margin-top-2"></div> */}
        <Grid item xs={9}>
          <Typography variant="h5">
            {title ? t(title) : t('The link has been deactivated.')}
          </Typography>
          <Typography>
            {subtext
              ? t(subtext)
              : t(
                  'If you still need to submit family history information then please contact the genetics department that issued this link.'
                )}
          </Typography>
        </Grid>
      </Grid>
    </BoxContainer>
  );
}
