import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SimpleAutocompleteInput(props) {
  const { highlightErrorBorder, helperText, ...rest } = props;
  const error = props.validated && !props.value;

  return (
    <Autocomplete
      {...rest}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          label={undefined}
          className={props.className}
          variant="outlined"
          fullWidth
          error={highlightErrorBorder || error}
          helperText={error && helperText}
        />
      )}
      validated={undefined}
    />
  );
}
