import React, { useState, useMemo, useEffect } from 'react';
import { APICONSTANTS, CONSTANTS } from '../Constants';
import UsersTableProgressCol from '../proband-info/UsersProgressStatus';
import Table from '../UI/table/Table';
import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import axios from 'src/axios';
import { useHistory } from 'react-router-dom';
import { useAutoTranslation } from 'src/hooks/useTranslate';

export default function ProgressTrackerTable({
  schemas,
  users,
  selectedWorkflow,
  setSelectedWorkflow
}) {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState('forms');
  const history = useHistory();
  const viewForm = (id) =>
    id &&
    history.push(CONSTANTS.formResponsesPath.replace(':_id', id), {
      from: CONSTANTS.formProgressTracker
    });

  useEffect(() => {
    axios
      .get(APICONSTANTS.formBuilderWorkflowSchemas)
      .then(({ data }) => setWorkflows(data.workflows));
  }, []);

  const columns = useMemo(() => {
    const defaultValues = [
      {
        title: '#',
        field: 'id',
        width: 10,
        sorting: false
      },
      {
        title: 'E-mail',
        field: 'email',
        width: 100
      }
    ];

    let newValues;

    if (typeof selectedWorkflow === 'string') {
      newValues = schemas.map((schema) => {
        return {
          title: <div style={{ paddingLeft: 10 }}>{schema.title}</div>,
          headerStyle: {
            textAlign: 'center'
          },
          cellStyle: {
            textAlign: 'center'
          },
          field: 'status',
          render: (data) => {
            return (
              <div onClick={() => viewForm(data.form[schema._id]?.resId)}>
                <UsersTableProgressCol status={data.form[schema._id]?.status} />
              </div>
            );
          },
          width: 100
        };
      });
    } else {
      const workflowItems = [];
      selectedWorkflow.forms.map((id) => {
        schemas.map((schema) => {
          if (schema._id === id) {
            workflowItems.push({
              title: <div style={{ paddingLeft: 10 }}>{schema.title}</div>,
              headerStyle: {
                textAlign: 'center'
              },
              cellStyle: {
                textAlign: 'center'
              },
              field: 'status',
              render: (data) => {
                return (
                  <div onClick={() => viewForm(data.form[schema._id]?.resId)}>
                    <UsersTableProgressCol
                      status={data.form[schema._id]?.status}
                    />
                  </div>
                );
              },
              width: 100
            });
          }
        });
      });

      newValues = workflowItems;
    }

    return [...defaultValues, ...newValues];
  }, [schemas, selectedWorkflow]);

  const table = useMemo(() => {
    const pageSize = users.length > 10 ? 20 : 10;

    return (
      <div style={{ position: 'relative' }}>
        <Table
          title={'Progress Tracker'}
          rows={users}
          columns={columns}
          pageSize={pageSize}
          dense={true}
        />
        <div style={{ position: 'absolute', bottom: 10 }}>
          <ChartHelper />
        </div>
        <div style={{ position: 'absolute', top: 10, left: 210 }}>
          <DropDown
            workflows={workflows}
            setSelectedWorkflow={setSelectedWorkflow}
            selectedWorkflowId={selectedWorkflowId}
            setSelectedWorkflowId={setSelectedWorkflowId}
          />
        </div>
      </div>
    );
  }, [columns, users, selectedWorkflowId, workflows]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {table}
      </Grid>
    </Grid>
  );
}

const ChartHelper = () => {
  const userStatus = CONSTANTS.user_status;

  const t = useAutoTranslation();

  const STATUS = {
    [userStatus.DEACTIVATED]: 'Deactivated',
    [userStatus.CREATED]: 'Created',
    [userStatus.PENDING]: 'Pending',
    [userStatus.IN_PROGRESS]: 'In Progress',
    [userStatus.SUBMITTED]: 'Submitted',
    [userStatus.IMPORTED]: 'Imported'
  };

  return (
    <div style={{ display: 'flex', marginInline: 10 }}>
      {Object.keys(userStatus).map((status) => {
        return (
          <span
            key={status}
            style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}
          >
            <UsersTableProgressCol status={status} />
            <span style={{ marginInline: 5 }}>{t(STATUS[status])}</span>
          </span>
        );
      })}
    </div>
  );
};

const DropDown = ({
  workflows,
  setSelectedWorkflow,
  selectedWorkflowId,
  setSelectedWorkflowId
}) => {
  const handleChange = (e) => {
    setSelectedWorkflowId(e.target.value);
    const workflow = workflows.find(
      (workflow) => workflow._id === e.target.value
    );
    workflow
      ? setSelectedWorkflow({
          forms: workflow.selectedForms,
          id: workflow._id,
          name: workflow.workflowName
        })
      : setSelectedWorkflow('forms');
  };

  return (
    <FormControl style={{ width: 150 }}>
      <Select
        lable="select"
        value={selectedWorkflowId}
        onChange={handleChange}
        variant="outlined"
      >
        <MenuItem value="forms">Forms</MenuItem>
        {workflows.map((workflow) => {
          return (
            <MenuItem key={workflow._id} value={workflow._id}>
              {workflow.workflowName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
