import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material';

const filter = createFilterOptions();

const filterOptions = (options, params) => {
  const filtered = filter(options, params);

  // Suggest the creation of a new value
  if (params.inputValue !== '') {
    filtered.push({
      inputValue: params.inputValue,
      label: `Add "${params.inputValue}"`
    });
  }

  return filtered;
};

export default function AutocompleteInput(props) {
  const { highlightErrorBorder, helperText, ...rest } = props;

  const onChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof newValue === 'string') {
      props.onChange({
        label: newValue
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      props.onChange({
        label: newValue.inputValue
      });
    } else {
      props.onChange(newValue);
    }
  };

  const error = props.validated && !props.value;

  return (
    <Autocomplete
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          label={undefined}
          className={props.className}
          variant="outlined"
          fullWidth
          error={highlightErrorBorder || error}
          helperText={error && helperText}
        />
      )}
      onChange={onChange}
      filterOptions={filterOptions}
      // renderOption={(option) => option.label}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "value" option created dynamically
        if (option.label) {
          return option.label;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      validated={undefined}
    />
  );
}
