import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import LinkIcon from '@mui/icons-material/Link';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';

import TourContent from './TourContent';
import { CONSTANTS } from 'src/components/Constants';

const TOUR_CONSTANTS = {
  welcome: 'Let us show you around and give you a quick tour of the app.',
  fhq: 'Click on this icon to create secure private links to the family history questionnaire and send it in an e-mail.',
  fhqGenerate:
    'Enter the e-mail address(es) and click on ‘Generate Link and Send E-mail’ to send a standard message to each recipient, including a unique private link to the questionnaire.',
  fhqGenerateSubTitle:
    'Previously generated links are shown in the table below.',
  proband:
    'Click here to view a list of the Probands / respondents and see their submission status.',
  editRecord:
    'Probands will appear in this table and the status of their family history questionnaire response shown. Each family can be expanded to show details of other family members that has been submitted.',
  editRecordSubTitle:
    'You can view the original submission and edit records for individuals. If you edit individual family members details in the app, the changes are saved but the originally submission is retained and always available.',
  export:
    'Families can be exported as an XML file or JSON file (GA4GH pedigree standard) for import into other systems.',
  pedigree: 'Click here to view family pedigrees.',
  pedigreeSubTitle:
    'Pedigrees are automatically generated based on the data submitted and symbols automatically shown for diagnoses.',
  chat: 'Click here to start and view chat with clinicians.',
  ready: 'Great job, you’re ready to start using the app!',
  readySubTitle:
    'You can replay this tour any time by clicking on the help icon on the top menu bar.'
};

const TOUR_TITLE_CONSTANTS = {
  welcome: 'Welcome!',
  fhq: 'Send Family History Questionnaire',
  fhqGenerate: 'Generate and Send Private Link',
  proband: 'View Probands and Submission Status',
  editRecord: 'View Submissions and Edit Records',
  export: 'Export Data',
  pedigree: 'View Pedigrees',
  chat: 'View Chat',
  ready: 'You’re Ready to Go'
};

const PATIENT_TOUR = {
  welcome: 'Let us show you around and give you a quick tour of the app.',
  dashboardMessage:
    "Here you can track the progress of all forms and take actions to submit them from the 'Generate Links' table.",
  proband:
    'Click here to view a your Family History Questionnaire  and see there submission status.',
  editRecord:
    'The Family History Questionnaire will be displayed in this table, providing the status of each response. You can expand each family entry to view details about your family members.',
  editRecordSubTitle:
    "You can take action to edit the Family History Questionnaire by clicking the button on 'Action' tab.",

  pedigree: 'Click here to view your family pedigrees.',
  pedigreeSubTitle:
    'Pedigrees are automatically generated based on the data you have submitted.',
  chat: 'Click here to start and view chat with clinicians.',
  ready: 'Great job, you’re ready to start using the app!',
  readySubTitle:
    'You can replay this tour any time by clicking on the help icon on the top menu bar.'
};

const PATIENT_TOUR_TITLE = {
  welcome: 'Welcome to Patient Engagement Portal !',
  dashboard: 'Dashboard',
  proband: 'View your Family History Questionnaire ',
  editRecord: 'Submit/Edit your Family History Questionnaire ',
  pedigree: 'View Your Pedigrees',
  chat: 'View Chat',
  ready: 'You’re Ready to Go'
};

const getIcon = (icon) => {
  let iconComp = '';
  switch (icon) {
    case 'welcome':
      iconComp = <HomeIcon />;
      break;
    case 'fhq':
      iconComp = <LinkIcon />;
      break;
    case 'fhqGenerate':
      iconComp = <EmailIcon />;
      break;
    case 'proband':
      iconComp = <AccountBoxIcon />;
      break;
    case 'editRecord':
      iconComp = <EditIcon />;
      break;
    case 'export':
      iconComp = <GetAppIcon />;
      break;
    case 'pedigree':
      iconComp = <AccountTreeIcon />;
      break;
    case 'chat':
      iconComp = <ChatIcon />;
      break;
    case 'ready':
      iconComp = <ThumbUpIcon />;
      break;

    default:
      return '';
  }

  return iconComp;
};

const TOUR_STEPS = [
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.welcome,
    icon: getIcon('welcome'),
    content: <TourContent title={TOUR_CONSTANTS.welcome} />,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '#tour-fhq',
    title: TOUR_TITLE_CONSTANTS.fhq,
    icon: getIcon('fhq'),
    content: <TourContent title={TOUR_CONSTANTS.fhq} />,
    disableBeacon: true,
    placement: 'right'
  },
  {
    target: '#tour-fhqGenerate',
    title: TOUR_TITLE_CONSTANTS.fhqGenerate,
    icon: getIcon('fhqGenerate'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.fhqGenerate}
        subTitle={TOUR_CONSTANTS.fhqGenerateSubTitle}
      />
    ),
    placement: 'right'
  },
  {
    target: '#tour-proband',
    title: TOUR_TITLE_CONSTANTS.proband,
    icon: getIcon('proband'),
    content: <TourContent title={TOUR_CONSTANTS.proband} />,
    placement: 'right'
  },
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.editRecord,
    icon: getIcon('editRecord'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.editRecord}
        subTitle={TOUR_CONSTANTS.editRecordSubTitle}
      />
    ),
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.export,
    icon: getIcon('export'),
    content: <TourContent title={TOUR_CONSTANTS.export} />,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '#tour-pedigree',
    title: TOUR_TITLE_CONSTANTS.pedigree,
    icon: getIcon('pedigree'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.pedigree}
        subTitle={TOUR_CONSTANTS.pedigreeSubTitle}
      />
    ),
    placement: 'right'
  },
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.ready,
    icon: getIcon('ready'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.ready}
        subTitle={TOUR_CONSTANTS.readySubTitle}
      />
    ),
    disableBeacon: true,
    placement: 'center'
  }
];

const PATIENT_TOUR_STEPS = [
  {
    target: 'body',
    icon: getIcon('welcome'),
    title: PATIENT_TOUR_TITLE.welcome,
    content: <TourContent title={PATIENT_TOUR.welcome} />,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: 'body',
    title: PATIENT_TOUR_TITLE.dashboard,
    icon: getIcon('welcome'),
    content: <TourContent title={PATIENT_TOUR.dashboardMessage} />,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '#tour-proband',
    title: PATIENT_TOUR_TITLE.proband,
    icon: getIcon('proband'),
    content: <TourContent title={PATIENT_TOUR.proband} />,
    placement: 'right'
  },
  {
    target: 'body',
    title: PATIENT_TOUR_TITLE.editRecord,
    icon: getIcon('editRecord'),
    content: (
      <TourContent
        title={PATIENT_TOUR.editRecord}
        subTitle={PATIENT_TOUR.editRecordSubTitle}
      />
    ),
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '#tour-pedigree',
    title: PATIENT_TOUR_TITLE.pedigree,
    icon: getIcon('pedigree'),
    content: (
      <TourContent
        title={PATIENT_TOUR.pedigree}
        subTitle={PATIENT_TOUR.pedigreeSubTitle}
      />
    ),
    placement: 'right'
  },
  {
    target: '#tour-chat',
    title: PATIENT_TOUR_TITLE.chat,
    icon: getIcon('chat'),
    content: <TourContent title={PATIENT_TOUR.chat} />,
    placement: 'right'
  },
  {
    target: 'body',
    title: PATIENT_TOUR_TITLE.ready,
    icon: getIcon('ready'),
    content: (
      <TourContent
        title={PATIENT_TOUR.ready}
        subTitle={PATIENT_TOUR.readySubTitle}
      />
    ),
    disableBeacon: true,
    placement: 'center'
  }
];

const STUDENT_TOUR_STEPS = [
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.welcome,
    icon: getIcon('welcome'),
    content: <TourContent title={TOUR_CONSTANTS.welcome} />,
    disableBeacon: true,
    placement: 'center'
  },
  {
    target: '#tour-pedigree',
    title: TOUR_TITLE_CONSTANTS.pedigree,
    icon: getIcon('pedigree'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.pedigree}
        subTitle={TOUR_CONSTANTS.pedigreeSubTitle}
      />
    ),
    placement: 'right'
  },
  {
    target: 'body',
    title: TOUR_TITLE_CONSTANTS.ready,
    icon: getIcon('ready'),
    content: (
      <TourContent
        title={TOUR_CONSTANTS.ready}
        subTitle={TOUR_CONSTANTS.readySubTitle}
      />
    ),
    disableBeacon: true,
    placement: 'center'
  }
];

const tourConfig = {
  general: [
    {
      index: 0
    },
    {
      index: 1,
      nextLink: CONSTANTS.generateLinkPath
    },
    {
      index: 2,
      prevLink: CONSTANTS.adminDashboardPath
    },
    {
      index: 3,
      nextLink: CONSTANTS.probandInfoPath,
      prevLink: CONSTANTS.generateLinkPath
    },
    {
      index: 4,
      prevLink: CONSTANTS.generateLinkPath
    },
    {
      index: 5
    },
    {
      index: 6,
      nextLink: CONSTANTS.adminDashboardPath,
      prevLink: CONSTANTS.probandInfoPath
    },
    {
      index: 7,
      prevLink: CONSTANTS.probandInfoPath,
      end: true
    }
  ],
  patient: [
    {
      index: 0
    },
    {
      index: 1,
      nextLink: CONSTANTS.patientProband
    },
    {
      index: 2,
      nextLink: CONSTANTS.patientProband
    },
    {
      index: 3,
      prevLink: CONSTANTS.patientDashboardPath
    },
    {
      index: 4,
      prevLink: CONSTANTS.patientProband
    },
    {
      index: 5,
      nextLink: CONSTANTS.patientPedigreeToolPath
    },
    {
      index: 6,
      prevLink: CONSTANTS.patientProband,
      nextLink: CONSTANTS.patientDashboardPath
    },
    {
      index: 7,
      prevLink: CONSTANTS.patientPedigreeToolPath,
      end: true
    }
  ],
  student: [
    {
      index: 0
    },
    {
      index: 1
    },
    {
      index: 2,
      end: true
    }
  ]
};

const getTourSteps = (userType) => {
  switch (userType) {
    case CONSTANTS.userTypes.PATIENT:
      return PATIENT_TOUR_STEPS;
    case CONSTANTS.userTypes.STUDENT:
      return STUDENT_TOUR_STEPS;
    default:
      return TOUR_STEPS;
  }
};

const styles = {
  tooltipContainer: {
    textAlign: 'left'
  },
  tooltip: {
    padding: 0,
    width: 500
  },
  tooltipFooter: {
    justifyContent: 'center',
    marginTop: 0
  }
};

export {
  TOUR_STEPS,
  PATIENT_TOUR_STEPS,
  STUDENT_TOUR_STEPS,
  styles,
  getIcon,
  getTourSteps,
  tourConfig
};
