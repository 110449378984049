export const EVENTS = {
  SET_EPIC_EMR_STATE: 'SET_EPIC_EMR_STATE',
  CLEAR_EPIC_EMR_STATE: 'CLEAR_EPIC_EMR_STATE'
};

export const INITIAL_INTEGRATIONS_STATE = {
  epicEMRState: {
    access_token: null,
    refresh_token: null,
    // token expires in DATE format
    expires_in: null
  }
};

export const integrationsReducer = (state, { type, payload }) => {
  switch (type) {
    case EVENTS.SET_EPIC_EMR_STATE:
      return {
        ...state,
        epicEMRState: {
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
          expires_in: payload.expires_in
        }
      };

    case EVENTS.CLEAR_EPIC_EMR_STATE:
      return {
        ...state,
        epicEMRState: INITIAL_INTEGRATIONS_STATE.epicEMRState
      };
  }
};
