import React from 'react';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { Grid } from '@mui/material';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { icon, title, classes, onCloseProps, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.title}>
        <div style={{ marginTop: 5 }}>{icon}</div>
        <Typography variant="h6"> {title} </Typography>
      </div>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        {...onCloseProps}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    gap: 15,
    justifyContent: 'flex-start'
  }
}))(MuiDialogActions);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CustomTooltip = ({
  continuous,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  index,
  isLastStep,
  step,
  tooltipProps
}) => {
  const {
    content,
    hideBackButton,
    locale,
    showSkipButton,
    title,
    icon,
    styles
  } = step;
  const { back, close, last, next, skip, start } = locale;
  const output = {
    primary: close
  };

  if (continuous) {
    if (isLastStep) {
      output.primary = last;
    } else {
      if (index > 0) output.primary = next;
      else output.primary = start;
    }
  }

  if (showSkipButton && !isLastStep) {
    output.skip = (
      <Button variant="outlined" {...skipProps}>
        {skip}
      </Button>
    );
  }

  if (!hideBackButton && index > 0) {
    output.back = (
      <Button variant="outlined" {...backProps}>
        {back}
      </Button>
    );
  }

  // translation
  const t = useAutoTranslation();

  return (
    <div key="JoyrideTooltip" style={styles.tooltip} {...tooltipProps}>
      <div style={styles.tooltipContainer}>
        <DialogTitle
          id="p-dialog"
          onCloseProps={closeProps}
          title={t(title)}
          icon={icon}
        />

        {content && <DialogContent dividers>{content}</DialogContent>}
      </div>
      <div style={styles.tooltipFooter}>
        {output.skip}
        {output.back}

        <DialogActions>
          <Button color="secondary" variant="contained" {...primaryProps}>
            {t(output.primary)}
          </Button>
        </DialogActions>
      </div>
    </div>
  );
};

export default CustomTooltip;
