// ContextAPI
import { FormData, PopupContext } from '../../../../CreateQuestionnaire';

// Libraries
import React, { useContext } from 'react';
import { useFormik } from 'formik';

// Components
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

// styles
import styles from './AddGroup.module.css';

// Dependencies
import GraphStructureService from '../../../../services/graph.structurer.service.js';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@mui/material';
import { useState } from 'react';
import { FORM_TYPES } from '../../../../constants/formTypes.js';
import { useEffect } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { WarningOutlined } from '@mui/icons-material';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const formFields = [
  { id: 'GroupHeader', label: 'Group Header', type: 'text' },
  { id: 'GroupDescription', label: 'Group Description', type: 'text' }
];
const initialFormValues = {
  GroupHeader: '',
  GroupDescription: '',
  Condition: {
    condition: 'None',
    type: 'NONE',
    value: []
  },
  ConditionOptions: [],
  repeat: false
};

function AddGroup({ edit, formType }) {
  // Dependencies
  const gs = new GraphStructureService();

  //   ContextAPI
  const [formData, setFormData] = useContext(FormData);
  const [popupContext, setPopupContext] = useContext(PopupContext);

  // State
  const [conditionElements, setConditionElements] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [error, setError] = useState('');

  const [formikState, setFormikState] = useState({ values: initialFormValues });

  const handleEditSection = () => {
    setFormData(
      gs.editSection(
        popupContext.data.id,
        {
          title: formikState.values.GroupHeader,
          description: formikState.values.GroupDescription,
          condition: formikState.values.Condition,
          repeat: formikState.values.repeat
        },
        formData
      )
    );
    setPopupContext({ ...popupContext, show: false });
  };

  // handleSubmit
  const handleFormSubmit = async (values) => {
    const condition = {
      formId: values.Condition?.id,
      sectionId: values.Condition?.sectionId,
      value: values.Condition?.value,
      condition: values.Condition?.condition,
      type: values.Condition?.type,
      options: conditionOptions
    };
    if (!gs.verifyUniqueCondition(popupContext.data.id, formData, condition)) {
      setError('This specified condition already exists in the parent section');
      return;
    }
    if (edit) {
      handleEditSection();
      return;
    }
    const newGroup = await gs.initializeEmptyGroup({
      initialFormValues: {
        FormTitle: values.GroupHeader,
        FormDescription: values.GroupDescription,
        repeat: values.repeat
      },
      condition,
      previousConnection: popupContext.data.id,
      formType: formType
    });
    let updateFormData = { ...formData };
    updateFormData.schema[popupContext.data.id].groupsConnectedTo.push({
      id: newGroup.id,
      renderType: 'default'
    });
    updateFormData.schema[newGroup.id] = { ...newGroup };
    setFormData(updateFormData);
    setPopupContext({ ...popupContext, show: false });
  };

  const handleClose = async () => {
    setPopupContext({ ...popupContext, show: false });
  };

  // Formik
  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values) => {
      handleFormSubmit(formikState.values);
    }
  });

  useEffect(() => {
    if (popupContext.edit) {
      if (
        popupContext.data.previousConnections &&
        popupContext.data.previousConnections.length
      ) {
        setConditionElements(
          gs.getConditionCheckElements(
            popupContext.data.previousConnections[0],
            formData
          )
        );
        setConditionOptions(popupContext.data.condition?.options);
        setFormikState({
          values: {
            GroupHeader: popupContext.data.title,
            GroupDescription: popupContext.data.description,
            Condition: popupContext.data.condition,
            repeat: popupContext.data.repeat
          }
        });
      } else
        setFormikState({
          values: {
            GroupHeader: popupContext.data.title,
            GroupDescription: popupContext.data.description
          }
        });
    } else {
      setConditionElements(
        gs.getConditionCheckElements(popupContext.data.id, formData)
      );
    }
  }, []);

  const t = useAutoTranslation();

  return (
    <div className={styles.InitialFormScreen} onSubmit={handleFormSubmit}>
      {formType ? (
        <>
          <h2>{t(`Add New Form`)}</h2>
          <p>
            {t(`Add new form with set of inputs and add conditions to make the form
            more personalized`)}
          </p>
        </>
      ) : (
        <>
          <h2>{t(`Add New Section`)}</h2>
          <p>
            {t(`Add new section of inputs and add conditions to make the form more
            personalized`)}
          </p>
          <Alert severity="info">
            {t(` The Header and description will not be visible to the user`)}
          </Alert>
        </>
      )}
      <form onSubmit={formik.handleSubmit} className={styles.InitialForm}>
        {formFields.map((formField, index) => (
          <div
            key={`New_Group_Detail_${index}`}
            className={styles.FormFieldContainer}
          >
            <TextField
              variant="outlined"
              className={styles.FormField}
              id={formField.id}
              name={formField.id}
              label={formField.label}
              value={formikState.values[formField.id]}
              onChange={(e) => {
                formik.handleChange(e);
                setFormikState({
                  values: {
                    ...formikState.values,
                    [formField.id]: e.target.value
                  }
                });
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched[formField.id] &&
                Boolean(formik.errors[formField.id])
              }
              helperText={
                formik.touched[formField.id] && formik.errors[formField.id]
              }
            />
          </div>
        ))}
        {/* <FormControlLabel
          control={
            <Checkbox
              id="repeat"
              name="repeat"
              checked={formikState.values['repeat']}
              onChange={() =>
                setFormikState({
                  ...formikState,
                  values: {
                    ...formikState.values,
                    repeat: !formikState.values.repeat
                  }
                })
              }
            />
          }
          label="Repeating Section"
        /> */}
        {(popupContext.data.id != formData.startingGroupId || !edit) && (
          <div>
            {formType ? (
              <>
                <h4>{t(`Add Conditions`)}</h4>
                <p>
                  {t(`Add conditions to render this form depending upon the options
                  selected on the previous form`)}
                </p>
              </>
            ) : (
              <>
                <h4>{t(`Add Conditions`)}</h4>
                <p>
                  {t(`Add conditions to render this section depending upon the
                  options selected with in the form`)}
                </p>
              </>
            )}

            <Select
              variant="outlined"
              placeholder="Form Type"
              style={{ width: '100%' }}
              value={formikState.values.Condition.condition}
            >
              <MenuItem
                key={`Condition_${0}`}
                value={`None`}
                onClick={() => {
                  formikState.values.Condition = {
                    condition: 'None',
                    type: 'NONE'
                  };
                  setFormikState({
                    values: {
                      ...formikState.values,
                      Condition: {
                        condition: 'None',
                        type: 'NONE'
                      }
                    }
                  });
                }}
              >
                None
              </MenuItem>
              {conditionElements.map((ft, index) => (
                <MenuItem
                  key={`Condition_${index + 1}`}
                  value={`${formData.schema[ft.sectionId].title} | ${ft.label}`}
                  onClick={() => {
                    setConditionOptions([...ft.options]);
                    // setFormikState({ values: formik.values });
                    setFormikState({
                      values: {
                        ...formikState.values,
                        Condition: {
                          ...ft,
                          condition: `${
                            formData.schema[ft.sectionId].title
                          } | ${ft.label}`,
                          value: []
                        }
                      }
                    });
                  }}
                >
                  {formData.schema[ft.sectionId].title} | {ft.label}
                </MenuItem>
              ))}
            </Select>
            {formikState.values.Condition.type ===
              FORM_TYPES.CHECKBOX_INPUT && (
              <div>
                <FormGroup>
                  {conditionOptions.map((option, ci) => (
                    <FormControlLabel
                      key={`ConditionOption_${ci}`}
                      control={
                        <Checkbox
                          checked={formikState.values.Condition.value.includes(
                            option
                          )}
                          onClick={() => {
                            const updatedState = { ...formikState };
                            if (
                              formikState.values.Condition.value.includes(
                                option
                              )
                            ) {
                              const index =
                                formikState.values.Condition.value.indexOf(
                                  option
                                );
                              updatedState.values.Condition.value.splice(
                                index,
                                1
                              );
                            } else {
                              updatedState.values.Condition.value.push(option);
                            }
                            setFormikState(updatedState);
                          }}
                        />
                      }
                      label={option}
                    />
                  ))}
                </FormGroup>
              </div>
            )}
            {(formikState.values.Condition.type ===
              FORM_TYPES.MULTIPLE_CHOICE ||
              formikState.values.Condition.type === FORM_TYPES.DROPDOWN) && (
              <FormControl>
                <RadioGroup>
                  {conditionOptions.map((option, ci) => (
                    <FormControlLabel
                      key={`ConditionOption_${ci}`}
                      control={
                        <Radio
                          checked={
                            formikState.values.Condition.value === option
                          }
                          onClick={() => {
                            const updatedState = { ...formikState };
                            updatedState.values.Condition.value = option;
                            setFormikState(updatedState);
                          }}
                        />
                      }
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </div>
        )}
        {error && (
          <Alert
            // icon={<WarningAmberOutlined fontSize="inherit" />}
            severity="error"
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            {error}
          </Alert>
        )}
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button variant="outlined" onClick={handleClose}>
            {t(`CANCEL`)}
          </Button>
          <Button variant="contained" type="submit" color="secondary">
            {t(`CREATE`)}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddGroup;
