import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

const FamilyHistoryMenu = ({ user }) => {
  return (
    <Grid>
      <Link
        to={`/admin/users/view/${user?.user_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="outlined">View Family History</Button>
      </Link>
    </Grid>
  );
};

export default FamilyHistoryMenu;
