import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Link,
  CardActionArea,
  Collapse,
  Grid
} from '@mui/material';
import axios from 'axios';
import { APICONSTANTS } from 'src/components/Constants';
import PushPinIcon from '@mui/icons-material/PushPin';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';

import { ThemeContext } from 'src/store/ContextStore';
import { useContext } from 'react';

const TileCard = ({
  model,
  type,
  setIsEditing,
  setModalOpen,
  setNoteType,
  setCurrentModel
}) => {
  const [body] = React.useState({
    ...model,
    _id: '',
    createdAt: '',
    pinned: '',
    caution: '',
    noteType: ''
  });

  const [fileLinks, setFileLinks] = React.useState({});

  const [expanded, setExpanded] = React.useState(false);

  const colours = {
    Referral: '#4d9fb3',
    Appointment: '#53b382',
    Test: '#354051',
    Sample: '#de2054',
    Address: '#9b9b9b',
    'Clinical Note': '#f7b661',
    Contact: '#f6d956',
    Consent: '#14859a'
  };

  const { theme } = useContext(ThemeContext);

  const handleExpandClick = () => {
    setIsEditing(true);
    setCurrentModel(model);
    setNoteType(type);
    setModalOpen(true);
  };

  const handleClick = (e, fileLink) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(fileLink, '_blank');
  };

  React.useState(() => {
    if (body.fileAttachments) {
      const fetchData = async () => {
        let links = {};
        for (let x in body.fileAttachments) {
          const response = await axios.get(
            `/api/${APICONSTANTS.fileAttachment}`,
            {
              params: {
                fileName: body.fileAttachments[x]
              }
            }
          );
          links[body.fileAttachments[x]] = response.data.fileURL;
        }
        setFileLinks(links);
      };
      fetchData();
    }
  }, []);

  const isDate = (date) => {
    return !isNaN(Date.parse(date)) && Date.parse(date) > 0;
  };

  const renderField = (key) => {
    let type = typeof body[key];

    if (isDate(body[key])) {
      if (key === 'appointmentTime')
        return <>{moment(body[key]).format('hh:mm')}</>;
      return <>{moment(body[key]).format(theme.dateFormat)}</>;
    }
    switch (type) {
      case 'object':
        return (
          <>
            {body[key] &&
              body[key].map((e, i) => (
                <Link
                  key={i}
                  href={fileLinks[e]}
                  value={fileLinks[e]}
                  onClick={(event) => {
                    handleClick(event, fileLinks[e]);
                  }}
                >
                  <br />
                  {e}
                </Link>
              ))}
          </>
        );
      case 'boolean':
        return <>{body[key] ? 'True' : 'False'}</>;
      case 'string':
        return (
          <>
            {body[key]}
            <Grid container>
              {key == 'trackingId' && (
                <Grid item xs={3} paddingTop="1.1rem">
                  <QRCode value={body.trackingId} size={60} />
                </Grid>
              )}
              {key == 'trackingId' && (
                <Grid item xs={9} paddingTop="0.5rem">
                  <Barcode
                    value={body.trackingId}
                    fontSize="0"
                    height="60"
                    width="2"
                  />
                </Grid>
              )}
            </Grid>
          </>
        );
      default:
        return <>{body[key]}</>;
    }
  };

  const SelectField = () => {
    switch (model.noteType) {
      case 'Referral':
        return model.referralDate;
      case 'Appointment':
        return model.appointmentDate;
      case 'Test':
        return model.dateRequested;
      case 'Sample':
        return model.dateTaken;
      case 'Address':
        return model.primaryAddress;
      case 'Clinical Note':
        return model.date;
      case 'Contact':
        return model.contactDate;
      case 'Consent':
        return model.consentDate;
      default:
        return model.createdAt;
    }
  };

  const getLabel = () => {
    const field = SelectField();
    if (type == 'Address')
      return field ? 'Primary Address' : 'Secondary Address';
    return moment(field).format(theme.dateFormat);
  };

  return (
    <Card>
      <CardActionArea onClick={handleExpandClick}>
        <CardContent>
          <Typography variant="h6" component="div">
            {getLabel()}
            <Chip
              label={
                <>
                  {type}
                  {model.caution && <StarIcon fontSize="sm" />}
                  {model.pinned && <PushPinIcon fontSize="sm" />}
                </>
              }
              variant="fill"
              style={{
                backgroundColor: colours[model.noteType],
                float: 'right',
                color: 'white'
              }}
            />
          </Typography>
          <Collapse in={expanded}>
            {Object.keys(body).map((key, index) => (
              <>
                {body[key] != '' && body[key] != null && (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{ borderBottom: '1px solid #ccc', py: 1 }}
                  >
                    <span style={{ fontWeight: 'bold' }}>{key} </span>
                    {renderField(key)}
                  </Typography>
                )}
              </>
            ))}
          </Collapse>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TileCard;
