import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  container: {
    padding: '20px'
  },
  infoMsg: {
    fontSize: 11,
    fontWeight: '700'
  }
});

const SendMsgBox = ({
  onSendMsg,
  onMsgChange,
  message,
  isGeneralConversation
}) => {
  const classes = useStyles();

  return (
    <form>
      <Grid container className={classes.container}>
        <Grid item xs={11}>
          <TextField
            id="outlined-basic-email"
            placeholder="Type Something..."
            fullWidth
            onChange={onMsgChange}
            value={message}
          />
        </Grid>
        <Grid xs={1} align="right">
          <Fab
            color="primary"
            aria-label="add"
            onClick={onSendMsg}
            disabled={!message}
            type="submit"
          >
            <SendIcon />
          </Fab>
        </Grid>

        {isGeneralConversation && (
          <div className={classes.infoMsg}>
            Messages you send will be sent to the healthcare provider and may be
            visible to multiple clinicians.
          </div>
        )}
      </Grid>
    </form>
  );
};

export default SendMsgBox;
