import { Grid } from '@mui/material';
import React from 'react';
import HeaderTitle from '../UI/header/HeaderTitle';
import ChangePasswordForm from '../admin/ChangePasswordForm';

function PatientSettings() {
  return (
    <Grid container>
      <HeaderTitle text={'Settings'} />
      <Grid item xs={12}>
        <ChangePasswordForm />
      </Grid>
    </Grid>
  );
}

export default PatientSettings;
