import React, { useContext } from 'react';
import { Grid, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';

import { UserContext } from 'src/store/ContextStore';

const SingleChat = ({ chat }) => {
  const { user } = useContext(UserContext);
  const { _id: userId } = user || {};

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          <ListItemText
            align={userId === chat.senderId ? 'right' : 'left'}
            primary={chat.message}
          ></ListItemText>
        </Grid>
        <Grid item xs={12}>
          <ListItemText
            align={userId === chat.senderId ? 'right' : 'left'}
            secondary={moment(chat.timestamp).format('MMMM D, h:mm a')}
          ></ListItemText>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default SingleChat;
