import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  unreadCountMsg: {
    backgroundColor: 'red',
    color: '#fff',
    width: 24,
    height: 24,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px'
  }
});

const ChatProfile = ({ chatProfile, onProfileClick, activeId }) => {
  const classes = useStyles();

  const { conversationId, receiver = null, unreadMessageCount } = chatProfile;

  return (
    <>
      <List>
        <ListItem
          button
          selected={conversationId === activeId}
          onClick={() => onProfileClick(chatProfile)}
          clickable={false}
        >
          <ListItemText primary={receiver || 'GENERAL'}></ListItemText>
          {!!unreadMessageCount && conversationId !== activeId && (
            <span className={classes.unreadCountMsg}>{unreadMessageCount}</span>
          )}
        </ListItem>
      </List>
      <Divider />
    </>
  );
};

export default ChatProfile;
